// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index_custom_form_item__trBha .ant-form-item-label {\n  padding: 0 0 4px;\n  font-family: 'SFProText Bold';\n}\n.index_custom_form_item__trBha .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {\n  display: inline-block;\n  margin-right: 4px;\n  color: #C91761;\n  font-size: 14px;\n  font-family: SimSun, sans-serif;\n  content: '*';\n}\n.index_custom_form_item__trBha .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {\n  display: none;\n}\n.index_custom_form_item__trBha > div > label {\n  font-size: 12px !important;\n  text-align: left;\n}\n", "",{"version":3,"sources":["webpack://./src/common/FormItem/index.module.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;EACA,6BAAA;AAAJ;AAHA;EAQI,qBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,+BAAA;EAEA,YAAA;AAHJ;AAXA;EAmBI,aAAA;AALJ;AASA;EACE,0BAAA;EACA,gBAAA;AAPF","sourcesContent":[".custom_form_item {\n  :global(.ant-form-item-label) {\n    padding: 0 0 4px;\n    font-family: 'SFProText Bold';\n  }\n\n  :global(.ant-form-item-label\n      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {\n    display: inline-block;\n    margin-right: 4px;\n    color: #C91761 ;\n    font-size: 14px;\n    font-family: SimSun, sans-serif;\n    // line-height: 1;\n    content: '*';\n  }\n\n  :global(.ant-form-item-label\n      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {\n    display: none;\n  }\n \n}\n.custom_form_item >div >label {\n  font-size: 12px !important;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom_form_item": "index_custom_form_item__trBha"
};
export default ___CSS_LOADER_EXPORT___;
