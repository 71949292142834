import React, { useState } from 'react';
import logo from '@/pages/login/images/Logo.png';
import userlogo from './images/user.png';
import RILLogo from './images/RILLogo.png';
// import reports from './images/reports.png';
import reports from './images/reports123.svg';
import reportshover from './images/barchart.png';
import pieChart from './images/pieChart.svg';
// import settingshover from './images/settings1.svg';
import dashboard from './images/dashboard.png';
// import settings from './images/settings.png';
import FormImg from './images/formsimg.svg';
import MonitorImg from './images/monitorimg.svg';
// import ClipboardImg from './images/clipboardimg.svg';
import ADSearchImg from './images/ADSIcon.svg';
import FormIconImg from './images/FormIcon.svg';
import TrainingIcon from './images/traininfIcon.svg';
import ADSImg from './images/ADSImg.svg';
// import ClipBoardIcon from './images/ClipboardIcon.svg';
import { Layout, Menu, Space } from 'antd';
import styles from './index.module.less';
import './custom-sidebar.css';
import { history } from '@/app/history';
import { LogoutOutlined } from '@ant-design/icons';
import { logout } from 'pages/login/redux/slice';
import { useDispatch } from 'react-redux';
import { getLoggedInUser } from 'utilities/helpers';
// import { NavLink } from 'react-router-dom';

const { Content, Sider } = Layout;
// function getItem(label, key, icon, url, children) {
//   return {
//     key,
//     icon,
//     children,
//     url,
//     label
//   };
// }

// const logout = [getItem('Logout', '1', logo)];

// const handleSidebar = () => {};
const CustomLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKey, setSelectedKey] = useState(
    window.location.pathname === '/reports'
      ? '2'
      : window.location.pathname === '/forms'
      ? '3'
      : window.location.pathname === '/trainingvideo'
      ? '4'
      : window.location.pathname === '/noticeboard'
      ? '5'
      : window.location.pathname === '/advancesearch'
      ? '6'
      : window.location.pathname === '/revertadvance'
      ? '7'
      : '1'
  );
  //  const [itemName, setItemName] = useState('');

  const dispatch = useDispatch();
  const { user } = getLoggedInUser();

  const handleLogout = () => {
    dispatch(logout());
  };

  // const Footer = [
  //   getItem(
  //     <ul className={styles.menuitem1}>
  //       <li style={{ fontSize: '12px', display: 'flex' }}>{user?.email}</li>
  //       <li style={{ fontSize: '11px', fontWeight: 'bold' }}>
  //         {user?.user_role_des.toLowerCase()}
  //       </li>
  //       <li style={{ fontSize: '14px' }} onClick={() => handleLogout()}>
  //         <LogoutOutlined />
  //       </li>
  //     </ul>,
  //     '12',
  //     <img style={{ height: '30px' }} src={userlogo} />
  //   )
  // ];
  // console.log(Footer);
  const items = [
    {
      label: 'Dashboard',
      key: '1',
      icon: <img src={selectedKey == 1 ? reportshover : dashboard} />,
      path: '/dashboard'
    },
    {
      label: 'Reports',
      key: '2',
      icon: <img src={selectedKey == 2 ? pieChart : reports} />,
      path: '/reports'
    },
    {
      label: 'Forms',
      key: '3',
      icon: <img src={selectedKey == 3 ? FormIconImg : FormImg} />,
      path: '/forms'
    },
    ...(user?.user_role_code !== 'ADMIN'
      ? [
          {
            label: 'Training Video',
            key: '4',
            icon: <img src={selectedKey == 4 ? TrainingIcon : MonitorImg} />,
            path: '/trainingvideo'
          }
        ]
      : [
          {
            label: 'Revert Advance',
            key: '7',
            icon: <img src={selectedKey == 7 ? TrainingIcon : MonitorImg} />,
            path: '/revertadvance'
          }
        ]),
    // {
    //   label: 'Notice Board',
    //   key: '5',
    //   icon: <img src={selectedKey == 5 ? ClipBoardIcon : ClipboardImg} />,
    //   path: '/noticeboard'
    // },
    {
      label: 'Advance Search',
      key: '6',
      icon: <img src={selectedKey == 6 ? ADSImg : ADSearchImg} />,
      path: '/advancesearch'
    }
  ];

  const handleNav = (e, data) => {
    console.log(e);
    setSelectedKey(
      window.location.pathname === '/reports'
        ? '2'
        : window.location.pathname === '/forms'
        ? '3'
        : window.location.pathname === '/trainingvideo'
        ? '4'
        : window.location.pathname === '/noticeboard'
        ? '5'
        : window.location.pathname === '/advancesearch'
        ? '6'
        : window.location.pathname === '/revertadvance'
        ? '7'
        : e.key
    );
    setSelectedKey(window.location.pathname === e.key);
    const getpath = data.filter((item) => item.key === e.key);
    console.log(getpath);
    history.push(getpath[0]?.path);
  };
  // console.log(selectedKey);
  // console.log('user?.user_role_code', user?.user_role_code);

  const renderUserRole = () => {
    if (user?.user_role_code === 'V') {
      return 'Vendor';
    } else if (user?.user_role_code === 'IVT') {
      return 'IVT';
    } else if (user?.user_role_code === 'SU') {
      return 'SU';
    } else if (user?.user_role_code === 'ST') {
      return 'ST';
    } else if (user?.user_role_code === 'FCA') {
      return 'FC&A';
    } else if (user?.user_role_code === 'ADMIN') {
      return 'Admin';
    } else {
      return 'NA'; // Default value if no matching user role code
    }
  };

  return (
    <Layout className={styles.custom_layout_container1}>
      <Sider
        collapsed={collapsed}
        onMouseOver={() => setCollapsed(false)}
        onMouseOut={() => setCollapsed(true)}
        className={styles.sider}
        style={{ minHeight: '100vh', background: '#fff' }}
      >
        <div className={styles.demo}>
          <img
            style={{
              width: 'Fill (64px)px',
              height: 'Fixed (100px)px',
              padding: collapsed ? '4px 20px 90px 20px' : '4px 20px 0px 20px',
              gap: '0px',
              opacity: '0px'
            }}
            src={collapsed ? logo : RILLogo}
          />
        </div>
        <Menu
          className={` df-jc-ac ${styles.menu}`}
          mode="horizontal"
          defaultSelectedKeys={['1']}
          selectedKeys={[selectedKey]}
        >
          {items.map((item) => (
            <>
              <div
                className={`${styles.menuitem} ${
                  selectedKey == item?.key ? styles.active : ''
                }`}
                onClick={() => handleNav(item, items)}
              >
                {item.icon}
              </div>

              <div
                className={`${styles.menutxt} ${
                  selectedKey == item?.key ? styles.active : ''
                }`}
                onClick={() => handleNav(item, items)}
              >
                <span>{item.icon}</span>
                <span style={{ marginLeft: '14px', marginTop: '1px' }}>
                  {item.label}
                </span>
              </div>
            </>
            // <Menu.Item key={index} onClick={() => handleNav(item)}>
            // <NavLink
            //   key={index}
            //   className={({ isActive }) =>
            //     isActive ? `${styles.active}` : `${styles.inactive}`
            //   }
            //   to={item.path}
            //   onClick={() => handleNav(item)}
            // >
            //   <span className={` ${styles.icon}`}>
            //     <Space>{item.icon}</Space>
            //   </span>
            // </NavLink>
            // </Menu.Item>
          ))}
        </Menu>
        <div className={styles.footer}>
          <div className={styles.menuitem1}>
            <img src={userlogo} alt="Settings" />
            <div className={styles.footitem}>
              <span style={{ display: 'flex' }}>{user?.email}</span>
              <span
                style={{ fontWeight: 'bold', color: '#bfa161' }}
                onClick={() => handleLogout()}
              >
                <Space>
                  {/* {user?.user_role_code.toLowerCase()} <LogoutOutlined /> */}
                  {renderUserRole()}
                  <LogoutOutlined />
                </Space>
              </span>
            </div>
          </div>
        </div>
        {/* <Menu mode="inline" className={styles.menuFooter} items={Footer} /> */}
        {/* <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #f0f0f0',
            background: '#fff'
          }}
        >
          
        </div> */}
      </Sider>

      <Layout className={styles.site_layout}>
        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};
export default CustomLayout;
