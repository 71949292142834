import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Divider,
  Input,
  Col,
  DatePicker,
  Form,
  Tooltip,
  Empty,
  Select,
  message
  // Spin
} from 'antd';
import styles from './index.module.less';
import FormItem from '@/common/FormItem';
import moment from 'moment';
// import { getLoggedInUser } from 'utilities/helpers';
import { Pagination } from 'antd';
import prev from '../images/arrow-left.svg';
import next from '../images/arrow-right.svg';
import { EyeFilled } from '@ant-design/icons';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { getadvancesearchData, adSearchExport } from '../redux/slice';
import { GetInvoiceTypes } from '../../Invoice/redux/slice';
import { getInvoiceStatuses } from '../../Dashboard/redux/slice';
import { getIVTUserDetails } from '../../Scrollview/redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import CustomTooltip from '../../CustomTooltip/CustomTooltip.js';
// import { useDispatch } from 'react-redux';
// import { FileTextOutlined, DownloadOutlined } from '@ant-design/icons';

// import { Exported } from '../redux/slice';
import { Buffer } from 'buffer';

const index = ({ allOnBoardingList, pagination }) => {
  //   const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const { user } = getLoggedInUser();
  //   const [exports, setExports] = useState(false);
  //   const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const [result, setResult] = useState('');

  const { invoicetypes } = useSelector((state) => state.invoices);
  const { statuses } = useSelector((state) => state.vendorDashboard);
  const { IVTUsersList } = useSelector((state) => state.companydetails);

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no Invoices to display
        </div>
      </span>
    )
  };
  console.log('IVTUsersList', IVTUsersList);

  useEffect(() => {
    advancesearchData();
  }, [page, search]);
  console.log('result', result);
  const advancesearchData = () => {
    const payload = { ...result, page };
    dispatch(getadvancesearchData(payload));
  };

  useEffect(() => {
    invoiceData();
    InvoiceStatuses();
    getIVTUsers();
  }, [page, search, allOnBoardingList, pagination]);
  const invoiceData = () => {
    dispatch(GetInvoiceTypes());
  };
  console.log('page', page);

  const getIVTUsers = () => {
    const payload = { q: search };
    dispatch(getIVTUserDetails(payload));
  };
  const InvoiceStatuses = () => {
    dispatch(getInvoiceStatuses());
  };
  // console.log('SearchData', allOnBoardingList);
  // const handleInvoiceTypeChange = (e) => {
  //   setSelectedInvoiceType(e);
  // };
  const handleReset = () => {
    form.resetFields();
  };

  const handleExport = (payload) => {
    setLoader(true);
    dispatch(adSearchExport(payload)).then((blob) => {
      setLoader(false);
      // console.log('blob?.payload', blob?.payload?.data?.data);
      const buffer = Buffer.from(blob?.payload?.data?.data);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Advanced Search Export.xlsx`
        // `SharedInvoice_${Date.now().toLocaleDateTimeString()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      form.resetFields();
    });
  };

  const onFinish = (values) => {
    // setLoading(true);
    setResult(values);
    const {
      requestNo,
      dealSlipNo,
      ivt_assignee_id,
      invoiceType,
      scrollNo,
      vendorName,
      project_name_and_code,
      bill_to,
      invoice_no,
      status,
      sn_shared_date
      // startDate,
      // endDate
    } = values || {};
    // console.log('values', values);
    // const invoice_id = id;

    let payload = {
      request_no: requestNo ? requestNo : 'NA',
      scroll_no: scrollNo ? scrollNo : 'NA',
      deal_slip_no: dealSlipNo ? dealSlipNo : 'NA',
      invoice_type_id: invoiceType ? invoiceType : 'NA',
      vendor_name_and_code: vendorName ? vendorName : 'NA',
      invoice_status_id: status ? status : 'NA',
      // start_date: startDate ? startDate : 'NA',
      // end_date: endDate ? endDate : 'NA'
      ivt_assignee_id: ivt_assignee_id ? ivt_assignee_id : 'NA',
      project_name_and_code: project_name_and_code
        ? project_name_and_code
        : 'NA',
      bill_to: bill_to ? bill_to : 'NA',
      invoice_no: invoice_no ? invoice_no : 'NA',
      sn_shared_date: sn_shared_date ? sn_shared_date : 'NA'
    };
    // console.log('payload', payload);
    setResult(payload);
    // onSubmit(payload);
    if (buttonClicked === 'apply') {
      setLoading(true);
      onSubmit(payload);
    } else if (buttonClicked === 'export') {
      setLoader(true);
      handleExport(payload);
    }
  };

  const onSubmit = (payload) => {
    setLoading(true);
    // setLoading(true);
    // const payload = {};
    // dispatch(getadvancesearchData(payload));
    // setLoading(false);
    dispatch(getadvancesearchData(payload))
      .then(() => {
        setLoading(false);
        // message.success('Data fetched successfully!');
      })
      .catch(() => {
        setLoading(false);
        message.error('Error fetching data.');
      });
  };
  const handleProject = () => {
    // setProjectId(e);
  };
  const handleSearch = (e) => {
    setSearch(e);
  };

  // Search Fields in Dashboard
  const columns = [
    {
      title: 'Request No.',
      dataIndex: 'request_no',
      key: 'request_no',
      width: 150,
      render: (request_no, record, index) => {
        return (
          <>
            <Tooltip
              title={request_no}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* || user?.user_role_des == 'SURRGATE USER' */}
              {user?.user_role_des == 'VENDOR' &&
              (record?.invoice_sts_des == 'DRAFT' ||
                record?.invoice_sts_des == 'ST REJECTED' ||
                record?.invoice_sts_des == 'Draft' ||
                record?.invoice_sts_des == 'ST Rejected' ||
                record?.invoice_sts_des == 'IVT Rejected') ? (
                //  <a href={`invoice-view/${record.id}`}></a>
                <a href={`invoices/${record.id}`}>
                  {request_no === null ? 'Not generated' : request_no}
                </a>
              ) : user?.user_role_des == 'SURRGATE USER' &&
                (record?.invoice_sts_des == 'DRAFT' ||
                  record?.invoice_sts_des == 'ST REJECTED' ||
                  record?.invoice_sts_des == 'Draft' ||
                  record?.invoice_sts_des == 'ST Rejected' ||
                  record?.invoice_sts_des == 'IVT Rejected') ? (
                //  <a href={`invoice-view/${record.id}`}></a>
                <a href={`invoices/${record.id}`}>
                  {request_no === null ? 'Not generated' : request_no}
                </a>
              ) : user?.user_role_des == 'SCROLL TEAM' &&
                (record?.invoice_sts_des == 'ST SUBMITTED' ||
                  record?.invoice_sts_des == 'ST Submitted') ? (
                <a href={`scrollview/${record.id}`}>
                  {request_no === null ? 'Not generated' : request_no}
                </a>
              ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                (record?.invoice_sts_des == 'SN Generated' ||
                  record?.invoice_sts_des == 'IVT Hold') ? (
                <a href={`scrollview/${record.id}`}>
                  {request_no === null ? 'Not generated' : request_no}
                </a>
              ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                record?.invoice_sts_des == 'SN Validated' ? (
                <a href={`scrollview/${record.id}`}>
                  {request_no === null ? 'Not generated' : request_no}
                </a>
              ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                record?.invoice_sts_des == 'SN Shared' ? (
                <a href={`approveInvoice/${record.id}`}>
                  {request_no === null ? 'Not generated' : request_no}
                </a>
              ) : request_no === null ? (
                'Not generated'
              ) : (
                request_no
              )}
            </Tooltip>
            {index === 0 && (
              <span
                className={
                  index === 0 ? styles.defaultStyle : styles.anotherStyle
                }
              ></span>
            )}
          </>
        );
      }
    },
    {
      title: 'Scroll No.',
      dataIndex: 'scroll_no',
      header: 'Scroll No.',
      key: 'scroll_no',
      width: 150
    },
    {
      title: 'Company Name',
      dataIndex: 'bill_to_party',
      header: 'Bill to',
      key: 'bill_to_party'
      // width: 150
    },
    {
      title: 'Deal Slip No.',
      dataIndex: 'deal_slip',
      key: 'deal_slip'
    },
    {
      title: 'Work Area',
      dataIndex: 'work_area',
      key: 'work_area'
    },
    {
      title: 'Vendor Name',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      render: (text, record) => (
        <span>
          {record.vendor_name}
          {/* ({record.vendor_code}) */}
        </span>
      )
    },
    {
      title: 'Vendor Code',
      dataIndex: 'vendor_code',
      key: 'vendor_code',
      render: (text, record) => (
        <span>
          {record.vendor_code}
          {/* ({record.vendor_code}) */}
        </span>
      )
    },
    {
      title: 'Currency',
      dataIndex: 'currency_code',
      key: 'currency_code'
    },
    {
      title: 'Invoice Type',
      dataIndex: 'invoice_type_des',
      key: 'invoice_type_des'
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no'
    },
    {
      title: 'Invoice Date',
      dataIndex: 'inv_date',
      key: 'inv_date',
      sorter: (a, b) =>
        moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
      render: (inv_date) => (
        <>
          <Tooltip
            title={
              inv_date === null
                ? 'Not generated'
                : moment(inv_date).format('DD/MM/YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {inv_date === null
              ? 'Not generated'
              : moment(inv_date).format('DD/MM/YY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'Invoice Base Amount',
      dataIndex: 'invoice_amount',
      key: 'invoice_amount'
    },
    {
      title: 'Invoice Amount (With GST)',
      dataIndex: 'total_invoice_amount',
      key: 'total_invoice_amount'
    },
    {
      title: 'IVT Assigne to',
      dataIndex: 'assigned_to_ivt',
      key: 'assigned_to_ivt'
    },
    {
      title: 'IVT Validated By',
      dataIndex: 'ivt_validated_by',
      key: 'ivt_validated_by'
    },
    {
      title: 'Entity Checked By',
      dataIndex: 'entity_checked_by',
      key: 'entity_checked_by'
    },
    // {
    //   title: 'Downloaded',
    //   dataIndex: 'is_downloaded',
    //   key: 'is_downloaded'
    // },
    {
      title: 'Downloaded',
      dataIndex: 'is_downloaded',
      key: 'is_downloaded',
      render: (is_downloaded) => (
        <Tooltip title={is_downloaded ? 'Downloaded' : 'Not Downloaded'}>
          {is_downloaded ? 'Yes' : 'No'}
        </Tooltip>
      )
    },
    {
      title: 'Downloaded Date',
      dataIndex: 'downloaded_on',
      key: 'downloaded_on',
      sorter: (a, b) =>
        moment(a[`downloaded_on`]).unix() - moment(b[`downloaded_on`]).unix(),
      render: (downloaded_on) => (
        <>
          <Tooltip
            title={
              downloaded_on === null
                ? ''
                : moment(downloaded_on).format('DD/MM/YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {downloaded_on === null
              ? ''
              : moment(downloaded_on).format('DD/MM/YY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'SN Shared Date',
      dataIndex: 'sn_shared_date',
      key: 'sn_shared_date',
      sorter: (a, b) =>
        moment(a[`sn_shared_date`]).unix() - moment(b[`sn_shared_date`]).unix(),
      render: (sn_shared_date) => (
        <>
          <Tooltip
            title={
              sn_shared_date === null
                ? ''
                : moment(sn_shared_date).format('DD/MM/YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {sn_shared_date === null
              ? ''
              : moment(sn_shared_date).format('DD/MM/YY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'FC&A Approved Date',
      dataIndex: 'approval_dt',
      key: 'approval_dt',
      sorter: (a, b) =>
        moment(a[`approval_dt`]).unix() - moment(b[`approval_dt`]).unix(),
      render: (approval_dt) => (
        <>
          <Tooltip
            title={
              approval_dt === null ? '' : moment(approval_dt).format('DD/MM/YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {approval_dt === null ? '' : moment(approval_dt).format('DD/MM/YY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'Payment Date',
      dataIndex: 'payment_date',
      key: 'payment_date',
      sorter: (a, b) =>
        moment(a[`payment_date`]).unix() - moment(b[`payment_date`]).unix(),
      render: (payment_date) => (
        <>
          <Tooltip
            title={
              payment_date === null
                ? ''
                : moment(payment_date).format('DD/MM/YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {payment_date === null
              ? ''
              : moment(payment_date).format('DD/MM/YY')}
          </Tooltip>
        </>
      )
    },

    // {
    //   title: 'Vendor Name & Code',
    //   dataIndex: 'vendor_name (vendor_code)',
    //   key: 'vendor_name (vendor_code)',
    //   render: (text, record) => (
    //     <span>
    //       {record.vendor_name} ({record.vendor_code})
    //     </span>
    //   )
    // },
    {
      title: 'Status',
      dataIndex: 'invoice_sts_des',
      key: 'invoice_sts_des',
      width: 150,
      // ellipsis: true,
      // textWrap: 'word-break',
      sorter: (a, b) =>
        (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
      render: (text, record) => {
        const status = record?.invoice_sts_des || 'NA';
        const color =
          status == 'ST GENERATED' || status == 'ST Generated'
            ? '#007bff'
            : status == 'ST SUBMITTED' || status == 'ST Submitted'
            ? '#9747FF'
            : status == 'ST REJECTED' || status == 'ST Rejected'
            ? '#E3486C'
            : status == 'SN VALIDATED' || status == 'SN Validated'
            ? '#344054'
            : status == 'IVT HOLD' || status == 'IVT Hold'
            ? '#FF5E00'
            : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
            ? '#15B0E0'
            : status == 'SN SHARED' || status == 'SN Shared'
            ? '#FF00FF'
            : status == 'PAID' || status == 'Paid'
            ? '#3C9C51'
            : status == 'DRAFT' || status == 'Draft'
            ? '#6c757d'
            : status == 'SN GENERATED' || status == 'SN Generated'
            ? '#2F80ED'
            : status == 'IVT REJECTED' || status == 'IVT Rejected'
            ? '#D92D20'
            : 'green';
        return (
          <span style={{ color, fontSize: '11px !important' }}>{status}</span>
        );
      }
    },
    {
      title: 'Last Updated Date',
      dataIndex: 'payment_date',
      key: 'payment_date',
      sorter: (a, b) =>
        moment(a[`payment_date`]).unix() - moment(b[`payment_date`]).unix(),
      render: (payment_date) => (
        <>
          <Tooltip
            title={
              payment_date === null
                ? ''
                : moment(payment_date).format('DD/MM/YY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {payment_date === null
              ? ''
              : moment(payment_date).format('DD/MM/YY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      key: 'comment',
      render: (comments, record) => {
        return (
          <div>
            {/* {record.map((comment, index) => ( */}
            <CustomTooltip
              // key={index}
              teamName={record.commented_user_role}
              comment={record.comment}
              id={record.id}
            />
          </div>
        );
      }
    },
    {
      title: 'View',
      dataIndex: 'View',
      key: 'View',
      className: styles.highlightedColumn,
      render: (request_no, record) => {
        return (
          <>
            <Tooltip
              title={
                record?.request_no === null
                  ? 'Not Generated'
                  : record?.request_no
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
              {record?.request_no !== null ? (
                <a href={`viewpdf/${record.id}`}>
                  {request_no === null ? 'Not generated' : request_no}
                  <EyeFilled className={`${styles.edit_icon}`} />
                </a>
              ) : (
                <EyeFilled />
              )}
            </Tooltip>
          </>
        );
      }
    }
  ];

  return (
    <Card className={styles.cardbody}>
      {/* <Meta title="#Reports" className={styles.metaData} /> */}
      <h1 className={styles.title}>Advance Search</h1>
      <Divider className="divider" />

      <div className={styles.container}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Request No."
                name="requestNo"
                className={styles.customLable}
              >
                <Input placeholder="Enter request no." />
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Scroll No."
                name="scrollNo"
                className={styles.customLable}
              >
                <Input placeholder="Enter scroll no." />
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Deal Slip No."
                name="dealSlipNo"
                className={styles.customLable}
              >
                <Input placeholder="Enter deal slip no." />
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Name and Email of the IVT Assignee"
                name="ivt_assignee_id"
                className={styles.customLable}
              >
                <Select
                  placeholder="Select ivt person"
                  style={{ width: '100%' }}
                  onChange={(e) => handleProject(e)}
                  showSearch
                  optionFilterProp="children"
                  onSearch={handleSearch}
                >
                  {IVTUsersList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.email}
                    </option>
                  ))}
                </Select>
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Vendor Name and Code"
                name="vendorName"
                className={styles.customLable}
              >
                <Input placeholder="Enter vendor name and code" />
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Invoice Type"
                name="invoiceType"
                className={styles.customLable}
              >
                {/* <Input placeholder="Enter name of EIC" /> */}
                <Select
                  placeholder="Select invoice type"
                  style={{ width: '100%' }}
                  // onChange={(e) => handleInvoiceTypeChange(e)}
                >
                  {invoicetypes.length
                    ? invoicetypes?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.invoice_type_des}
                        </option>
                      ))
                    : ''}
                </Select>
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Project Name and Code"
                name="project_name_and_code"
                className={styles.customLable}
              >
                <Input placeholder="Enter project name and code" />
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Bill to"
                name="bill_to"
                className={styles.customLable}
              >
                <Input placeholder="Enter bill to" />
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Invoice Number"
                name="invoice_no"
                className={styles.customLable}
              >
                <Input placeholder="Enter invoice number" />
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Status"
                name="status"
                className={styles.customLable}
              >
                {/* <Input placeholder="Enter name of EIC" /> */}
                <Select
                  placeholder="Select status"
                  style={{ width: '100%' }}
                  // onChange={(e) => handleInvoiceTypeChange(e)}
                >
                  {statuses.length
                    ? statuses?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.invoice_sts_des}
                        </option>
                      ))
                    : ''}
                </Select>
              </FormItem>
            </Col>

            <Col xs={24} xl={6} span={8}>
              <FormItem
                label="SN Shared Date"
                name="sn_shared_date"
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Enter sn shared date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>

            {/* <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Start Date"
                name="startDate"
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Enter name of EIC"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col> */}

            {/* <Col xs={24} xl={6} span={8}>
              <FormItem
                label="End Date"
                name="endDate"
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Enter name of EIC"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col> */}
          </Row>

          <Form.Item className={styles.formactions}>
            <button
              type="button"
              onClick={handleReset}
              className={styles.resetbutton}
            >
              Reset filters
            </button>
            <button
              type="submit"
              className={styles.applybutton}
              // loading={loading}
              onClick={() => {
                setButtonClicked('apply');
                form.submit(); // Triggers the onFinish function
              }}
            >
              {/* Apply filters */}
              {loading ? 'Applying...' : 'Apply filters'}
            </button>
            <button
              type="button"
              // onClick={handleExport}
              onClick={() => {
                setButtonClicked('export');
                form.submit(); // Triggers the onFinish function
              }}
              className={styles.resetbutton}
              // loading={loading}
            >
              {/* Export */}
              {loader ? 'Exporting...' : 'Export'}
            </button>
          </Form.Item>
        </Form>
      </div>

      {/*  */}
      <br></br>
      <div className={styles.container2}>
        {/* Dashboard tables */}
        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            columns={columns}
            dataSource={allOnBoardingList}
            pagination={false}
            className={styles.pagination_style}
            scroll={{ x: 100 }}
          />
          {allOnBoardingList?.length ? (
            <div className={styles.pagination}>
              <span
                className={page === 1 ? styles.prevDisabled : styles.prev}
                onClick={() => setPage(page - 1)}
              >
                <img className={styles.prev} src={prev} /> Previous
              </span>
              <Pagination
                className={styles.pagination_style}
                current={page}
                total={pagination?.totalItems || 0}
                // itemRender={itemRender}
                onChange={(page) => setPage(page)}
                showSizeChanger={false}
                scroll={{ x: 'auto' }}
              />
              <span
                span={8}
                className={
                  page === pagination?.totalPages
                    ? styles.nextdisabled
                    : styles.next
                }
                onClick={() => setPage(page + 1)}
              >
                Next
                <img className={styles.prev} src={next} />
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Card>
  );
};

export default index;
