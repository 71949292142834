import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import Loader from './common/Loader';
import { useSelector } from 'react-redux';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Login from './pages/login';
import DashBoard from './pages/Dashboard';
import Invoices from './pages/Invoice';
import CheckList from './pages/Checklist';
import InvoiceView from 'pages/InvoiceView';
import ResetPassword from './pages/Resetpassword';
import SetPassword from './pages/Setpassword';
import ScrollViewPDF from './pages/Scrollview';
import Validation from './pages/Validation';
import ApproveInv from './pages/IVT';
import Reports from './pages/Report';
import Settings from './pages/Settings';
import FormsDownload from './pages/FormDownloads';
import TrainingVideo from './pages/TrainingVideo';
import NotiveBoard from './pages/NoticeBoard';
import AdvanceSearch from './pages/AdvanceSearch';
import ViewPDF from './pages/Viewpage';
import FCAApproveInv from './pages/FCAApprove';
import RevertAdvance from './pages/RevertAdvance';

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }
  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();

  const { loggedIn } = getLoggedInUser();

  if (loggedIn) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }

  return children;
};

function App() {
  // const user = getLoggedInUser();
  let goTo = '/login';
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <Login />
            </AuthChecker>
          }
        />
        <Route
          path="reset-password"
          element={
            <AuthChecker>
              <ResetPassword />
            </AuthChecker>
          }
        />
        <Route
          path="set-password"
          element={
            <AuthChecker>
              <SetPassword />
            </AuthChecker>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <DashBoard />
            </PrivateRoute>
          }
        />
        <Route
          path="invoices"
          element={
            <PrivateRoute>
              <Invoices />
            </PrivateRoute>
          }
        />
        <Route
          path="invoices/:id"
          element={
            <PrivateRoute>
              <Invoices />
            </PrivateRoute>
          }
        />
        <Route
          path="checklist"
          element={
            <PrivateRoute>
              <CheckList />
            </PrivateRoute>
          }
        />
        <Route
          path="invoice-view/:id"
          element={
            <PrivateRoute>
              <InvoiceView />
            </PrivateRoute>
          }
        />
        <Route
          path="scrollview/:id"
          element={
            <PrivateRoute>
              <ScrollViewPDF />
            </PrivateRoute>
          }
        />
        <Route
          path="validation/:id"
          element={
            <PrivateRoute>
              <Validation />
            </PrivateRoute>
          }
        />
        <Route
          path="approveInvoice/:id"
          element={
            <PrivateRoute>
              <ApproveInv />
            </PrivateRoute>
          }
        />
        <Route
          path="fcaapproveInvoice/:id"
          element={
            <PrivateRoute>
              <FCAApproveInv />
            </PrivateRoute>
          }
        />
        <Route
          path="reports"
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />
        <Route
          path="settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="forms"
          element={
            <PrivateRoute>
              <FormsDownload />
            </PrivateRoute>
          }
        />
        <Route
          path="trainingvideo"
          element={
            <PrivateRoute>
              <TrainingVideo />
            </PrivateRoute>
          }
        />
        <Route
          path="noticeboard"
          element={
            <PrivateRoute>
              <NotiveBoard />
            </PrivateRoute>
          }
        />
        <Route
          path="advancesearch"
          element={
            <PrivateRoute>
              <AdvanceSearch />
            </PrivateRoute>
          }
        />
        <Route
          path="viewpdf/:id"
          element={
            <PrivateRoute>
              <ViewPDF />
            </PrivateRoute>
          }
        />
        <Route
          path="revertadvance"
          element={
            <PrivateRoute>
              <RevertAdvance />
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
