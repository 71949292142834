import React, { useState } from 'react';
import CustomTable from '@/common/Content/CustomTable';
import CustomModal from '@/common/CustomModal';
import styles from '../../index.module.less';
import { Card, Tag, Row, Col, Typography, Skeleton } from 'antd';
// Timeline
import moment from 'moment';
import { HistoryOutlined } from '@ant-design/icons';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
// const { Title, Text } = Typography;
const { Meta } = Card;
const { Text } = Typography;
const InvoiceDetails = ({
  performaInvoices,
  selectedRowKeys,
  setSelectedRowKeys,
  getAuditDetails,
  auditData,
  isModal,
  setIsModal,
  invoicesData,
  projectid,
  projects
}) => {
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isShow, setIsShow] = useState(true);
  const [auditListData, setAuditListData] = useState([]);
  // const [btnLoading, setBtnLoading] = useState(false);

  const projectName = projects?.length
    ? projects.filter((item) => item.id === projectid)
    : [];

  const handleAudit = (id) => {
    // setBtnLoading(true);

    const audit = performaInvoices.filter((item) => item.id === id);
    setAuditListData(audit);
    getAuditDetails(id);
  };
  console.log('auditListData', auditListData);

  const columns = [
    {
      title: 'Deal Slip No.',
      dataIndex: 'deal_slip',
      key: 'deal_slip'
    },
    {
      title: 'Scroll No.',
      dataIndex: 'scroll_no',
      key: 'scroll_no'
    },
    {
      title: 'Proforma Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no'
    },
    {
      title: 'Proforma Invoice Amount',
      dataIndex: 'invoice_amount',
      key: 'invoice_amount'
    },
    {
      title: 'Proforma Invoice Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      render: (text) => {
        return moment(text).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Vendor Name & Code',
      dataIndex: 'vendorName',
      key: 'vendorName',
      render: (text, record) => {
        return (
          <span>
            {record.vendor_name} ({record.vendor_code})
          </span>
        );
      }
    },
    {
      title: 'Project name',
      dataIndex: 'project_name',
      key: 'project_name'
    },
    {
      title: '',
      dataIndex: 'audit_trail',
      key: 'audit_trail',
      render: (text, record) => {
        return record?.has_audit_trail ? (
          !isModal ? (
            <Tag
              color="default"
              size="small"
              className={styles.tag}
              onClick={() => handleAudit(record?.id)}
              style={{ cursor: 'pointer' }}
            >
              Audit Trail
            </Tag>
          ) : (
            ''
          )
        ) : (
          ''
        );
      }
    }
  ];

  const auditcolumns = [
    {
      title: 'Deal Slip No.',
      dataIndex: 'deal_slip',
      key: 'deal_slip'
    },
    {
      title: 'Scroll No.',
      dataIndex: 'scroll_no',
      key: 'scroll_no'
    },
    {
      title: 'Proforma Invoice No.',
      dataIndex: 'proforma_invoice_no',
      key: 'proforma_invoice_no'
    },
    {
      title: 'Proforma Invoice Amount',
      dataIndex: 'proforma_invoice_amount',
      key: 'proforma_invoice_amount'
    },
    {
      title: 'Proforma Invoice Date',
      dataIndex: 'Proforma_invoice_date',
      key: 'Proforma_invoice_date',
      render: (text) => {
        return moment(text).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Proforma Adjusted Amount',
      dataIndex: 'utilization_amount',
      key: 'utilization_amount'
    },
    {
      title: 'Proforma Adjusted Date',
      dataIndex: 'utilization_dt',
      key: 'utilization_dt',
      render: (text) => {
        return moment(text).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Proforma Remaining Amount',
      dataIndex: 'proforma_remaining_amount',
      key: 'proforma_remaining_amount'
    },
    {
      title: 'Vendor Name & Code',
      dataIndex: 'vendorName',
      key: 'vendorName',
      render: (text, record) => {
        return (
          <span>
            {record.vendor_name} ({record.vendor_code})
          </span>
        );
      }
    },
    {
      title: 'Project name',
      dataIndex: 'project_name',
      key: 'project_name'
    },
    {
      title: '',
      dataIndex: 'audit_trail',
      key: 'audit_trail',
      render: (text, record) => {
        return record?.has_audit_trail ? (
          !isModal ? (
            <Tag
              color="default"
              size="small"
              className={styles.tag}
              onClick={() => handleAudit(record?.id)}
              style={{ cursor: 'pointer' }}
            >
              Audit Trail
            </Tag>
          ) : (
            ''
          )
        ) : (
          ''
        );
      }
    }
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };
  console.log('auditData', auditData);

  return (
    // <Card className={styles.card}>
    <>
      <div className={styles.collapse}>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={8}>
            <Meta title="Invoice Details" className={styles.metaData1} />
          </Col>
          <Col xs={24} xl={12} span={8}>
            {!isShow ? (
              <DownOutlined
                style={{ float: 'right' }}
                onClick={() => setIsShow(true)}
              />
            ) : (
              <CloseOutlined
                style={{ float: 'right' }}
                onClick={() => setIsShow(false)}
              />
            )}
          </Col>
        </Row>
        {isShow ? (
          <div className={styles.nestedCard1}>
            {/* <div className={styles.tablelist}> */}
            <Row gutter={24} className={styles.rowclass}>
              <Col span={6} className={styles.colclass}>
                <Text strong>Deal slip</Text>
                <div>{invoicesData?.deal_slip}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong>Tax Invoice Number</Text>
                <div>{invoicesData?.invoice_no}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong>Tax Invoice Date</Text>
                <div>{moment(invoicesData?.inv_date).format('YYYY-MM-DD')}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong>Vendor Name & Code</Text>
                <div>{`${invoicesData?.vendor_name} (${invoicesData?.vendor_code})`}</div>
              </Col>
            </Row>
            <Row gutter={24}>
              {/* <Col span={6} className={styles.colclass}>
                <Text strong>DC No.</Text>
                <div>23</div>
              </Col> */}
              <Col span={6} className={styles.colclass}>
                <Text strong>Project Name</Text>
                <div>{projectName?.[0]?.project_name}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong>Invoice Amount</Text>
                <div>{invoicesData?.invoice_amount}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong></Text>
                <div></div>
              </Col>
            </Row>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className={styles.collapse1}>
        {/* <Meta title="Invoice details" className={styles.metaData2} /> */}
        <div className={styles.tableheader}>
          List of Proforma Invoices for the vendor
        </div>
        <CustomTable
          dataSource={performaInvoices}
          columns={columns}
          className={styles.tabledata}
          rowSelection={rowSelection}
          rowKey={(record) => record.id}
          pagination={false}
        />
      </div>
      {isModal ? (
        <CustomModal
          title={
            <>
              <span>
                <HistoryOutlined className={styles.historyOutline} />
              </span>
              <span className={styles.audittrail}> Audit Trail</span>
            </>
          }
          visible={isModal}
          width="100%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          {auditData?.length ? (
            <>
              <CustomTable
                dataSource={auditData}
                columns={auditcolumns}
                className={styles.tabledata}
                // rowSelection={rowSelection}
                // rowKey={(record) => record.id}
                pagination={false}
              />
              <br />
              {/* <Timeline>
                {auditData?.map((item, index) => (
                  <Timeline.Item key={index}>
                    {moment(item?.created_at).format('DD/MM/YYYY')},{' '}
                    {moment(item?.created_at).format('hh:mm')}{' '}
                    {`proforma invoice amount changed to ${item?.utilization_amount}`}
                  </Timeline.Item>
                ))}
              </Timeline> */}
              <FormItem className="text-center">
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => setIsModal(false)}
                  type="primary"
                  // size="small"
                >
                  Close
                </CustomButton>
              </FormItem>
            </>
          ) : (
            <Skeleton />
          )}
        </CustomModal>
      ) : (
        ''
      )}
    </>
  );
};

export default InvoiceDetails;
