import React from 'react';
import { Input, Card, DatePicker, message } from 'antd';
import styles from '../../index.module.less';
import CustomTable from '@/common/Content/CustomTable';
import moment from 'moment';

const { Meta } = Card;

const InvoiceTable = ({
  selectedInvoices,
  setRemainingAmount,
  remainingAmount
}) => {
  // const [remainingAmount, setRemainingAmount] = useState([]);

  const formatToTwoDecimals = (number) => {
    if (isNaN(number)) return 'NA';
    return parseFloat(number).toFixed(2);
  };

  const handleAdjustedamount = (e, _, index) => {
    if (e.target.value > selectedInvoices[index].total_invoice_amount) {
      message.error(
        'Adjusted amount should be less than or equal to Proforma Invoice Amount'
      );
      const list = [...remainingAmount];
      list[index].adjustment_amount = 0;
      list[index].remaining_amount = 0;
      setRemainingAmount(list);
    } else {
      const adjustedAmount = e.target.value;
      const list = [...remainingAmount];
      list[index].adjustment_amount = adjustedAmount;
      list[index].remaining_amount =
        selectedInvoices[index].total_invoice_amount - adjustedAmount;
      setRemainingAmount(list);
    }
    // selectedInvoices[0].adjustedAmount = adjustedAmount;
    // selectedInvoices[0].remainingAmount = remainingAmount;
  };

  // const handleChangeDate = (e, _, index) => {
  //   const adjustedDate = e;
  //   const list = [...remainingAmount];
  //   list[index].adjustment_date = adjustedDate;
  //   setRemainingAmount(list);
  // };

  const columns = [
    {
      title: 'Deal Slip No',
      dataIndex: 'deal_slip',
      key: 'deal_slip'
    },
    {
      title: 'Proforma Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no'
    },
    {
      title: 'Proforma Invoice Amount',
      dataIndex: 'invoice_amount',
      key: 'invoice_amount'
    },
    {
      title: 'Performa Invoice Rem Amt for Adjustment',
      dataIndex: 'total_invoice_amount',
      key: 'total_invoice_amount'
    },
    {
      title: 'Adjusted Amount',
      dataIndex: 'adjustedAmount',
      key: 'adjustedAmount',
      render: (text, record, index) => (
        <Input
          onChange={(e) => handleAdjustedamount(e, record, index)}
          value={remainingAmount[index]?.adjustment_amount}
        />
      )
    },
    {
      title: 'Remaining Amount',
      dataIndex: 'remainingAmount',
      key: 'remainingAmount',
      render: (text, _, index) => (
        <Input
          disabled
          value={
            remainingAmount[index]?.remaining_amount
              ? formatToTwoDecimals(remainingAmount[index]?.remaining_amount)
              : 0
          }
        />
      )
    },
    {
      title: 'Adjusted Date',
      dataIndex: 'adjustedDate',
      key: 'adjustedDate',
      width: 150,
      render: () => (
        <DatePicker
          format="DD/MM/YY"
          // onChange={(e) => handleChangeDate(e, _, index)}
          value={moment()}
        />
      )
    },
    {
      title: 'Proforma Invoice Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      render: (text) => {
        return moment(text).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Vendor Name & Code',
      dataIndex: 'vendorNameCode',
      key: 'vendorNameCode',
      render: (text, record) => {
        return (
          <span>
            {record.vendor_name} ({record.vendor_code})
          </span>
        );
      }
    },
    {
      title: 'Project Name & Code',
      dataIndex: 'project_name',
      key: 'project_name'
    }
  ];

  return (
    <div className={styles.collapse}>
      <Meta title="Invoice details" className={styles.metaData2} />
      <br />
      <CustomTable
        className={styles.invoiceTable}
        dataSource={selectedInvoices}
        columns={columns}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default InvoiceTable;
