import React, { useState } from 'react';
import { Row, Col, Tabs, Form, Input } from 'antd';
import './InvoiceDetails.css';
import styles from '../../index.module.less';
import moment from 'moment';
// import { TextArea } from 'semantic-ui-react';
const { TextArea } = Input;
const index = ({
  // performaInvoices,
  // companyid,
  // projectTypeId,
  // projectid,
  remainingAmount,
  selectedInvoices,
  form
  // invoiceid
}) => {
  const [selectedTab, setSelectedTab] = useState('0');
  const books = remainingAmount;
  console.log(books);

  const jsonObject = books.map(JSON.stringify);
  const uniqueSet = new Set(jsonObject);
  const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
  console.log(uniqueArray);
  const finalData = [];
  remainingAmount?.map((item) => {
    const data = selectedInvoices?.filter(
      (data1) => data1.id == item.proforma_invoice_id
    );
    const duplicate = finalData.some(
      (person) => person.id === item.proforma_invoice_id
    );
    console.log(item.proforma_invoice_id, duplicate);
    data.length ? finalData.push(data) : '';
  });
  const onTabChange = (e) => {
    setSelectedTab(e);
  };

  const books1 = finalData;
  const jsonObject1 = books1.map(JSON.stringify);
  const uniqueSet1 = new Set(jsonObject1);
  const uniqueArray1 = Array.from(uniqueSet1).map(JSON.parse);

  const formatNumberWithCommasAndDecimals = (number) => {
    if (isNaN(number)) return 'NA';
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  return (
    <>
      <Tabs onChange={onTabChange} activeKey={selectedTab} type="card">
        {uniqueArray1.map((tab, index) => {
          // const { key, label, children } = tab;
          return (
            <Tabs.TabPane
              key={index}
              tab={tab?.[0]?.invoice_no}
              style={{ margin: '1.5rem auto 1.5rem' }}
            >
              <div className="invoice-content">
                <Row gutter={24} className={styles.validationclass}>
                  <Col span={24} xl={6}>
                    <div className={styles.invoiceLabel}>Advance Adjusted</div>
                    <div className={styles.invoiceValue}>Yes</div>
                  </Col>

                  <Col span={24} xl={6}>
                    <div className={styles.invoiceLabel}>
                      Advance Adjusted against Proforma Invoice Number
                    </div>
                    <div className={styles.invoiceValue}>
                      {tab?.[0]?.invoice_no}
                    </div>
                  </Col>
                  <Col span={24} xl={6}>
                    <div className={styles.invoiceLabel}>
                      Proforma Invoice Base Amount
                    </div>
                    <div className={styles.invoiceValue}>
                      {tab?.[0]?.invoice_amount}
                    </div>
                  </Col>
                </Row>

                <Row gutter={24} className={styles.validationclass}>
                  <Col span={24} xl={6}>
                    <div className={styles.invoiceLabel}>
                      Proforma Invoice Remaining Amount
                    </div>
                    <div className={styles.invoiceValue}>
                      {remainingAmount?.[index]?.remaining_amount
                        ? formatNumberWithCommasAndDecimals(
                            remainingAmount?.[index]?.remaining_amount
                          )
                        : 0}
                    </div>
                  </Col>

                  <Col span={24} xl={6}>
                    <div className={styles.invoiceLabel}>
                      Proforma Invoice Utilized Amount
                    </div>
                    <div className={styles.invoiceValue}>
                      {remainingAmount?.[index]?.adjustment_amount}
                    </div>
                  </Col>
                  <Col span={24} xl={6}>
                    <div className={styles.invoiceLabel}>
                      Proforma Invoice Utilized Date
                    </div>
                    <div className={styles.invoiceValue}>
                      {moment().format('DD-MM-YYYY')}
                    </div>
                  </Col>
                  <Col span={24} xl={6}>
                    <div className={styles.invoiceLabel}>
                      Proforma Invoice Scroll Number
                    </div>
                    <div className={styles.invoiceValue}>
                      {tab?.[0]?.scroll_no}
                    </div>
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>

      <Form form={form} layout="vertical">
        <Row gutter={24}>
          <Col xs={24} xl={24} span={16}>
            {/* <Meta title="Invoice Details" className={styles.metaData2} /> */}
            <Form.Item
              label="Remarks"
              // name="rejection_message"
              name="ivt_remarks"
              className={styles.labelrejection}
              rules={[{ required: false, message: 'Enter remarks' }]}
            >
              <TextArea placeholder="Enter remarks" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default index;
