import apiClient from 'utilities/apiClient';

export const companies = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};
export const projects = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};
export const projectsTypes = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoiceMaster}/${url}`, payload, true);
};
export const performaDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.userDashboard}/${url}`, payload, true);
};

export const createperformaDetails = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.userDashboard}/${url}`,
    payload,
    true
  );
};

export const auditTrails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};
