import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { setpassword } from './api';
// import { history } from 'app/history';

const initialState = {
  loading: false,
  token: ''
  // user: null
};

const actions = {
  SETPASSWORD: 'setpassword/SETPASSWORD'
};

export const SetPassword = createAsyncThunk(
  actions.SETPASSWORD,
  async (payload) => {
    const response = await setpassword(payload);

    console.log('testing', response);
    if (response.success === true) {
      //   // const { access_token, user } = response;
      const messageText = response.message || 'Mail sent successfully';
      //   // console.log('email', response.success.data);
      message.success(messageText);
      // history.push('/login');
    } else {
      //   // Set the error message
      const errorMessage =
        response.message ||
        'Login failed. Please check your credentials and try again';
      message.error(errorMessage);
    }

    return response;
  }
);

export const setpasswordSlice = createSlice({
  name: 'setpassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setpassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(setpassword.fulfilled, (state, action) => {
        const { access_token, message: msg, user } = action.payload;
        state.loading = false;
        state.token = access_token;
        state.user = user;

        if (access_token) {
          message.success(msg, [1]);
        } else {
          message.error(msg);
        }
      })
      .addCase(setpassword.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default setpasswordSlice.reducer;
