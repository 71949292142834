import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;
// export const apiUrl ='https://gnfa-api.lexyslabs.com/api/v1/';

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

const getLocalToken = () => {
  const user = LocalStorage.getItem('RIL_user');
  const authToken = user?.access_token;
  if (authToken !== null && authToken !== '') {
    return `Bearer ${authToken}`;
  } else {
    return '';
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    signin: `${apiUrl}/auth/login`,
    resetPassword: `${apiUrl}/auth/forget-password`,
    setPassword: `${apiUrl}/auth/reset-password`,
    // userDashboard: `${apiUrl}/users/dashboard`,
    invoiceMaster: `${apiUrl}/master`,
    invoice: `${apiUrl}`,
    userDashboard: `${apiUrl}/invoice`,
    getinvoicelist: `${apiUrl}/invoice`,
    invoiceApproved: `${apiUrl}/invoice/scroll-team/approve-reject`,
    ivtactions: `${apiUrl}/invoice/ivt-team/actions`,
    ivtApproved: `${apiUrl}/invoice/fca/approve-entity`,
    ivtupdate: `${apiUrl}/invoice/fca/update-paid`,
    snapprove: `${apiUrl}/invoice/fca/update-fca-approved`,
    ivtTeam: `${apiUrl}/invoice/ivt-team`,
    snsharedList: `${apiUrl}/invoice/fca/export-invoices`,
    snUpload: `${apiUrl}/invoice/fca/import-excel`,
    report: `${apiUrl}/reports`,
    formslist: `${apiUrl}/form`,
    ivtusers: `${apiUrl}/user`,
    fcateam: `${apiUrl}/invoice/fca`
  },

  make: function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method == 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth) {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;
