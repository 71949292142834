import React, { useState } from 'react';
import { Row, Form, Input, Typography, Col, Checkbox } from 'antd';
import styles from './index.module.less';
import { Message, Icon } from 'semantic-ui-react';
// import tataPro from '../../images/main_image.png';
// import logo from '../../images/Logo.png';
import RILLogo from '@/common/Layout/images/RILLogo.png';
// import MAINLOGO from '../../../login/images/main_image.svg'
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Link } = Typography;

const LoginPage = ({ loading, onSubmit }) => {
  const [errorMsg] = useState('');
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email, password } = values;

    let payload = {
      email,
      password
    };

    onSubmit(payload);
  };
  return (
    <>
      <Row className={styles.mainContainerStyle}>
        <Col xs={24} xl={16} md={12} sm={12} className={styles.backgroundImg}>
          {/* <img className={`${styles.left_logo} mt-5 ml-5 mb-1`} src={logo} /> */}

          <img
            className={`${styles.left_logo} mt-3 ml-5 mb-1`}
            // style={{
            //   width: '110%',
            //   height: '100%',
            //   top: '106px',
            //   gap: '0px',
            //   position: 'absolute'
            // }}
            src={RILLogo}
          />
        </Col>
        <Col xs={18} xl={8} sm={18} md={12} className={styles.sideheader}>
          <div className={styles.sideheader1}>
            <h1
              style={{
                marginTop: '50%',
                marginLeft: '7%',
                fontWeight: 'Bold',
                color: '#BFA161',
                fontSize: '26px'
                // fontFamily: 'Source Sans Pro'
              }}
            >
              Let&apos;s get started
            </h1>
          </div>
          {/* <AuthWrapper> */}
          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  placeholder="Enter Employee/User ID"
                  className={styles.customLable}
                />
              </FormItem>
              <FormItem
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password
                  placeholder="Enter password"
                  className={styles.customLable}
                />
              </FormItem>
              <Row gutter={24} className={styles.remember}>
                <Col span={12} xs={12} xl={12} sm={6} md={12}>
                  <Checkbox className=" sfprotext-medium font-12">
                    Remember
                  </Checkbox>
                </Col>
                <Col span={12} xs={12} xl={12} sm={6} md={12} className="p1-0">
                  <Link
                    href="/reset-password"
                    className="d-block text-right mb-4 sfprotext-medium font-12"
                  >
                    Forgot password?
                  </Link>
                </Col>
              </Row>
              <FormItem>
                <CustomButton
                  // block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className={styles.login_button}
                >
                  Sign in <ArrowRightOutlined />
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </Col>
      </Row>
      {/*  <div className={styles.bakground_img}>
        <div className={styles.left_screen}>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
        </div>
        <div className={styles.right_screen}></div>
      </div> */}
      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </>
  );
};

export default LoginPage;
