import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  companies,
  projects,
  projectsTypes,
  performaDetails,
  createperformaDetails,
  auditTrails
} from './api';
// import { getLoggedInUser } from 'utilities/helpers';
// ./api
const initialState = {
  loading: false,
  companies: [],
  projects: [],
  projectsTypes: [],
  performaInvoices: []
};

const actions = {
  GETCOMPANIES: 'getCompanyDetails/GETCOMPANIES',
  GETPROJECTS: 'getProjectsDetails/GETPROJECTS',
  GETPROJECTTYPES: 'getProjectTypeDetails/GETPROJECTTYPES',
  GETPERFORMADETAILS: 'getPerformaDetails/GETPERFORMADETAILS',
  CREATEPERFORMAINVOICE: 'createPerformaInvoice/CREATEPERFORMAINVOICE',
  GETAUDITTRAILS: 'getAuditTrails/GETAUDITTRAILS'
};

export const getCompanyDetails = createAsyncThunk(
  actions.GETCOMPANIES,
  async (payload) => {
    const response = await companies(`company`, payload);
    return response;
  }
);

export const getProjectsDetails = createAsyncThunk(
  actions.GETPROJECTS,
  async (payload) => {
    const response = await projects(`project`, payload);
    return response;
  }
);

export const getProjectTypeDetails = createAsyncThunk(
  actions.GETPROJECTTYPES,
  async (payload) => {
    const response = await projectsTypes(`project-type`, payload);
    return response;
  }
);

export const getPerformaDetails = createAsyncThunk(
  actions.GETPERFORMADETAILS,
  async (payload) => {
    const response = await performaDetails(`proforma/${payload?.id}`, payload);
    return response;
  }
);

export const createPerformaInvoice = createAsyncThunk(
  actions.CREATEPERFORMAINVOICE,
  async (payload) => {
    const response = await createperformaDetails(`ivt-team/validate`, payload);
    return response;
  }
);

export const getAuditTrails = createAsyncThunk(
  actions.GETAUDITTRAILS,
  async (payload) => {
    const response = await auditTrails(`audit-trail/${payload}`, payload);
    return response;
  }
);
export const IVTInvoicesSlice = createSlice({
  name: 'IVTinvoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyDetails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.companies = data.items;
        state.acceptedLoading = false;
      })
      .addCase(getCompanyDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getProjectsDetails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getProjectsDetails.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.projects = data.items;
        state.acceptedLoading = false;
      })
      .addCase(getProjectsDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getProjectTypeDetails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getProjectTypeDetails.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.projectsTypes = data;
        state.acceptedLoading = false;
      })
      .addCase(getProjectTypeDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getPerformaDetails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getPerformaDetails.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.performaInvoices = data;
        state.acceptedLoading = false;
      })
      .addCase(getPerformaDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createPerformaInvoice.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(createPerformaInvoice.fulfilled, (state, action) => {
        const { message: msg } = action.payload;
        message.success(msg);
      })
      .addCase(createPerformaInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAuditTrails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getAuditTrails.fulfilled, (state, action) => {
        const { message: msg } = action.payload;
        message.success(msg);
      })
      .addCase(getAuditTrails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
  }
});

export default IVTInvoicesSlice.reducer;
