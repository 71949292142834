import React from 'react';
import Layout from '@/common/Layout';
import DashboardPage from '@/pages/Dashboard/components/DashboardPage';

const Dashboard = () => {
  return (
    <>
      <Layout sider={true}>
        <DashboardPage />
      </Layout>
    </>
  );
};

export default Dashboard;
