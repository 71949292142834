import React, { useState } from 'react';
import { Row, Form, Input, Col, Button } from 'antd';
import styles from './index.module.less';
import { Message, Icon } from 'semantic-ui-react';
// import tataPro from '../../images/main_image.png';
// import logo from '../../images/Logo.png';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { ResetPassword } from '../redux/slice';
import { history } from '@/app/history';
import RILLogo from '@/common/Layout/images/RILLogo.png';

// /redux/slice
// const { Link } = Typography;

const index = () => {
  const [errorMsg] = useState('');
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email } = values;

    let payload = {
      email
    };

    onSubmit(payload);
  };

  // const { loading } = useSelector((state) => state.resetpassword);
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(ResetPassword(payload));
  };

  const backToLogin = () => {
    history.push('login');
  };

  return (
    <>
      <Row className={styles.mainContainerStyle}>
        <Col xs={24} xl={16} md={12} sm={12} className={styles.backgroundImg}>
          {/* <img className={`${styles.left_logo} mt-5 ml-5 mb-1`} src={logo} /> */}

          <img
            className={`${styles.left_logo} mt-3 ml-5 mb-1`}
            // style={{
            //   width: '110%',
            //   height: '100%',
            //   top: '106px',
            //   gap: '0px',
            //   position: 'absolute'
            // }}
            src={RILLogo}
          />
        </Col>
        <Col xs={18} xl={8} sm={18} md={12} className={styles.sideheader}>
          <div className={styles.sideheader1}>
            <h1
              style={{
                marginTop: '50%',
                marginLeft: '7%',
                fontWeight: 'Bold',
                color: '#BFA161',
                fontSize: '26px'
                // fontFamily: 'Source Sans Pro'
              }}
            >
              {/* Let&apos;s get started */}
              Forgot Password
            </h1>
            <div className={styles.subcontent}>
              <p
                style={{
                  marginTop: '0%',
                  marginLeft: '7%'
                  // fontWeight: 'Bold',
                  // color: '#BFA161',
                  // fontSize: '22px'
                  // fontFamily: 'Source Sans Pro'
                }}
              >
                {' '}
                {`Enter your email and we'll send you a link to reset your
                password`}
              </p>
            </div>
          </div>
          {/* <AuthWrapper> */}
          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  placeholder="Enter your email"
                  className={styles.customLable}
                />
              </FormItem>
              <FormItem>
                <CustomButton
                  // block
                  type="primary"
                  htmlType="submit"
                >
                  Request reset link
                </CustomButton>
              </FormItem>
              <Form.Item>
                <Button
                  type="link"
                  onClick={backToLogin}
                  style={{ padding: 0 }}
                >
                  <ArrowLeftOutlined />
                  Back to login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      {/*  <div className={styles.bakground_img}>
        <div className={styles.left_screen}>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
        </div>
        <div className={styles.right_screen}></div>
      </div> */}
      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </>
  );
};

export default index;
