import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  invoicetypes,
  submitInvoices,
  invoiceData,
  appovedInvoice,
  appovedEntity,
  companies,
  projects,
  projectsTypes,
  ivtuserDetails,
  allremarks,
  headerdata
} from './api';
import { getLoggedInUser } from 'utilities/helpers';
// ./api
const initialState = {
  loading: false,
  invoicetypes: [],
  currencies: [],
  gstTypes: [],
  invoicedata: [],
  companies: [],
  projects: [],
  projectsTypes: [],
  IVTUsersList: [],
  Remarks: []
};

const actions = {
  GETINVOICETYPES: 'GetInvoiceTypes/GETINVOICETYPES',
  GETCURRENCIES: 'GetCurrencies/GETCURRENCIES',
  GETGSTDATA: 'GetGSTData/GETGSTDATA',
  SUBMITINVOICE: 'submitInvoice/SUBMITINVOICE',
  GETINVOICEDATA: 'getInvoiceData/GETINVOICEDATA',
  APPROVEINVOICE: 'approveInvoice/APPROVEINVOICE',
  APPROVEDIVTINVOICE: 'approvedIvtInvoice/APPROVEDIVTINVOICE',
  GETCOMPANIES: 'getCompanyDetails/GETCOMPANIES',
  GETPROJECTS: 'getProjectsDetails/GETPROJECTS',
  GETPROJECTTYPES: 'getProjectTypeDetails/GETPROJECTTYPES',
  GETIVTUSERDETAILS: 'getIVTUserDetails/GETIVTUSERDETAILS',
  GETALLREMARKS: 'GetAllRemarks/GETALLREMARKS',
  GETHEADERDATA: 'getHeaderData/GETHEADERDATA'
};

export const GetInvoiceTypes = createAsyncThunk(
  actions.GETINVOICETYPES,
  async (payload) => {
    const response = await invoicetypes('invoice-types', payload);
    return response;
  }
);

export const GetCurrencies = createAsyncThunk(
  actions.GETCURRENCIES,
  async (payload) => {
    const response = await invoicetypes('currency', payload);
    return response;
  }
);

export const GetGSTData = createAsyncThunk(
  actions.GETGSTDATA,
  async (payload) => {
    const response = await invoicetypes('gst-types', payload);
    return response;
  }
);

export const submitInvoice = createAsyncThunk(
  actions.SUBMITINVOICE,
  async (payload) => {
    const response = await submitInvoices('invoice', payload);
    return response;
  }
);

export const getInvoiceData = createAsyncThunk(
  actions.GETINVOICEDATA,
  async (payload) => {
    const response = await invoiceData(payload);
    return response;
  }
);

export const getCompanyDetails = createAsyncThunk(
  actions.GETCOMPANIES,
  async (payload) => {
    const response = await companies(`company`, payload);
    return response;
  }
);

export const getProjectsDetails = createAsyncThunk(
  actions.GETPROJECTS,
  async (payload) => {
    const response = await projects(`project`, payload);
    return response;
  }
);

export const getProjectTypeDetails = createAsyncThunk(
  actions.GETPROJECTTYPES,
  async (payload) => {
    const response = await projectsTypes(`project-type`, payload);
    return response;
  }
);

export const getIVTUserDetails = createAsyncThunk(
  actions.GETIVTUSERDETAILS,
  async (payload) => {
    const response = await ivtuserDetails(`ivt_persons`, payload);
    return response;
  }
);

export const getRemarks = createAsyncThunk(
  actions.GETALLREMARKS,
  async (payload) => {
    const response = await allremarks(`/audit-status/${payload}`);
    return response;
  }
);

export const getHeaderData = createAsyncThunk(
  actions.GETHEADERDATA,
  async (payload) => {
    console.log('payload', payload);
    const response = await headerdata(
      `/invoice/export-invoice-header-details/${payload.id}`,
      payload
    );
    console.log('response', response);
    return response;
  }
);

export const approvedIvtInvoice = createAsyncThunk(
  actions.APPROVEDIVTINVOICE,
  async (payload) => {
    const response = await appovedEntity(payload);
    if (response.success === true) {
      const messageText = response.message;
      message.success(messageText);
    } else {
      //   // Set the error message
      const errorMessage = response.message;
      message.error(errorMessage);
    }
    return response;
  }
);

export const approveInvoice = createAsyncThunk(
  actions.APPROVEINVOICE,
  async (payload) => {
    const { user } = getLoggedInUser();

    if (user?.user_role_des === 'SCROLL TEAM') {
      const response = await appovedInvoice(
        'scroll-team/approve-reject',
        payload
      );
      console.log('testing', response);
      if (response.success === true) {
        const messageText = response.message;
        message.success(messageText);
        //   history.push('/login');
      } else {
        //   // Set the error message
        const errorMessage = response.message;
        message.error(errorMessage);
      }
      return response;
    } else if (user?.user_role_des === 'INVOICE VALIDATION TEAM') {
      const response = await appovedInvoice('ivt-team/actions', payload);
      console.log('testing', response?.data);
      if (response.success === true) {
        const messageText = response.message;
        message.success(messageText);
        //   history.push('/login');
      } else {
        //   // Set the error message
        const errorMessage = response.message;
        message.error(errorMessage);
      }
      return response;
    }
  }
);

export const InvoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetInvoiceTypes.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetInvoiceTypes.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.invoicetypes = data;
        state.acceptedLoading = false;
      })
      .addCase(GetInvoiceTypes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(GetCurrencies.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetCurrencies.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.currencies = data;
        state.acceptedLoading = false;
      })
      .addCase(GetCurrencies.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(GetGSTData.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetGSTData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.gstTypes = data;
        state.acceptedLoading = false;
      })
      .addCase(GetGSTData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(submitInvoice.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(submitInvoice.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.gstTypes = data;
        state.acceptedLoading = false;
      })
      .addCase(submitInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getInvoiceData.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getInvoiceData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.invoicedata = data;
        state.acceptedLoading = false;
      })
      .addCase(getInvoiceData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(approvedIvtInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(approvedIvtInvoice.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.data = data;
      })
      .addCase(approvedIvtInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(approveInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveInvoice.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.data = data;
      })
      .addCase(approveInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getCompanyDetails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.companies = data.items;
        state.acceptedLoading = false;
      })
      .addCase(getCompanyDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getProjectsDetails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getProjectsDetails.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.projects = data.items;
        state.acceptedLoading = false;
      })
      .addCase(getProjectsDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getProjectTypeDetails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getProjectTypeDetails.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.projectsTypes = data;
        state.acceptedLoading = false;
      })
      .addCase(getProjectTypeDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    // getIVTUserDetails
    builder
      .addCase(getIVTUserDetails.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getIVTUserDetails.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.IVTUsersList = data;
        state.acceptedLoading = false;
      })
      .addCase(getIVTUserDetails.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getRemarks.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getRemarks.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.Remarks = data;
        state.acceptedLoading = false;
      })
      .addCase(getRemarks.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getHeaderData.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getHeaderData.fulfilled, (state, action) => {
        // const { data } = action.payload;
        const { message: msg } = action.payload;
        // state.HeaderData = data;
        message.success(msg);
        state.acceptedLoading = false;
      })
      .addCase(getHeaderData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
  }
});

export default InvoicesSlice.reducer;
