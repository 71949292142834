import React, { useEffect, useState } from 'react';
import { Row, Col, Upload, Button, message } from 'antd';
import styles from '../../index.module.less';
import FormItem from '@/common/FormItem';
import { PaperClipOutlined } from '@ant-design/icons';
import { removeDuplicatesFromArray } from 'utilities/helpers';

const attachments = ({
  invoicesData,
  invoiceValues,
  invoicetypes,
  attachmentsData
}) => {
  // const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const MAX_FILE_SIZE_MB = 3 * 1024 * 1024; // 3MB in bytes

  const beforeUpload = (file) => {
    if (file.size > MAX_FILE_SIZE_MB) {
      message.error(`${file.name} exceeds the maximum size allow is 3MB.`);
      return Upload.LIST_IGNORE; // Prevent the file from being added to the upload list
    }
    return true;
  };

  // const handleOnChangePdfUpload = ({ fileList: onChangeFileList }) => {
  //   let files = fileList.concat(onChangeFileList.map((o) => o.originFileObj));

  //   setFileList(removeDuplicatesFromArray(files, 'name'));

  const handleOnChangePdfUpload = ({ fileList: onChangeFileList }) => {
    // Filter out files that were removed from the upload list
    const updatedFileList = removeDuplicatesFromArray(
      [...fileList, ...onChangeFileList.map((o) => o.originFileObj)],
      'name'
    );

    setFileList(updatedFileList);
  };
  const [invoiceTypeCode, setInvoiceTypeCode] = useState({});
  const [isValidationRequired, setIsValidationRequired] = useState(false);

  // console.log('invoicetypes', invoicetypes);
  console.log('invoiceValues', invoiceValues);
  console.log('invoicesData?.invoice_type_des', invoicesData?.invoice_type_des);
  console.log(
    'invoiceTypeCode?.invoice_type_des',
    invoiceTypeCode?.invoice_type_des
  );

  useEffect(() => {
    const matchingType = invoicetypes.find(
      (type) => type.id === invoiceValues.invoice_type
    );
    // console.log('matchingType', matchingType);
    if (matchingType) {
      setInvoiceTypeCode(matchingType);
    } else {
      setInvoiceTypeCode('Type not found');
    }
  }, [invoicetypes, invoiceValues.invoice_type]);
  // console.log('invoiceTypeCode', invoiceTypeCode?.invoice_type_des);
  console.log('attachmentsData', attachmentsData);

  // const isValidationRequired =
  //   invoicesData?.invoice_type_des || invoiceTypeCode !== 'Proforma Invoice';
  useEffect(() => {
    // Check the condition and set the state accordingly
    // if (
    //   (invoicesData?.invoice_type_des !== 'Proforma Invoice' ||
    //     invoiceTypeCode?.invoice_type_des !== 'Proforma Invoice') &&
    //     invoiceTypeCode?.invoice_type_des !== 'Proforma Invoice'
    // ) {
    //   setIsValidationRequired(true);
    // } else {
    //   setIsValidationRequired(false);
    // }
    const isProformaOrCreditNote = (type) =>
      type === 'Proforma Invoice' || type === 'Credit Note';

    if (
      (!isProformaOrCreditNote(invoicesData?.invoice_type_des) ||
        !isProformaOrCreditNote(invoiceTypeCode?.invoice_type_des)) &&
      !isProformaOrCreditNote(invoiceTypeCode?.invoice_type_des)
    ) {
      setIsValidationRequired(true);
    } else {
      setIsValidationRequired(false);
    }
  }, [invoicesData, invoiceTypeCode]);

  // const status = attachmentsData?.filter(
  //   (data) => data.attachment_type_code === 'RA'
  // )[0]?.attachment_status;
  const status =
    attachmentsData?.length > 0
      ? attachmentsData.find((data) => data.attachment_type_code === 'RA')
          ?.attachment_status
      : 'false';
  console.log('status', status);
  const name =
    attachmentsData?.length > 0
      ? attachmentsData.find((data) => data.attachment_type_code === 'RA')
          ?.file_name
      : 'false';
  // console.log('name', name);

  return (
    // <Form form={form} layout="vertical">
    <Row gutter={24}>
      {/* Rohit Code */}
      <Col xs={24} xl={8} span={8} className={styles.upload}>
        <FormItem
          label="Attachment"
          name="ra_attachment"
          // rules={[{ required: true, message: 'Please attach a file' }]}
          // const isDisabled = status === 'approve' || false;
          rules={
            isValidationRequired &&
            status !== 'st-approve' &&
            isValidationRequired &&
            status !== 'ivt-approve'
              ? [{ required: true, message: 'Please attach a file' }]
              : []
          }
          className={styles.customLable}
        >
          {status === 'st-approve' || status === 'ivt-approve' ? (
            // <p> {status}</p>
            <Button
              icon={<PaperClipOutlined />}
              className={styles.uploaded_button}
            >
              {name}
            </Button>
          ) : (
            <Upload
              // name="file"
              accept=".xlsx"
              // listType="picture-card"
              className={`avatar-uploader ${styles.upload}`}
              onChange={handleOnChangePdfUpload}
              beforeUpload={beforeUpload} // Add file size validation here
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess('ok');
                }, 0);
              }}
              // multiple={true}
              showUploadList={true}
              disabled={status === 'st-approve' || status === 'ivt-approve'}
            >
              <Button
                icon={<PaperClipOutlined />}
                className={styles.upload_button}
              >
                {/* Attach File */}
                {/* {status === 'approve' ? 'File Attached' : 'Attach File'} */}
                {status === 'st-reject' || status === 'ivt-reject'
                  ? 'Re-upload File'
                  : 'Attach File'}
              </Button>
            </Upload>
          )}
        </FormItem>
      </Col>
    </Row>
    // </Form>
  );
};
export default attachments;
