import React, { useEffect, useState } from 'react';
// import { Checkbox } from 'antd';
import { DownloadOutlined, FileOutlined } from '@ant-design/icons';
import styles from './AttachmentCard.module.css';
import { getLoggedInUser } from 'utilities/helpers';

const AttachmentCard = ({ name, url, fileId, onApproval, status }) => {
  //   const [selectedFiles, setSelectedFiles] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const { user } = getLoggedInUser();
  // console.log('user', user);
  console.log('status', status);

  //   const handleCheckboxChange = (e) => {
  //     const { name, checked } = e.target;
  //     onCheckboxChange(fileId, name, checked);
  //   };
  // useEffect(() => {
  //   if (status === 'st-approve') {
  //     setIsApproved(true);
  //     setIsRejected(false);
  //   } else if (status === 'st-reject') {
  //     setIsRejected(true);
  //     setIsApproved(false);
  //   } else {
  //     setIsApproved(false);
  //     setIsRejected(false);
  //   }
  // }, [status]);

  useEffect(() => {
    if (user?.user_role_code === 'ST' && status === 'st-approve') {
      setIsApproved(true);
      setIsRejected(false);
    } else if (user?.user_role_code === 'ST' && status === 'st-reject') {
      setIsRejected(true);
      setIsApproved(false);
    } else if (user?.user_role_code === 'IVT' && status === 'ivt-approve') {
      setIsApproved(true);
      setIsRejected(false);
    } else if (user?.user_role_code === 'IVT' && status === 'ivt-reject') {
      setIsRejected(true);
      setIsApproved(false);
    } else {
      setIsApproved(false);
      setIsRejected(false);
    }
  }, [status]);

  // const handleApproveChange = () => {
  //   if (isRejected) {
  //     return; // Prevent approving if already rejected
  //   }
  //   setIsApproved(!isApproved);
  //   setIsRejected(false);
  //   onApproval(fileId, !isApproved, false);
  // };

  // const handleRejectChange = () => {
  //   if (isApproved) {
  //     return; // Prevent rejecting if already approved
  //   }
  //   setIsRejected(!isRejected);
  //   setIsApproved(false);
  //   onApproval(fileId, false, !isRejected);
  // };

  const handleApproveChange = () => {
    if (!isApproved) {
      setIsApproved(true);
      setIsRejected(false);
      onApproval(fileId, true, false);
    } else {
      setIsApproved(false);
      setIsRejected(false);
      onApproval(fileId, false, false);
    }
  };

  const handleRejectChange = () => {
    if (!isRejected) {
      setIsRejected(true);
      setIsApproved(false);
      onApproval(fileId, false, true);
    } else {
      setIsRejected(false);
      setIsApproved(false);
      onApproval(fileId, false, false);
    }
  };

  const isDisabled = () => {
    // Disable checkboxes if the document is already approved or rejected in the current role
    if (user?.user_role_code === 'ST' && status === 'st-approve') {
      return true;
    } else if (user?.user_role_code === 'IVT' && status === 'ivt-approve') {
      return true;
    }
    return false;
  };
  //   setSelectedFiles((prevState) => ({
  //     ...prevState,
  //     [fileId]: {
  //       ...prevState[fileId],
  //       [name]: checked
  //     }
  //   }));
  return (
    <>
      <div className={styles.container}>
        <div className={styles.attachmentCard}>
          <div className={styles.attachmentInfo}>
            <div className={styles.attachmentIcon1}>
              <FileOutlined
                style={{
                  fontSize: 18,
                  marginRight: 10,
                  color: '#dfa161',
                  background: '#fff9ec',
                  border: '4px solid #fffcf6',
                  borderRadius: '50%',
                  padding: '10px'
                }}
              />
            </div>
            <div>
              <p className={styles.attachmentName}>{name}</p>
              {/* <p className={styles.attachmentSize}>600 KB</p> */}
            </div>
          </div>
          <div className={styles.attachmentActions}>
            <DownloadOutlined
              className={styles.downloadIcon}
              onClick={() => window.open(url)}
            />
          </div>
        </div>
        <div className={styles.actionbuttons}>
          {user?.user_role_code != 'FCA' ? (
            <>
              <label className={styles.checkbox1styles}>
                <input
                  type="checkbox"
                  checked={isApproved}
                  onChange={handleApproveChange}
                  // disabled={isRejected || status === 'st-approve'}
                  // disabled={isDisabled()}
                  disabled={isDisabled() || isRejected}
                  className={styles.approvecheckboxstyles}
                  style={{ fontSize: '30px' }}
                />
                Approve
              </label>
              <label className={styles.checkbox2styles}>
                <input
                  type="checkbox"
                  checked={isRejected}
                  onChange={handleRejectChange}
                  // disabled={isApproved || status === 'st-approve'}
                  // disabled={isDisabled()}
                  disabled={isDisabled() || isApproved}
                  className={styles.rejectcheckboxstyles}
                />
                Reject
              </label>
              <input type="hidden" name={`file_name_${fileId}`} value={name} />
              <input type="hidden" name={`file_id_${fileId}`} value={fileId} />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default AttachmentCard;
