import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import FormDownload from '../FormDownloads/components';
import { getDocumentData } from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const index = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    DocumentData();
  }, []);

  const DocumentData = () => {
    dispatch(getDocumentData());
  };
  const { formslist } = useSelector((state) => state.formslist);
  // console.log('formslist', formslist);
  return (
    <>
      <Layout sider={true}>
        <FormDownload documents={formslist} />
        {/* Reports Page testing */}
      </Layout>
    </>
  );
};

export default index;
