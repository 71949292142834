import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { paidinvoice } from './api';
// import { history } from 'app/history';

const initialState = {
  loading: false,
  token: ''
  // user: null
};

const actions = {
  PAIDINV: 'PaidInv/PAIDINV'
};

export const PaidInv = createAsyncThunk(actions.PAIDINV, async (payload) => {
  const response = await paidinvoice(payload);
  if (response.success === true) {
    const messageText = response.message;
    message.success(messageText);
  } else {
    //   // Set the error message
    const errorMessage = response.message;
    message.error(errorMessage);
  }
  return response;
});

export const paidinvSlice = createSlice({
  name: 'paidinv',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PaidInv.pending, (state) => {
        state.loading = true;
      })
      .addCase(PaidInv.fulfilled, (state, action) => {
        const { data, message: msg } = action.payload;
        state.loading = false;
        state.data = data;
        message.success(msg);
      })
      .addCase(PaidInv.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default paidinvSlice.reducer;
