import React from 'react';
import Layout from '@/common/Layout';
import IVTApprove from '@/pages/IVT/components';

const index = () => {
  return (
    <>
      <Layout sider={true}>
        <IVTApprove />
        {/* IVT Single Invoice Approve Page testing */}
      </Layout>
    </>
  );
};

export default index;
