import React from 'react';
import { Row, Col, Select, Card } from 'antd';
import styles from '../../index.module.less';
import FormItem from '@/common/FormItem';

const { Meta } = Card;
const { Option } = Select;

const index = ({
  companies,
  projects,
  projectsTypes,
  setCompanyId,
  setProjectId,
  setProjectTypeId,
  setSearch,
  // invoicesData
  companyid,
  projectid
}) => {
  // const [company, setCompany] = useState('');
  // const [project, setProject] = useState('');

  // useEffect(() => {
  //   if (invoicesData && invoicesData.company_name) {
  //     setCompany(invoicesData.company_name);
  //   }
  // }, [invoicesData]);
  // console.log('invoicesData', invoicesData?.company_id);

  //   if (invoicesData && invoicesData.company_name && companies) {
  //     const selectedCompany = companies.find(
  //       (c) => c.company_name === invoicesData.company_name
  //     );
  //     if (selectedCompany) {
  //       setCompany(selectedCompany.id);
  //     }
  //   }
  // }, [invoicesData, companies]);
  // const handleCompany = (e) => {
  //   setCompanyId(e);
  // };
  // const handleCompany = (value) => {
  //   setCompanyId(value);
  //   const selectedCompany = companies.find((company) => company.id === value);
  //   setCompany(selectedCompany ? selectedCompany.company_name : '');
  // };
  const handleCompany = (value) => {
    setCompanyId(value);
    // setCompany(value);
  };
  const handleProject = (e) => {
    setProjectId(e);
  };
  const handleProjectType = (e) => {
    setProjectTypeId(e);
  };
  const handleSearch = (e) => {
    setSearch(e);
  };
  console.log('companyid', companyid);

  return (
    <>
      <div className={styles.nestedCard}>
        <Meta title="Project & Details" className={styles.metaData1} />
        <Row gutter={24}>
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Company"
              // name="Company"
              rules={[
                {
                  required: true,
                  message: 'Select company'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                value={companyid}
                // onChange={(e) => handleCompany(e)}
                onChange={handleCompany}
                placeholder="Select company"
                showSearch
                optionFilterProp="children"
                // onChange={onChangeBatch}
                onSearch={handleSearch}
              >
                {companies?.map((dd, i) => (
                  <Option value={dd.id} key={i}>
                    {dd.company_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>

          <Col xs={24} xl={8} span={6}>
            <FormItem
              label="Project"
              // name="project"
              rules={[
                {
                  required: true,
                  message: 'Select project'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                value={projectid}
                placeholder="Select project"
                style={{ width: '100%' }}
                onChange={(e) => handleProject(e)}
                showSearch
                optionFilterProp="children"
                onSearch={handleSearch}
              >
                {projects?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.project_name}
                  </option>
                ))}
              </Select>
            </FormItem>
          </Col>

          <Col xs={24} xl={8} span={6}>
            <FormItem
              label="Project type"
              name="project_type"
              rules={[
                {
                  required: true,
                  message: 'Select project type'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select project type"
                style={{ width: '100%' }}
                onChange={(e) => handleProjectType(e)}
              >
                {projectsTypes?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.project_type_des}
                  </option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        {/* </Skeleton> */}
      </div>
    </>
  );
};

export default index;
