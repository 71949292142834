import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { documentdata } from './api';
// import { history } from 'app/history';

const initialState = {
  loading: false,
  formslist: {}
  // user: null
};

const actions = {
  GETDOCUMENTDATA: 'getDocumentData/GETDOCUMENTDATA'
};

export const getDocumentData = createAsyncThunk(
  actions.GETDOCUMENTDATA,
  async (payload) => {
    const response = await documentdata(payload);
    if (response.success === true) {
      // const messageText = response.message;
      // message.success(messageText);
    } else {
      //   // Set the error message
      const errorMessage = response.message;
      message.error(errorMessage);
    }
    return response;
  }
);

export const exportedSlice = createSlice({
  name: 'getDocumentData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocumentData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.formslist = data;
        // message: msg
        // message.success(msg);
      })
      .addCase(getDocumentData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default exportedSlice.reducer;
