import React from 'react';
import { Spin } from 'antd';
import styles from '@/common/Layout/index.module.less';

const Loader = () => {
  return (
    <div className={`df-jb-ac ${styles.loader_container}`}>
      <Spin />
    </div>
  );
};

export default Loader;
