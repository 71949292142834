import React from 'react';
import SetPassword from './components/';

function index() {
  return (
    <>
      <SetPassword />
    </>
  );
}

export default index;
