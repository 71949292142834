import React, { useState, useEffect } from 'react';
import { Card, Form, Steps, Space, Spin, message } from 'antd';
import styles from '../../index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import ProjectsPage from '../Projects';
import InvoiceDetails from '../IncoiceDetails';
import InvoicesList from '../InvoicesList';
import InvoiceTable from '../InvoiceTable';
import ValidateInvoice from '../ValidateInvoice';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompanyDetails,
  getProjectsDetails,
  getProjectTypeDetails,
  getPerformaDetails,
  createPerformaInvoice,
  getAuditTrails
} from '../../redux/slice';
import { getInvoiceDataById } from '@/pages/Invoice/redux/slice';
import { useParams } from 'react-router-dom';
const { Meta } = Card;
const ValidationPage = () => {
  const [form] = Form.useForm();
  const { companies, projects, projectsTypes, performaInvoices } = useSelector(
    (state) => state.IVTinvoices
  );
  const { invoicesData } = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const params = useParams();

  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [companyid, setCompanyId] = useState();
  const [projectid, setProjectId] = useState();
  const [projectTypeId, setProjectTypeId] = useState();
  const [remainingAmount, setRemainingAmount] = useState([]);
  const [auditData, setAuditData] = useState({});
  const [isModal, setIsModal] = useState(false);
  const [btnType, setBtnType] = useState('');
  const [search, setSearch] = useState('');
  // const [form] = Form.useForm();
  console.log(companies);
  useEffect(() => {
    InvoiceDataById();
    PerformaInvDetails();
  }, []);
  useEffect(() => {
    getProjects();
    getProjectTypes();
    PerformaInvDetails();
  }, [invoicesData]);

  useEffect(() => {
    getCompanies();
  }, [search]);
  useEffect(() => {
    // form.setFieldsValue({
    //   Company: invoicesData?.company_id
    // });
    setCompanyId(invoicesData?.company_id);
    setProjectId(invoicesData?.project_id);
  }, [invoicesData]);

  const getCompanies = () => {
    const payload = { id: invoicesData?.vendor_id, q: search };
    dispatch(getCompanyDetails(payload));
  };
  const getProjects = () => {
    const payload = { id: invoicesData?.vendor_id };
    dispatch(getProjectsDetails(payload));
  };
  const getProjectTypes = () => {
    const payload = { id: invoicesData?.vendor_id };
    dispatch(getProjectTypeDetails(payload));
  };

  const InvoiceDataById = () => {
    dispatch(getInvoiceDataById(params.id));
  };

  const PerformaInvDetails = () => {
    const payload = { id: invoicesData?.vendor_id };
    dispatch(getPerformaDetails(payload));
  };

  const selectedInvoices = performaInvoices?.filter((invoice) =>
    selectedRowKeys.includes(invoice.id)
  );

  const getAuditDetails = (id) => {
    setAuditData([]);
    setIsModal(true);
    dispatch(getAuditTrails(id)).then((res) => {
      if (res?.payload?.success) {
        setAuditData(res?.payload?.data);
        // setIsModal(true);
      }
    });
  };
  const items = [
    {
      title: 'Step 1',
      description: 'Project & Details',
      content: 'First-content',
      header: 'Project & Details'
      // content: <ProjectsPage />
    },
    {
      title: 'Step 2',
      description: 'Adjust Advance',
      content: 'Last-content'
      // header: 'Project & Details',
      // content: <ValidateInvoice />
    }
  ];

  const steps = [
    {
      title: 'First',
      content: (
        <ProjectsPage
          companies={companies}
          projects={projects}
          projectsTypes={projectsTypes}
          invoicesData={invoicesData}
          setCompanyId={setCompanyId}
          setProjectId={setProjectId}
          setProjectTypeId={setProjectTypeId}
          setSearch={setSearch}
          companyid={companyid}
          projectid={projectid}
        />
      ),
      header: '',
      dd: 0,
      sub: 1
    },
    {
      title: 'First',
      content: (
        <InvoiceDetails
          invoicesData={invoicesData}
          projectid={projectid}
          projects={projects}
          form={form}
        />
      ),
      header: '',
      dd: 1,
      sub: 2
    },
    {
      title: 'First',
      content: (
        <InvoicesList
          performaInvoices={performaInvoices}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          getAuditDetails={getAuditDetails}
          auditData={auditData}
          isModal={isModal}
          setIsModal={setIsModal}
          invoicesData={invoicesData}
          projectid={projectid}
          projects={projects}
        />
      ),
      header: '',
      dd: 1,
      sub: 3
    },
    {
      title: 'First',
      content: (
        <InvoiceTable
          // performaInvoices={performaInvoices}
          // selectedRowKeys={selectedRowKeys}
          selectedInvoices={selectedInvoices}
          // companyid={companyid}
          // projectTypeId={projectTypeId}
          // projectid={projectid}
          setRemainingAmount={setRemainingAmount}
          remainingAmount={remainingAmount}
          invoicesData={invoicesData}
        />
      ),
      header: '',
      dd: 1,
      sub: 4
    },
    {
      title: 'Last',
      content: (
        <ValidateInvoice
          performaInvoices={performaInvoices}
          selectedRowKeys={selectedRowKeys}
          companyid={companyid}
          projectTypeId={projectTypeId}
          projectid={projectid}
          remainingAmount={remainingAmount}
          setRemainingAmount={setRemainingAmount}
          invoiceid={params.id}
          selectedInvoices={selectedInvoices}
          form={form}
        />
      ),
      header: '',
      dd: 1
    }
  ];
  const next = (type) => {
    console.log(current);
    if (type === 'direct') {
      form
        .validateFields()
        .then((values) => {
          setLoading(true);
          const payload = {
            invoice_id: params.id,
            company_id: companyid,
            project_type_id: projectTypeId,
            project_id: projectid,
            ivt_remarks: values.ivt_remarks
          };
          dispatch(createPerformaInvoice(payload)).then((res) => {
            if (res.payload.success) {
              // message.success(res.payload.message);
              setLoading(false);
              history.push('/dashboard');
            } else {
              setLoading(false);
              message.error(res.payload.message);
            }
          });
        })
        .catch((errorInfo) => {
          // Handle form validation errors
          console.error('Form validation failed:', errorInfo);
        });
    } else {
      setCurrent(current + 1);
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const uniqueArray = (data, key) => {
    return [...new Map(data.map((item) => [key[item], item])).values()];
  };

  console.log(
    JSON.stringify(uniqueArray(remainingAmount, (it) => it.proforma_invoice_id))
  );
  const onFinish = (values) => {
    console.log('Success:', values, current, remainingAmount);

    let sum = 0;
    remainingAmount.forEach((num) => {
      sum += num?.adjustment_amount ? parseFloat(num?.adjustment_amount) : 0;
    });

    const unique = [];
    remainingAmount.map((item) => {
      if (item?.remaining_amount !== '') {
        unique.push({
          proforma_invoice_id: item?.proforma_invoice_id,
          adjustment_amount: item?.adjustment_amount,
          remaining_amount: item?.remaining_amount,
          adjustment_date: item?.adjustment_date
        });
      }
    });
    if (current === 2) {
      selectedInvoices?.map((invoice) => {
        remainingAmount.push({
          proforma_invoice_id: invoice?.id,
          adjustment_amount: '',
          remaining_amount: '',
          adjustment_date: ''
        });
      });
      setCurrent(current + 1);
    } else if (unique?.length !== selectedInvoices?.length) {
      message.error('Should be adjust all the selected Proforma invoices');
      setLoading(false);
    } else if (sum > invoicesData?.total_invoice_amount) {
      message.error('Proforma amount should be less than total invoice amount');
      setLoading(false);
    } else if (btnType === 'next') {
      console.log(btnType);
      setCurrent(current + 1);
    } else {
      setLoading(true);
      form.validateFields().then((values) => {
        const payload = {
          invoice_id: params.id,
          company_id: companyid,
          project_type_id: projectTypeId,
          project_id: projectid,
          ivt_remarks: values.ivt_remarks, // Include ivt_remarks here
          proforma_invoices: remainingAmount
        };
        dispatch(createPerformaInvoice(payload)).then((res) => {
          if (res.payload.success) {
            // message.success(res.payload.message);
            setLoading(false);
            history.push('/dashboard');
          }
        });
      });
    }
  };
  // console.log('invoicesDataaaaaaa', invoicesData?.request_no);

  const cancelClick = () => {
    history.push('/dashboard');
  };
  return (
    <>
      <Form layout="vertical" onFinish={onFinish}>
        <Card className={styles.cardbody}>
          <Meta title={invoicesData?.request_no} className={styles.metaData} />
          <Steps
            current={steps[current]?.dd}
            items={items}
            labelPlacement="vertical"
            className={styles.steps}
          >
            {items.map((item, index) => {
              return (
                <Steps.Step
                  key={index}
                  title={item.title}
                  description={item.description}
                  className={styles.insideSteps}
                />
              );
            })}
          </Steps>
          <br />
          <Card>
            {/* <Meta title="Project & Details" className={styles.metaData1} /> */}
            {steps[current]?.content}
            <br />
            <FormItem className="text-right">
              <Space>
                {current > 0 && (
                  <CustomButton
                    htmlType="button"
                    // type="primary"
                    onClick={prev}
                    className={styles.border_button}
                  >
                    Previous
                  </CustomButton>
                )}

                {current < steps.length - 1 && (
                  <Space>
                    {current === 0 ? (
                      <CustomButton onClick={cancelClick}>Cancel</CustomButton>
                    ) : (
                      ''
                    )}
                    {current == 1 ? (
                      invoicesData?.invoice_type_code === 'CN' ||
                      invoicesData?.invoice_type_code === 'PI' ? (
                        <CustomButton
                          htmlType="button"
                          // type="primary"
                          onClick={() => next('direct')}
                          loading={loading}
                          className={styles.border_button}
                        >
                          Validate
                        </CustomButton>
                      ) : (invoicesData?.invoice_type_code === 'TAX' ||
                          invoicesData?.invoice_type_code === 'BOS' ||
                          invoicesData?.invoice_type_code === 'DN' ||
                          invoicesData?.invoice_type_code === 'CI') &&
                        performaInvoices?.length ? (
                        <CustomButton
                          htmlType="submit"
                          // type="primary"
                          // onClick={() => next('adjust')}
                          onClick={() => setBtnType('next')}
                          className={styles.border_button}
                        >
                          Adjust Advance
                        </CustomButton>
                      ) : (
                        <CustomButton
                          htmlType="button"
                          type="primary"
                          onClick={() => next('direct')}
                          loading={loading}
                          className={styles.border_button}
                        >
                          Validate
                        </CustomButton>
                      )
                    ) : (
                      <CustomButton
                        htmlType="submit"
                        // type="primary"
                        //  type={current === 1 ? 'primary' : 'secondary'}
                        onClick={() => setBtnType('next')}
                        className={styles.border_button}
                      >
                        Next
                      </CustomButton>
                    )}
                    {/* <CustomButton
                      htmlType="button"
                      type="primary"
                      onClick={next}
                      className={styles.border_button}
                    >
                      {(invoicesData?.invoice_type_code === 'DN' ||
                          invoicesData?.invoice_type_code === 'PI') && current == 1
                        ? 
                           'Validate'
                          : 'Adjust Advance'
                        : 'Next Step'}
                    </CustomButton> */}
                  </Space>
                )}
                {current === steps.length - 1 &&
                  (loading ? (
                    <CustomButton
                      type="primary"
                      className={styles.border_button}
                    >
                      <Spin size="small" />
                    </CustomButton>
                  ) : (
                    <CustomButton
                      htmlType="submit"
                      // type="primary"
                      className={styles.bordersubmit_button}
                      onClick={() => setBtnType('')}
                    >
                      Submit
                    </CustomButton>
                  ))}
              </Space>
            </FormItem>
            {/* </Card> */}
          </Card>
        </Card>
      </Form>

      {/* <Form layout="vertical" onFinish={onFinish}>
        <Card className={styles.cardbody}>
          <Meta title=" PR-1000025" className={styles.metaData} />
          <Row gutter={24}>
            <Col span={24} xl={20}>
              <Steps items={items} labelPlacement="vertical">
                {items.map((item, index) => {
                  return (
                    <Steps.Step
                      key={index}
                      title={item.title}
                      description={item.name}
                    />
                  );
                })}
              </Steps>
            </Col>
          </Row>

          <br />
          <div className={styles.stepsContent}>{items[current].content}</div>
          <FormItem className="text-right">
            <Space>
              {current > 0 && (
                <CustomButton
                  htmlType="button"
                  type="primary"
                  onClick={prev}
                  className={styles.border_button}
                >
                  Previous
                </CustomButton>
              )}
              {current < items.length - 1 && (
                <CustomButton
                  htmlType="button"
                  type="primary"
                  onClick={next}
                  className={styles.border_button}
                >
                  Next Step
                </CustomButton>
              )}
              {current === items.length - 1 && (
                <CustomButton
                  htmlType="submit"
                  type="primary"
                  className={styles.border_button}
                >
                  {loading ? <Spin size="small" /> : 'Submit'}
                </CustomButton>
              )}
            </Space>
          </FormItem>
        </Card>
      </Form> */}
    </>
  );
};
export default ValidationPage;
