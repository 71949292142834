import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  // Card,
  Modal,
  Input,
  Tooltip,
  Button,
  Typography,
  Space,
  // Skeleton,
  Select,
  List
  // Checkbox
} from 'antd';
import FormItem from 'common/FormItem';
import styles from './index.module.less';
import CustomButton from '@/common/CustomButton';
import moment from 'moment';
import { approveInvoice } from '../redux/slice';
import { approvedIvtInvoice } from '../redux/slice';
import { getProjectTypeDetails } from '../../Validation/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckCircleTwoTone,
  CloseOutlined,
  CloseCircleTwoTone,
  EditOutlined,
  DownloadOutlined,
  ClockCircleOutlined
  // FilePdfOutlined
  // SaveOutlined
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import symbol from '../../Invoice/components/Generatepdf/images/info-hexagon.png';
import { TextArea } from 'semantic-ui-react';
import { getInvoiceData } from '../redux/slice';
// import { FileOutlined } from '@ant-design/icons';
import { getLoggedInUser } from 'utilities/helpers';
import { history } from '@/app/history';
import Attachmentcard from './AttachmentCard';

// import { ReactMultiEmail, isEmail } from 'react-multi-email';

// const index = (companies) => {
const index = ({
  companies,
  projects,
  IVTUsersList,
  // setCompanyId,
  // setProjectId,
  // setProjectTypeId,
  setSearch
  // onCheckboxChange
  // filterAttachments
}) => {
  const { user } = getLoggedInUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRejectVisible, setIsRejectVisible] = useState(false);
  const [isHoldVisible, setIsHoldVisible] = useState(false);
  const [is_approved, setIs_approved] = useState(false);
  const [action, setAction] = useState('');
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [remBtns, setRemBtns] = useState(false);
  // const [attachid, setAttachid] = useState([]);
  const [approvals, setApprovals] = useState([]);

  // const [isSubmitting, setIsSubmitting] = useState(false);

  // const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const { id } = useParams();
  // console.log('companies', companies);

  // <Attachmentcard onCheckboxChange />;

  useEffect(() => {
    // getVendorData();
    let payload = { id };
    getProjectTypes();
    dispatch(getInvoiceData(payload));
  }, []);

  const getProjectTypes = () => {
    const payload = { id: invoicedata?.vendor_id };
    dispatch(getProjectTypeDetails(payload));
  };

  // const formatNumberWithCommas = (number) => {
  //   return number.toLocaleString();
  // };

  // const formatNumberWithCommasAndDecimals = (number) => {
  //   if (isNaN(number)) return 'NA';
  //   return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  // };

  const { invoicedata } = useSelector((state) => state.getInvoiceData);
  // console.log('itemstesting111', invoicedata);
  const { invoice_details } = invoicedata || {};
  const header = invoice_details?.request_no;
  const { attachments } = invoicedata || {};
  const [isEditing, setIsEditing] = useState(false);
  // console.log('raattachment_details', attachments);
  const { projectsTypes } = useSelector((state) => state.IVTinvoices);
  // console.log('projectsTypes', projectsTypes);
  const [projectType, setProjectType] = useState(
    invoice_details?.project_type_des
  );
  const Supply = [
    {
      id: 1,
      name: 'Certified by EIC',
      value: invoice_details?.certified_by_eic ? 'YES' : 'NO'
    },
    {
      id: 2,
      name: 'Name of EIC',
      value: invoice_details?.name_of_eic.toUpperCase()
    },
    {
      id: 3,
      name: 'Date of Certification',
      value: moment(invoice_details?.date_of_certification).format('DD/MM/YYYY')
    },
    {
      id: 4,
      name: 'Invoice Type',
      value: invoice_details?.invoice_type_des.toUpperCase()
    },
    {
      id: 5,
      name: 'Reference Invoice Number',
      value:
        invoice_details?.invoice_type_des === 'Debit Note' ||
        invoice_details?.invoice_type_des === 'Credit Note'
          ? invoice_details?.reference_invoice_number
            ? invoice_details.reference_invoice_number.toUpperCase()
            : 'NA'
          : 'NA'
    },
    {
      id: 6,
      name: 'Reference Invoice Date',
      value:
        invoice_details?.invoice_type_des === 'Debit Note' ||
        invoice_details?.invoice_type_des === 'Credit Note'
          ? invoice_details?.reference_invoice_date
            ? moment(invoice_details.reference_invoice_date).format(
                'DD/MM/YYYY'
              )
            : 'NA'
          : invoice_details?.reference_invoice_date
          ? moment(invoice_details.reference_invoice_date).format('DD/MM/YYYY')
          : 'NA'
    },
    {
      id: 7,
      name: 'Certified abstract in standard Format',
      value: invoice_details?.certified_abstruct_in_stnd_format ? 'YES' : 'NO'
    },
    {
      id: 8,
      name: 'Final Bill',
      value: invoice_details?.final_bill ? 'YES' : 'NO'
    },
    {
      id: 9,
      name: 'FIM Reconciliation Statement',
      value: invoice_details?.fim_reconcilliation_statement
        ? invoice_details?.fim_reconcilliation_statement
          ? 'YES'
          : 'NO'
        : 'NA'
    },
    {
      id: 10,
      name: 'No Claim Certificate',
      value: invoice_details?.no_claim_certification
        ? invoice_details?.no_claim_certification
          ? 'YES'
          : 'NO'
        : 'NA'
    },
    {
      id: 11,
      name: 'E-Way Bill',
      value: invoice_details?.e_way_bill ? 'YES' : 'NO'
    },
    {
      id: 12,
      name: 'Delivery Challan',
      value: invoice_details?.delivery_challan ? 'YES' : 'NO'
    },
    { id: 13, name: 'LR Copy', value: invoice_details?.lr_copy ? 'YES' : 'NO' },
    {
      id: 14,
      name: 'LD Certification',
      value: invoice_details?.ld_certification_by_eic ? 'YES' : 'NO'
    },
    { id: 15, name: 'MSME', value: invoice_details?.msme ? 'YES' : 'NO' },
    {
      id: 16,
      name: 'Udayam Number',
      value: invoice_details?.udayam_number
        ? invoice_details?.udayam_number.toUpperCase()
        : 'NA'
    }
  ];

  const Invoices = [
    {
      id: 1,
      name: 'Name of Buyer',
      value: invoice_details?.name_of_buyer.toUpperCase()
    },
    {
      id: 2,
      name: 'Deal Slip / Order No',
      value: invoice_details?.deal_slip.toUpperCase()
    },
    {
      id: 3,
      name: 'Bill to Party',
      value: invoice_details?.bill_to_party.toUpperCase()
    },
    {
      id: 4,
      name: 'Work Area',
      value: invoice_details?.work_area.toUpperCase()
    },
    {
      id: 5,
      name: 'Vendor Name',
      value: invoice_details?.vendor_name.toUpperCase()
    },
    {
      id: 6,
      name: 'Vendor Code',
      value: invoice_details?.vendor_code.toUpperCase()
    },
    {
      id: 8,
      name: 'Currency',
      value: invoice_details?.currency_code.toUpperCase()
    },
    {
      id: 7,
      name: 'GST No',
      value: invoice_details?.gst_no
        ? invoice_details?.gst_no?.toUpperCase()
        : 'NA'
    },
    {
      id: 8,
      name: 'Invoice No',
      value: invoice_details?.invoice_no?.toUpperCase()
    },
    {
      id: 9,
      name: 'Invoice Date',
      value: moment(invoice_details?.inv_date).format('DD/MM/YYYY')
    },
    {
      id: 10,
      name: 'Invoice Basic Value',
      // value: invoice_details?.inv_basic_value
      //   ? formatNumberWithCommasAndDecimals(invoice_details?.inv_basic_value)
      //   : 'NA'
      value: invoice_details?.inv_basic_value
        ? parseFloat(invoice_details?.inv_basic_value)?.toLocaleString(
            invoice_details?.currency_code === 'EUR' ? 'de-DE' : 'en',
            {
              style: 'currency',
              currency: invoice_details?.currency_code
            }
          )
        : 'NA'
    },
    {
      id: 11,
      name: 'GST Amount',
      // value:
      //   // parseFloat(invoice_details?.cgst_amount) +
      //   // parseFloat(invoice_details?.sgst_amount)

      //   parseFloat(invoice_details?.cgst_amount) +
      //   parseFloat(invoice_details?.sgst_amount)
      //     ? parseFloat(invoice_details?.cgst_amount) +
      //       parseFloat(invoice_details?.sgst_amount)
      //     : parseFloat(invoice_details?.igst_amount) || 0
      value: (parseFloat(invoice_details?.cgst_amount) +
      parseFloat(invoice_details?.sgst_amount)
        ? parseFloat(invoice_details?.cgst_amount) +
          parseFloat(invoice_details?.sgst_amount)
        : parseFloat(invoice_details?.igst_amount) || 0
      )?.toLocaleString(
        invoice_details?.currency_code === 'EUR' ? 'de-DE' : 'en',
        {
          style: 'currency',
          currency: invoice_details?.currency_code || 'USD'
        }
      )
    },
    {
      id: 12,
      name: 'Total Invoice Amount',
      // value: parseFloat(invoice_details?.total_invoice_amount) || 0
      value: invoice_details?.invoice_amount
        ? invoice_details?.invoice_amount.toLocaleString(
            invoice_details?.currency_code === 'EUR' ? 'de-DE' : 'en',
            {
              style: 'currency',
              currency: invoice_details?.currency_code
            }
          )
        : 0 || 0
    },
    invoice_details?.invoice_category_code === 'SRVC'
      ? {
          id: 13,
          name: 'Period of rendered service (From Date)',
          // value: moment(invoice_details?.rendered_service_start_dt).format(
          //   'DD/MM/YYYY'
          // )
          value: invoice_details?.rendered_service_start_dt
            ? moment(invoice_details?.rendered_service_start_dt).format(
                'DD/MM/YYYY'
              )
            : 'NA'
        }
      : {
          id: 13,
          name: 'Period of rendered service (From Date)',
          value: 'NA'
        },
    invoice_details?.invoice_category_code === 'SRVC'
      ? {
          id: 14,
          name: 'Period of rendered service (To Date)',
          // value: moment(invoice_details?.rendered_service_end_dt).format(
          //   'DD/MM/YYYY'
          // )
          value: invoice_details?.rendered_service_end_dt
            ? moment(invoice_details?.rendered_service_end_dt).format(
                'DD/MM/YYYY'
              )
            : 'NA'
        }
      : {
          id: 14,
          name: 'Period of rendered service (To Date)',
          value: 'NA'
        },
    {
      id: 15,
      name: 'Material Description',
      value: invoice_details?.job_material_description
    }
  ];

  const { Title } = Typography;

  // const attachments = [
  //   // { name: 'attachments01attachments01.pdf', size: '600 KB' }
  //   // { name: 'attachments02.pdf', size: '600 KB' },
  //   // { name: 'attachments03.pdf', size: '600 KB' },
  //   // { name: 'attachments04.pdf', size: '600 KB' },
  // ];

  // const raAbstract = { name: 'Tech design requirements.xls', size: '200 KB' };
  let specificAttachment = null;
  let attachmentLength = 0;
  if (attachments && attachments.length > 0) {
    attachmentLength = attachments.length; // Get the length of the attachments array
    specificAttachment = attachments.find(
      (attachment) => attachment.attachment_type_code === 'RA'
    );
  }
  // const specificAttachmentLength = specificAttachment?.length;
  console.log('specificAttachmentLength', attachmentLength);

  const filteredAttachments = attachments?.filter(
    (attachment) => attachment.attachment_type_code !== 'RA'
  );
  const filteredAttachmentsLength = filteredAttachments?.length;
  console.log('filteredAttachmentsLength', filteredAttachmentsLength);

  // console.log('specificAttachment', specificAttachment);
  const [approvalStatus, setApprovalStatus] = useState(() => {
    const initialStatus = {};
    filteredAttachments?.forEach((item) => {
      console.log('12attachment_status', item.attachment_status);
      initialStatus[item.id] = {
        isApproved: false,
        isRejected: false
        // isApproved: item.attachment_status === 'approved',
        // isRejected: item.attachment_status === 'rejected'
      };
    });

    return initialStatus;
    // const storedStatus = localStorage.getItem('approvalStatus');
    // return storedStatus ? JSON.parse(storedStatus) : {};
  });

  const attachments_statuses = attachments?.filter((approval) => {
    let status = '';

    // Check if the user belongs to the 'SCROLL TEAM'
    if (user?.user_role_code === 'ST') {
      status = approval.attachment_status ? 'st-approve' : '';
    }
    // Check if the user belongs to the 'IVT' role
    else if (user?.user_role_code === 'IVT') {
      status = approval.attachment_status ? 'ivt-approve' : '';
    }

    return {
      // attachment_id: approval.fileId,
      attachment_status: status?.length // Ensure a status is always returned
    };
  });
  console.log('attachments_statuses', attachments_statuses);

  // const [statusCounts, setStatusCounts] = useState({
  //   stApprove: 0,
  //   ivtApprove: 0
  // });
  // useEffect(() => {
  //   const statusCounts = attachments?.reduce(
  //     (acc, approval) => {
  //       let status = '';

  //       if (user?.user_role_code === 'ST') {
  //         status = approval.attachment_status ? 'st-approve' : '';
  //       } else if (user?.user_role_code === 'IVT') {
  //         status = approval.attachment_status ? 'ivt-approve' : '';
  //       }

  //       if (status === 'st-approve') {
  //         acc.stApprove += 1;
  //       } else if (status === 'ivt-approve') {
  //         acc.ivtApprove += 1;
  //       }

  //       return acc;
  //     },
  //     { stApprove: 0, ivtApprove: 0 } // Initial counts set to 0
  //   );

  //   setStatusCounts(statusCounts);
  // }, [attachments]);
  // console.log('statusCounts', statusCounts);

  // useEffect(() => {
  //   localStorage.removeItem('setApprovalStatus');
  //   // Update approvalStatus in local storage whenever it changes
  //   localStorage.setItem('approvalStatus', JSON.stringify(setApprovalStatus));
  // }, [approvalStatus, setApprovalStatus]);

  // const AttachmentCard = ({ name, size }) => (
  //   <Card
  //     bodyStyle={{
  //       display: 'flex',
  //       alignItems: 'center',
  //       marginBottom: '0',
  //       padding: '10px',
  //       border: '1px solid #ddd',
  //       borderRadius: '8px',
  //       // width: '50%',
  //       whitesspace: 'nowrap',
  //       overflow: 'hidden',
  //       marginRight: '10px'
  //     }}
  //   >
  //     <FileOutlined
  //       style={{
  //         fontSize: 18,
  //         marginRight: 10,
  //         color: '#dfa161',
  //         background: '#fff9ec',
  //         border: '4px solid #fffcf6',
  //         borderRadius: '50%',
  //         padding: '10px'
  //       }}
  //     />
  //     <div>
  //       <Text>{name}</Text>
  //       <br />
  //       <Text type="secondary">{size}</Text>
  //     </div>
  //   </Card>
  // );
  // const [to, setTo] = useState('');
  // const [additionalEmails, setAdditionalEmails] = useState('');

  // console.log('to', form.getFieldValue('to'));
  const [form] = Form.useForm();
  useEffect(() => {
    if (invoice_details) {
      form.setFieldsValue({
        to: invoice_details.email
      });
    }
  }, [invoice_details, form]);

  const IVTDetails = [
    { id: 1, name: 'Company', value: invoice_details?.company_name },
    { id: 2, name: 'Project', value: invoice_details?.project_name },
    {
      id: 3,
      name: 'Project Type',
      value: projectType ? projectType : invoice_details?.project_type_des,
      isEditable: true
    }
  ];

  const onFinish = (values) => {
    setLoading(true);
    const {
      to,
      additionalEmails = '',
      assigned_to_ivt_person_id,
      company_id,
      project_id,
      comment
    } = values || {};
    console.log('values', values);

    // const cc_emails = additionalEmails;
    const invoice_id = id;
    // Split the emails by comma and trim any extra spaces
    // const cc_emails = emails ? emails.split(',').map((email) => email.trim()).filter(email => email) : [];
    // const cc_emails = ["sai@gmail.com","rohit@gmail.com"];
    const cc_emails = additionalEmails
      ? additionalEmails
          .split(',')
          .map((email) => email.trim())
          .filter((email) => email)
      : [];
    // const attachments_statuses = approvals.map((approval) => {
    //   {user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' ? (
    //   let status = approval.isApproved
    //     ? 'st-approve'
    //     : approval.isRejected
    //     ? 'st-reject'
    //     : '';
    //   ) : (
    //     let status = approval.isApproved
    //     ? 'st-approve'
    //     : approval.isRejected
    //     ? 'st-reject'
    //     : '';
    //   )}
    //   return {
    //     attachment_id: approval.fileId,
    //     attachment_status: status
    //   };
    // });
    const attachments_statuses = approvals.map((approval) => {
      let status = '';

      // Check if the user belongs to the 'SCROLL TEAM'
      if (user?.user_role_code === 'ST') {
        status = approval.isApproved
          ? 'st-approve'
          : approval.isRejected
          ? 'st-reject'
          : '';
      }
      // Check if the user belongs to the 'IVT' role
      else if (user?.user_role_code === 'IVT') {
        status = approval.isApproved
          ? 'ivt-approve'
          : approval.isRejected
          ? 'ivt-reject'
          : '';
      }

      return {
        attachment_id: approval.fileId,
        attachment_status: status || null // Ensure a status is always returned
      };
    });
    console.log('attachments_statuses', attachments_statuses);

    let payload = {
      invoice_id,
      to,
      cc_emails,
      is_approved,
      assigned_to_ivt_person_id,
      company_id,
      project_id,
      comment,
      attachments_statuses,
      // attachid,
      action: action ? action : 'hold',
      // values='hold'?'hold':action
      rejection_message: values?.rejection_message
    };
    console.log('payload', payload);
    // console.log('attachid', attachments_statuses);

    onSubmit(payload);
  };
  // const handleApproveSubmit = () => {
  //   let formData = new FormData();
  //   dispatch(approveInvoice(formData));
  //   setIsModalVisible(false);
  // };

  const onSubmit = async (payload) => {
    const response = await dispatch(approveInvoice(payload));
    if (response.payload.success) {
      setLoading(false);
      navigate('/dashboard');
    } else {
      setLoading(false);
    }
  };

  const handleApproveClick = () => {
    setIsModalVisible(true);
    setIs_approved(true);
    setAction(false);
  };

  const handleRejectClick = () => {
    setIsRejectVisible(true);
    setIs_approved(false);
    setAction('reject');
  };

  const handleBackClick = () => {
    history.push('/dashboard');
  };

  const holdClick = () => {
    setIsHoldVisible(true);
  };

  const handleHoldClick = (values) => {
    setRemBtns(true);
    setLoading(true);
    setIsRejectVisible(false);
    setIs_approved(false);
    // setAction(status);
    // const invoice_id = id;
    // const payload = { invoice_id, action: status };
    // console.log('values123456789', values);

    // const { to, additionalEmails = '', comment } = values || {};
    const { to, additionalEmails = '', comment } = values || {};
    // console.log('values', values);

    // const cc_emails = additionalEmails;
    const invoice_id = id;
    const cc_emails = additionalEmails
      ? additionalEmails
          .split(',')
          .map((email) => email.trim())
          .filter((email) => email)
      : [];

    let payload = {
      invoice_id,
      to,
      cc_emails,
      is_approved,
      comment,
      action:
        invoicedata?.invoice_details?.invoice_sts_des === 'IVT Hold'
          ? 'unhold'
          : 'hold'
    };
    console.log('payload', payload);

    onSubmit(payload);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleRejectCancel = () => {
    setIsRejectVisible(false);
  };

  const handleHoldCancel = () => {
    setIsHoldVisible(false);
  };

  const handlevalidateClick = () => {
    history.push(`/validation/${id}`);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleProjectTypeChange = (e) => {
    setProjectType(e);
    // console.log('handleProjectTypeChange', e);
  };

  const handleSaveClick = () => {
    setLoading(true);
    // console.log('handleProjectTypeChange_save', projectType);

    let payload = {
      invoice_id: id,
      project_type_id: projectType
    };
    // console.log('payload', payload);
    formSubmit(payload);
  };

  const handleRetainClick = () => {
    setIsEditing(false);
    setLoading(true);
    // console.log(
    //   'handleProjectTypeChangeeeeeeeeeee',
    //   invoice_details?.project_type_id
    // );
    let payload = {
      invoice_id: id,
      project_type_id: invoice_details?.project_type_id
    };
    // console.log('payload', payload);
    formSubmit(payload);
  };

  const handlePreview = () => {
    setPreviewVisible(true);
  };
  const formSubmit = async (payload) => {
    setLoading(true);
    const response = await dispatch(approvedIvtInvoice(payload));
    if (response.payload.success) {
      setRemBtns(false);
      setIsEditing(false);
      navigate('/dashboard');
    } else {
      setRemBtns(false);
    }
    setLoading(false);
  };

  const handleCompany = (e) => {
    console.log('e', e);
    // setCompanyId(e);
  };
  const handleProject = (e) => {
    // setProjectId(e);
    console.log('e', e);
  };
  const handleSearch = (e) => {
    setSearch(e);
  };

  const isHold = invoicedata?.invoice_details?.invoice_sts_des === 'IVT Hold';
  // // const buttonLabel = isHold ? 'Un Hold' : 'Hold';
  const buttonClickHandler = isHold ? handleHoldClick : holdClick;

  const handleApproval = (fileId, isApproved, isRejected) => {
    // Handle approval and reject logic here
    // console.log(
    //   `File ID: ${fileId}, Approved: ${isApproved}, Rejected: ${isRejected}`
    // );
    // Second Approch
    // const list = [...approvals];
    // list.push({ fileId, isApproved, isRejected });
    // setApprovals(list);
    //
    const list = [...approvals];
    const fileIndex = list.findIndex((item) => item.fileId === fileId);
    if (fileIndex !== -1) {
      list[fileIndex] = { fileId, isApproved, isRejected };
    } else {
      list.push({ fileId, isApproved, isRejected });
    }
    setApprovals(list);

    // setApprovalStatus((prevStatus) => ({
    //   ...prevStatus,
    //   [fileId]: { isApproved, isRejected }
    // }));

    // Handle approval and reject logic here
    const updatedApprovalStatus = { ...approvalStatus };

    if (!isApproved && !isRejected) {
      // Clear the approval status if both are unchecked
      delete updatedApprovalStatus[fileId];
    } else {
      // Update the approval status if one is checked
      updatedApprovalStatus[fileId] = { isApproved, isRejected };
    }

    setApprovalStatus(updatedApprovalStatus);
  };
  // console.log('payload_prevApprovals', approvals);

  const is_disabled = approvals.filter((data) => data.isRejected === true);

  const isActionButtonsEnabled = () => {
    if (!Array.isArray(attachments) || attachments.length === 0) {
      return false; // If attachments is not an array or empty, disable the buttons
    }
    const statuses = Object.values(approvalStatus);
    console.log('statuses', statuses);
    // const attachmentLength = attachments.length;

    // Calculate the status counts for 'st-approve' and 'ivt-approve'
    const statusCounts = attachments.reduce(
      (counts, attachment) => {
        if (attachment.attachment_status === 'st-approve') {
          counts.stApprove += 1;
        }
        if (attachment.attachment_status === 'ivt-approve') {
          counts.ivtApprove += 1;
        }
        return counts;
      },
      { stApprove: 0, ivtApprove: 0 }
    );
    console.log('statusCounts', statusCounts);
    console.log(
      'length',
      statuses?.length,
      attachmentLength,
      statusCounts.stApprove + statuses.length,
      statusCounts.ivtApprove + statuses.length
    );
    // Determine if all documents are processed
    console.log('User', user?.user_role_code);

    const allProcessed = (() => {
      if (user?.user_role_code === 'ST' && statusCounts.stApprove === 0) {
        return statuses.length === attachmentLength ? true : false;
      }
      if (user?.user_role_code === 'ST' && statusCounts.stApprove !== 0) {
        return statusCounts.stApprove + statuses.length === attachmentLength
          ? true
          : false;
      }
      if (user?.user_role_code === 'IVT' && statusCounts.ivtApprove === 0) {
        return statuses.length === attachmentLength ? true : false;
      }
      if (user?.user_role_code === 'IVT' && statusCounts.ivtApprove !== 0) {
        return statusCounts.ivtApprove + statuses.length === attachmentLength
          ? true
          : false;
      }
      // return true;
    })();
    console.log('allProcessed', allProcessed);
    // Buttons are enabled only if all documents are either approved or rejected
    return allProcessed;
  };

  return (
    <>
      <Form layout="vertical" className={styles.container}>
        <div className={styles.heading}> {header} </div>

        <div className={styles.supply}>
          <Row gutter={24} style={{ width: '100%' }}>
            <Col xl={12}>
              <div className={styles.divider}>
                {/* <p className={styles.header}> </p> */}
                <div className={styles.frame}>
                  <div className={styles.frame1}>
                    <Button
                      type="primary"
                      className={styles.download}
                      icon={<DownloadOutlined />}
                      href="path/to/your/file"
                      download
                    ></Button>
                    <div className={styles.frame2}>
                      {user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' ? (
                        <div className={styles.menu}>
                          {IVTDetails.map((item, index) => {
                            return (
                              <Row gutter={24} key={index}>
                                <Col span={12}>
                                  <ul>
                                    <li
                                      style={{ listStyleType: 'none' }}
                                      key={index}
                                    >
                                      {' '}
                                      {item.name}
                                    </li>
                                  </ul>
                                </Col>
                                <Col span={12}>
                                  <ul>
                                    <li
                                      key={index}
                                      style={{
                                        listStyleType: 'none',
                                        color: '#667085',
                                        fontWeight: '100',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      {' '}
                                      {/* {item.value} */}
                                      {item.isEditable &&
                                      isEditing &&
                                      !previewVisible ? (
                                        // <Input
                                        //   value={item.value}
                                        //   // onChange={handleChange}
                                        //   style={{ width: '70%' }}
                                        // />
                                        <Select
                                          placeholder="Select Invoice Type"
                                          style={{ width: '70%' }}
                                          name="projectType"
                                          value={item.value}
                                          onChange={(e) =>
                                            handleProjectTypeChange(e)
                                          }
                                        >
                                          {projectsTypes?.map((item) => (
                                            <option
                                              key={item.id}
                                              value={item.id}
                                            >
                                              {item.project_type_des}
                                            </option>
                                          ))}
                                        </Select>
                                      ) : item.isEditable && isEditing ? (
                                        projectsTypes.filter(
                                          (item) => item.id == projectType
                                        )?.[0]?.project_type_des
                                      ) : (
                                        item.value
                                      )}
                                      {item.isEditable && (
                                        <Button
                                          type="link"
                                          icon={
                                            isEditing ? (
                                              previewVisible
                                            ) : (
                                              // <SaveOutlined />
                                              // ''
                                              <EditOutlined />
                                            )
                                          }
                                          onClick={
                                            isEditing
                                              ? handleSaveClick
                                              : handleEditClick
                                          }
                                          style={{ marginLeft: '20px' }}
                                        />
                                      )}
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      ) : (
                        ''
                      )}
                      <div className={styles.menu}>
                        {Supply.map((item, index) => {
                          return (
                            <Row gutter={24} key={index}>
                              <Col span={12}>
                                <ul>
                                  <li
                                    style={{ listStyleType: 'none' }}
                                    key={index}
                                  >
                                    {' '}
                                    {item.name}
                                  </li>
                                </ul>
                              </Col>
                              <Col span={12}>
                                <ul>
                                  <li
                                    key={index}
                                    style={{
                                      listStyleType: 'none',
                                      color: '#667085',
                                      fontWeight: '100'
                                    }}
                                  >
                                    {' '}
                                    {item.value}
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                      <div className={styles.menu}>
                        {Invoices.map((item, index) => {
                          return (
                            <Row gutter={24} key={index}>
                              <Col span={12}>
                                <ul>
                                  <li
                                    style={{ listStyleType: 'none' }}
                                    key={index}
                                  >
                                    {' '}
                                    {item.name}
                                  </li>
                                </ul>
                              </Col>
                              <Col span={12}>
                                <ul>
                                  <li
                                    key={index}
                                    style={{
                                      listStyleType: 'none',
                                      color: '#667085',
                                      fontWeight: '100'
                                    }}
                                  >
                                    {item.value}
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <div className={styles.cardattachments}>
                <div className={styles.attachments}>
                  <Title level={5}>Attachments</Title>
                  {/* {invoice_details?.generated_inv_filename ? (
                <AttachmentCard
                  name={
                    invoice_details?.generated_inv_filename
                      ? `${invoice_details.generated_inv_filename}.pdf`
                      : 'defaultName.pdf'
                  }
                  // size={raAbstract.size}
                />
              ) : (
                <Skeleton />
              )} */}
                  {/* {filteredAttachments?.length > 0 ? (
                <List
                  className={styles.attachments}
                  itemLayout="vertical"
                  dataSource={filteredAttachments}
                  renderItem={(item) => (
                    <List.Item className={styles.attachmentcardstyles}>
                      <AttachmentCard
                        name={
                          item.file_name ? item.file_name : 'defaultName.pdf'
                        }
                        url={item.file_url}
                      />
                    </List.Item>
                  )}
                />
              ) : (
                <Skeleton />
              )} */}
                  {filteredAttachments?.length > 0 ? (
                    <List
                      className={styles.attachments}
                      itemLayout="vertical"
                      dataSource={filteredAttachments}
                      renderItem={(item) => (
                        <List.Item className={styles.attachmentCardStyles}>
                          <Attachmentcard
                            name={
                              item.file_name
                                ? item.file_name
                                : 'defaultName.pdf'
                            }
                            fileId={item.id}
                            url={item.file_url}
                            onApproval={handleApproval}
                            status={item.attachment_status}
                            // onCheckboxChange={onCheckboxChange}
                          />
                        </List.Item>
                      )}
                    />
                  ) : (
                    // <Skeleton />
                    <p className={styles.noRA}>
                      No attachment for the selected invoice type{' '}
                    </p>
                  )}
                </div>

                <div className={styles.raabstract}>
                  <Title level={5}>RA Abstract</Title>

                  {specificAttachment && specificAttachment.file_name ? (
                    <>
                      <Attachmentcard
                        name={
                          specificAttachment.file_name
                            ? specificAttachment.file_name
                            : 'defaultName.xlsx'
                        }
                        fileId={specificAttachment.id}
                        url={specificAttachment.file_url}
                        // onCheckboxChange={onCheckboxChange}
                        onApproval={handleApproval}
                        status={specificAttachment.attachment_status}
                        // size={(specificAttachment.file_name.size / 1024).toFixed(2)}
                        data-tip="Your tooltip content here"
                      />
                    </>
                  ) : (
                    // <Skeleton />
                    <p className={styles.noRA}>
                      No RA abstract for the selected invoice type{' '}
                    </p>
                  )}
                </div>

                <FormItem className={`text-right ${styles.sectionfooter}`}>
                  {previewVisible &&
                  user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' ? (
                    <Space size={12}>
                      <CustomButton
                        onClick={() => history.push('/dashboard')}
                        // className={styles.rejectbutton}
                        type="secondary"
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        onClick={handleSaveClick}
                        className={styles.successbutton}
                        // type="primary"
                        loading={loading}
                      >
                        Approve Entity
                      </CustomButton>
                    </Space>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    isEditing ? (
                    <Space size={12}>
                      <CustomButton
                        onClick={() => history.push('/dashboard')}
                        // className={styles.rejectbutton}
                        // type="secondary"
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        onClick={handlePreview}
                        // className={styles.rejectbutton}
                        // type="primary"
                      >
                        Preview
                      </CustomButton>
                    </Space>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    !isEditing ? (
                    <CustomButton
                      // onClick={handleRejectClick}
                      onClick={handleRetainClick}
                      className={styles.successbutton}
                      // type="primary"
                      loading={loading}
                    >
                      Retain
                    </CustomButton>
                  ) : (
                    <Space size={0}>
                      {/* Back Option */}
                      <CustomButton
                        onClick={handleBackClick}
                        className={styles.backbutton}
                        // disabled={
                        //   invoicedata?.invoice_details?.invoice_sts_des ===
                        //     'IVT Hold' || remBtns
                        // }
                        // type="reject"
                      >
                        Back
                      </CustomButton>
                      {/* Back Option End */}
                      <CustomButton
                        onClick={handleRejectClick}
                        className={styles.rejectbutton}
                        disabled={
                          !isActionButtonsEnabled() ||
                          invoicedata?.invoice_details?.invoice_sts_des ===
                            'IVT Hold' ||
                          remBtns
                        }
                        // type="reject"
                      >
                        Reject
                      </CustomButton>
                      {user?.user_role_des == 'INVOICE VALIDATION TEAM' ? (
                        <CustomButton
                          // onClick={() =>
                          //   handleHoldClick(
                          //     invoicedata?.invoice_details?.invoice_sts_des ===
                          //       'IVT Hold'
                          //       ? 'unhold'
                          //       : 'hold'
                          //   )
                          // }
                          // onClick={holdClick}
                          onClick={buttonClickHandler}
                          // form={form}
                          className={styles.holdbutton}
                          // onFinish={onFinish}
                          // onClick={() => {
                          //   setAction('hold');
                          //   onFinish('hold')}}
                          // onClick={() => onFinish('hold')}
                          // type="secondary"
                          htmlType="submit"
                          loading={loading}
                        >
                          {invoicedata?.invoice_details?.invoice_sts_des ===
                          'IVT Hold'
                            ? 'Un Hold'
                            : 'Hold'}
                        </CustomButton>
                      ) : (
                        ''
                      )}
                      {user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                      (invoicedata?.invoice_details?.invoice_type_code !==
                        'CN' ||
                        invoicedata?.invoice_details?.invoice_type_code !==
                          'PI') ? (
                        <CustomButton
                          onClick={handlevalidateClick}
                          form={form}
                          className={styles.approvedbutton}
                          // type="approve"
                          disabled={
                            !isActionButtonsEnabled() ||
                            invoicedata?.invoice_details?.invoice_sts_des ===
                              'IVT Hold' ||
                            remBtns ||
                            (is_disabled.length > 0 ? true : false)
                          }
                        >
                          Proceed for Validate
                        </CustomButton>
                      ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                        (invoicedata?.invoice_details?.invoice_type_code ===
                          'CN' ||
                          invoicedata?.invoice_details?.invoice_type_code ===
                            'PI') ? (
                        isEditing ? (
                          <CustomButton
                            onClick={handlevalidateClick}
                            form={form}
                            className={styles.approvedbutton}
                            // disabled={
                            //   invoicedata?.invoice_details?.invoice_sts_des ===
                            //   'IVT Hold'
                            // }
                          >
                            {/* {isEditing ? Preview : Retain} */}
                            Preview
                          </CustomButton>
                        ) : (
                          <CustomButton
                            onClick={handlevalidateClick}
                            form={form}
                            className={styles.approvedbutton}
                            // disabled={
                            //   invoicedata?.invoice_details?.invoice_sts_des ===
                            //   'IVT Hold'
                            // }
                          >
                            {/* {isEditing ? Preview : Retain} */}
                            Retain
                          </CustomButton>
                        )
                      ) : (
                        <CustomButton
                          onClick={handleApproveClick}
                          className={styles.approvedbutton}
                          // type="approve"
                          disabled={
                            !isActionButtonsEnabled() || is_disabled.length > 0
                              ? true
                              : false
                          }
                        >
                          {/* Approve */}
                          Proceed to approve
                        </CustomButton>
                      )}
                    </Space>
                  )}
                </FormItem>
              </div>
            </Col>
          </Row>
        </div>
      </Form>

      {/* Approve Modal */}
      <Modal
        className={styles.approvemodel}
        form={form}
        title={
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: '30px' }}
          />
        }
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        closeIcon={<CloseOutlined />}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {/* <Form.Item
            label="Approve"
            name="comment"
            className={styles.labelrejection}
            rules={[{ required: true, message: 'Enter reason for approve' }]}
          >
            <TextArea
              placeholder="Enter reason for approve"
              style={{ width: '100%', border: '1px solid #dcdce4' }}
            />
          </Form.Item> */}
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="company_id"
                label="Company"
                rules={[
                  { required: true, message: 'Please select your company!' }
                ]}
              >
                <Select
                  onChange={(e) => handleCompany(e)}
                  placeholder="Select company"
                  showSearch
                  optionFilterProp="children"
                  // onChange={onChangeBatch}
                  onSearch={handleSearch}
                  className={styles.companytype}
                >
                  {companies?.map((dd, i) => (
                    <option value={dd.id} key={i}>
                      {dd.company_name}
                    </option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="project_id"
                label="Project"
                rules={[
                  { required: true, message: 'Please enter your project!' }
                ]}
              >
                <Select
                  onChange={(e) => handleProject(e)}
                  placeholder="Select project"
                  style={{ width: '100%' }}
                  showSearch
                  optionFilterProp="children"
                  onSearch={handleSearch}
                  className={styles.companytype}
                >
                  {projects?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.project_name}
                    </option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="assigned_to_ivt_person_id"
                label="Assign to IVT"
                rules={[{ required: true, message: 'Please enter name!' }]}
              >
                <Select
                  placeholder="Select ivt person"
                  style={{ width: '100%' }}
                  onChange={(e) => handleProject(e)}
                  showSearch
                  optionFilterProp="children"
                  onSearch={handleSearch}
                >
                  {IVTUsersList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.email}
                    </option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            // label="to"
            name="to"
            value={invoice_details && invoice_details?.email}
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              className={styles.mailbody}
              disabled
              // onChange={(e) => setTo(e.target.value)}
              prefix={
                <span className={styles.inputgrouptext} id="basic-addon1">
                  To:
                </span>
              }
              placeholder={invoice_details?.email}
              aria-label={invoice_details?.email}
              aria-describedby="basic-addon1"
            />
          </Form.Item>
          <Form.Item
            className={styles.formcc}
            label={
              <>
                {`Additional mail ID's `}
                <Tooltip
                  title="Separate different email id's with a comma"
                  overlayClassName="custom-tooltip"
                  className={styles.customtooltip}
                >
                  <img src={symbol} alt="info" style={{ marginLeft: 8 }} />
                </Tooltip>
              </>
            }
            name="additionalEmails"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.resolve();
                  }
                  const emailsArray = value
                    .split(',')
                    .map((email) => email.trim());
                  if (emailsArray.length === 0) {
                    return Promise.reject(
                      new Error('Please enter at least one email address')
                    );
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input
              className={styles.mailbody}
              prefix={
                <span className={styles.inputgrouptext} id="basic-addon2">
                  cc:
                </span>
              }
              placeholder="Enter email ID"
              aria-label="Enter mail id"
              aria-describedby="basic-addon2"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
            />
          </Form.Item>
          <Form.Item className={styles.modalbtn}>
            <Button
              type="default"
              onClick={handleModalCancel}
              style={{ marginRight: 8 }}
              className={styles.cancelbutton}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              // type="primary"
              htmlType="submit"
              // onClick={handleApproveSubmit}
              loading={loading}
              className={styles.approvebutton}
            >
              Approve
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Approve Modal END */}
      {/* Reject Modal */}
      <Modal
        style={{ borderBottom: 0 }}
        title={
          <CloseCircleTwoTone
            twoToneColor="#f5222d"
            style={{ fontSize: '30px' }}
            // className={styles.modalheader}
          />
        }
        visible={isRejectVisible}
        onCancel={handleRejectCancel}
        footer={null}
        closeIcon={<CloseOutlined />}
        // className={styles.modelheader}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Rejection"
            // name="rejection_message"
            name="comment"
            className={styles.labelrejection}
            rules={[{ required: true, message: 'Enter reason for rejection' }]}
          >
            <TextArea
              placeholder="Enter reason for rejection"
              style={{ width: '100%', border: '1px solid #dcdce4' }}
            />
          </Form.Item>
          <Form.Item
            // label="to"
            name="to"
            value={invoice_details?.email}
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              className={styles.mailbody}
              disabled
              prefix={
                <span className={styles.inputgrouptext} id="basic-addon1">
                  To:
                </span>
              }
              placeholder={invoice_details?.email}
              aria-label={invoice_details?.email}
              aria-describedby="basic-addon1"
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                {`Additional mial ID's`}
                <Tooltip
                  title="Separate different email id's with a comma"
                  overlayClassName="custom-tooltip"
                  className={styles.customtooltip}
                >
                  <img src={symbol} alt="info" style={{ marginLeft: 8 }} />
                </Tooltip>
              </>
            }
            name="additionalEmails"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.resolve();
                  }
                  const emailsArray = value
                    .split(',')
                    .map((email) => email.trim());
                  if (emailsArray.length === 0) {
                    return Promise.reject(
                      new Error('Please enter at least one email address')
                    );
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input
              className={styles.mailbody}
              prefix={
                <span className={styles.inputgrouptext} id="basic-addon2">
                  cc:
                </span>
              }
              placeholder="Enter email ID"
              aria-label="Enter email ID"
              aria-describedby="basic-addon2"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
            />
          </Form.Item>
          <Form.Item className={styles.modalbtn}>
            <Button
              // type="default"
              onClick={handleRejectCancel}
              style={{ marginRight: 8 }}
              className={styles.cancelbutton}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              // type="primary"
              htmlType="submit"
              // onClick={handleApproveSubmit}
              loading={loading}
              className={styles.rejectedbutton}
            >
              Reject
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Reject Modal END */}

      {/* Hold Modal */}
      <Modal
        style={{ borderBottom: 0 }}
        // title={
        //   <CloseCircleTwoTone
        //     twoToneColor="#f5222d"
        //     style={{ fontSize: '30px' }}
        //     // className={styles.modalheader}
        //   />
        // }
        title={
          <ClockCircleOutlined style={{ fontSize: '24px', color: '#08c' }} />
        }
        visible={isHoldVisible}
        onCancel={handleHoldCancel}
        footer={null}
        closeIcon={<CloseOutlined />}
        // className={styles.modelheader}
      >
        {/* <Form form={form} layout="vertical" onFinish={onFinish}> */}
        <Form
          form={form}
          layout="vertical"
          // onFinish={onFinish}
          onFinish={handleHoldClick}
          // onFinish={() =>
          //   handleHoldClick(
          //     invoicedata?.invoice_details?.invoice_sts_des === 'IVT Hold'
          //       ? 'unhold'
          //       : 'hold'
          //   )
          // }
        >
          <Form.Item
            label="Hold Comments"
            name="comment"
            className={styles.labelrejection}
            rules={[{ required: true, message: 'Enter reason for hold' }]}
          >
            <TextArea
              placeholder="Enter reason for hold"
              style={{ width: '100%', border: '1px solid #dcdce4' }}
            />
          </Form.Item>
          {/* <Form.Item
            // label="to"
            name="to"
            value={invoice_details?.email}
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              className={styles.mailbody}
              disabled
              prefix={
                <span className={styles.inputgrouptext} id="basic-addon1">
                  To:
                </span>
              }
              placeholder={invoice_details?.email}
              aria-label={invoice_details?.email}
              aria-describedby="basic-addon1"
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                {`Additional mial ID's`}
                <Tooltip
                  title="Separate different email id's with a comma"
                  overlayClassName="custom-tooltip"
                  className={styles.customtooltip}
                >
                  <img src={symbol} alt="info" style={{ marginLeft: 8 }} />
                </Tooltip>
              </>
            }
            name="additionalEmails"
            rules={[
              {
                validator: async (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.resolve();
                  }
                  const emailsArray = value
                    .split(',')
                    .map((email) => email.trim());
                  if (emailsArray.length === 0) {
                    return Promise.reject(
                      new Error('Please enter at least one email address')
                    );
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input
              className={styles.mailbody}
              prefix={
                <span className={styles.inputgrouptext} id="basic-addon2">
                  cc:
                </span>
              }
              placeholder="Enter email ID"
              aria-label="Enter email ID"
              aria-describedby="basic-addon2"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
            />
          </Form.Item> */}
          <Form.Item className={styles.modalbtn}>
            <Button
              // type="default"
              onClick={handleHoldCancel}
              style={{ marginRight: 8 }}
              className={styles.cancelbutton}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              // type="primary"
              htmlType="submit"
              // onClick={handleApproveSubmit}
              loading={loading}
              className={styles.holdingbutton}
            >
              {/* Reject */}
              {invoicedata?.invoice_details?.invoice_sts_des === 'IVT Hold'
                ? 'Un Hold'
                : 'Hold'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Hold Modal END */}
    </>
  );
};

export default index;
