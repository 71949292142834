import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'pages/login/redux/slice';
import dashboardReducer from 'pages/Dashboard/redux/slice';
import InvoicesReducer from 'pages/Invoice/redux/slice';
import invoiceDataReducer from 'pages/Scrollview/redux/slice';
import IVTinvoicesReducer from 'pages/Validation/redux/slice';
import DownloadFilesList from 'pages/FormDownloads/redux/slice';
import GetCompanyDetails from 'pages/Scrollview/redux/slice';
import GetAllRemarks from 'pages/Viewpage/redux/slice';
import GetNotiveBoard from 'pages/NoticeBoard/redux/slice';
import GetAdvanceSearchData from 'pages/AdvanceSearch/redux/slice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    vendorDashboard: dashboardReducer,
    invoices: InvoicesReducer,
    getInvoiceData: invoiceDataReducer,
    IVTinvoices: IVTinvoicesReducer,
    formslist: DownloadFilesList,
    companydetails: GetCompanyDetails,
    Allremarks: GetAllRemarks,
    NotiveBoard: GetNotiveBoard,
    SearchData: GetAdvanceSearchData
    // Define your reducers here
    // Learn more here: https://redux.js.org/tutorials/fundamentals/part-3-state-actions-reducers
  }
});
