import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { searchdata, undodata } from './api';
// import { history } from 'app/history';

const initialState = {
  loading: false,
  allOnBoardingList: [],
  pagination: {}
};

const actions = {
  GETFETCHINVOICEDATA: 'getfetchinvoiceData/GETFETCHINVOICEDATA',
  GETUNDOINVOICEDATA: 'getundoinvoiceData/GETUNDOINVOICEDATA'
  //   ADSEARCHEXPORT: 'adSearchExport/ADSEARCHEXPORT'
};

export const getfetchinvoiceData = createAsyncThunk(
  actions.GETFETCHINVOICEDATA,
  async (payload) => {
    const response = await searchdata('/invoice/undo-action-invoice', payload);
    if (response.success === true) {
      const messageText = response.message;
      message.success(messageText);
    } else {
      //   // Set the error message
      const errorMessage = response.message;
      message.error(errorMessage);
    }
    return response;
  }
);

export const getundoinvoiceData = createAsyncThunk(
  actions.GETUNDOINVOICEDATA,
  async (payload) => {
    const response = await undodata(
      '/invoice/ivt/undo-invoice-validate',
      payload
    );
    if (response.success === true) {
      const messageText = response.message;
      message.success(messageText);
    } else {
      //   // Set the error message
      const errorMessage = response.message;
      message.error(errorMessage);
    }
    return response;
  }
);

export const exportedSlice = createSlice({
  name: 'getData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getfetchinvoiceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getfetchinvoiceData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        // console.log('data.items', data.items);
        // console.log('data.meta', data.meta);
        state.allOnBoardingList = data.items ? data.items : [];
        state.pagination = data.meta || null;
        // state.searchData = data;
        // message: msg
        // message.success(msg);
      })
      .addCase(getfetchinvoiceData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getundoinvoiceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getundoinvoiceData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        // console.log('data.items', data.items);
        // console.log('data.meta', data.meta);
        state.allOnBoardingList = data.items ? data.items : [];
        state.pagination = data.meta || null;
        // state.searchData = data;
        // message: msg
        // message.success(msg);
      })
      .addCase(getundoinvoiceData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default exportedSlice.reducer;
