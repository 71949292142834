import React from 'react';
import Layout from '@/common/Layout';
import TrainingVideo from '../TrainingVideo/components';

const index = () => {
  return (
    <>
      <Layout sider={true}>
        {/* <h1 style={{ textAlign: 'center' }}>Training Video</h1> */}
        {/* <TrainingVideo url="https://www.youtube.com/watch?v=85FnhX6d4bc" /> */}
        <TrainingVideo url="https://youtu.be/cu4yWk76ipk" />
      </Layout>
    </>
  );
};

export default index;
