import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { resetpassword } from './api';
import { history } from 'app/history';

const initialState = {
  loading: false,
  token: '',
  user: null
};

const actions = {
  RESETPASSWORD: 'resetpassword/RESETPASSWORD'
};

export const ResetPassword = createAsyncThunk(
  actions.RESETPASSWORD,
  async (payload) => {
    const response = await resetpassword(payload);

    if (response.success === true) {
      // const { access_token, user } = response;
      const messageText = response.message || 'Mail sent successfully';
      // console.log('email', response.success.data);
      message.success(messageText);
      history.push('/login');
    } else {
      // Set the error message
      const errorMessage =
        response.message ||
        'Login failed. Please check your credentials and try again';
      message.error(errorMessage);
    }

    return response;
  }
);

export const resetpasswordSlice = createSlice({
  name: 'resetpassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetpassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetpassword.fulfilled, (state, action) => {
        const { access_token, message: msg, user } = action.payload;
        state.loading = false;
        state.token = access_token;
        state.user = user;

        if (access_token) {
          message.success(msg, [1]);
        } else {
          message.error(msg);
        }
      })
      .addCase(resetpassword.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default resetpasswordSlice.reducer;
