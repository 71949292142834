import React from 'react';
import Layout from '@/common/Layout';
import InvoicePage from '@/pages/Validation/components/ValidationPage';

const Invoice = () => {
  return (
    <>
      <Layout sider={true}>
        <InvoicePage />
      </Layout>
    </>
  );
};

export default Invoice;
