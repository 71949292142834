import React from 'react';
import Marquee from 'react-fast-marquee';
import './NewsTicker.css'; // Import the CSS file

// const NewsTicker = () => {
//   return (
//     <div>
//       <Marquee pauseOnHover gradient={false} speed={50} className="marquee">
//         <div className="news-item">
//           <h3>Notice Board Updated, Please check it once.</h3>
//         </div>
//       </Marquee>
//     </div>
//   );
// };
const NewsTicker = () => {
  return (
    <div className="ticker-container">
      <Marquee pauseOnHover gradient={false} speed={50}>
        <div className="news-item">
          <h3>Notice Board Updated, Please check it once.</h3>
        </div>
        {/* <div className="news-item">
          <h3>New Event Coming Up Next Week!</h3>
        </div> */}
        {/* <div className="news-item">
          <h3>System Maintenance Scheduled for Tomorrow</h3>
        </div> */}
      </Marquee>
    </div>
  );
};

export default NewsTicker;
