import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  InputNumber,
  Space,
  Tooltip
  // Popover
} from 'antd';
import styles from '../../index.module.less';
import FormItem from '@/common/FormItem';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const invoice = ({
  currencies,
  gstTypes,
  invoiceValues,
  invoicesData,
  setVendorData,
  vendorData,
  currency,
  setCurrency,
  // setInvoicesData,
  vendorslist,
  buyerslist,
  user,
  handleCurrency,
  handleGst,
  setGstData,
  gstData,
  cgstData,
  setCgstData,
  sgstData,
  setSgstData,
  igstData,
  setIgstData,
  basicInvoice,
  setBasicInvoice,
  buyername,
  setSearch,
  invoicetypes
}) => {
  const handleChangeVendor = (e) => {
    const vendor = vendorslist.filter((item) => item.id === e);
    setVendorData(vendor[0]);
    console.log(vendor);
    // setInvoicesData
    // setInvoicesData({
    //   ...invoicesData,
    //   vendor_code: vendor[0]?.vendor_code,
    //   vendor_name: vendor[0]?.vendor_name
    // });
  };
  const [invoiceTypeCode, setInvoiceTypeCode] = useState({});
  console.log('invoice_category_code', invoicesData);
  console.log('invoiceValues', invoiceValues?.invoice_type);

  const handleTotalInvoice = (e, type) => {
    if (type === 'cgst') {
      setCgstData(e);
    } else if (type === 'sgst') {
      setSgstData(e);
    } else if (type === 'igst') {
      setIgstData(e);
    } else if (type === 'basicInvoice') {
      setBasicInvoice(e);
    }
  };
  useEffect(() => {
    const gst_type = gstTypes?.length
      ? gstTypes.filter(
          (item) => item.gst_type_code === invoicesData?.gst_type_code
        )
      : [];
    console.log(gst_type);
    setGstData(gst_type?.length ? gst_type[0]?.gst_type_code : gstData);
    setCgstData(invoicesData?.cgst_amount || cgstData);
    setSgstData(invoicesData?.sgst_amount || sgstData);
    setIgstData(invoicesData?.igst_amount || igstData);
    setBasicInvoice(invoicesData?.inv_basic_value || basicInvoice);
    setCurrency(
      invoicesData?.currency_code || invoiceValues?.currency_code || currency
    );
    console.log(invoicesData?.gst_type_id);
    const gsts = gstTypes.filter(
      (item) => item.id === invoicesData?.gst_type_id
    );
    console.log(gsts, 'llll');
    setGstData(gsts?.length ? gsts?.[0]?.gst_type_code : gstData);
  }, [invoicesData]);

  useEffect(() => {
    const matchingType = invoicetypes.find(
      (type) => type.id === invoiceValues.invoice_type
    );
    // console.log('matchingType', matchingType);
    if (matchingType) {
      setInvoiceTypeCode(matchingType);
    } else {
      setInvoiceTypeCode('Type not found');
    }
  }, [invoicetypes, invoiceValues.invoice_type]);

  console.log('invoiceTypeCode', invoiceTypeCode);

  console.log(invoicesData, gstData);
  const validateInvoiceNo = (_, value) => {
    const regex = /^[a-zA-Z0-9/-]*$/;
    // if (value && value.length > 16) {
    if (value && (!regex.test(value) || value.length > 16)) {
      return Promise.reject(
        new Error(
          'Only letters, numbers, /, and - are allowed, with a maximum of 16 characters.'
        )
      );
    }
    if (value && /\s/.test(value)) {
      return Promise.reject(new Error('Invoice number cannot contain spaces'));
    }
    return Promise.resolve();
  };

  // const validateDealslip = (_, value) => {
  //   const regex = /^[a-zA-Z0-9/]*$/;
  //   if (value && !regex.test(value)) {
  //     return Promise.reject(
  //       new Error('Only letters, numbers, and / are allowed')
  //     );
  //   }
  //   return Promise.resolve();
  // };

  const validateAmount = (_, value) => {
    const regex = /^[0-9.]*$/;
    if (value && !regex.test(value)) {
      return Promise.reject(new Error('Only numbers are allowed'));
    }
    return Promise.resolve();
  };

  const validateGSTNo = (_, value) => {
    if (value && value.length > 15) {
      return Promise.reject(
        new Error('GST number cannot exceed 15 characters')
      );
    }
    if (value && /\s/.test(value)) {
      return Promise.reject(new Error('GST number cannot contain spaces'));
    }
    if (value && /[^a-zA-Z0-9]/.test(value)) {
      return Promise.reject(
        new Error('GST number can only contain letters and numbers')
      );
    }
    return Promise.resolve();
  };
  // console.log('buyername', buyername);
  const handleCompany = (e) => {
    console.log('e', e);
    // setCompanyId(e);
  };

  const handleSearch = (e) => {
    setSearch(e);
  };

  // const name_of_buyer = buyerslist.find(buyer => buyer.id === buyer_id);
  // const proformaData =
  //   invoiceValues?.invoice_type == 'BDEE433E-F36B-1410-8194-00685A84348C';
  // console.log('log', proformaData);
  // const proformaData = 'B5EE433E-F36B-1410-8194-00685A84348C';
  // console.log('log', proformaData);
  // State to store the selected 'from' and 'to' dates
  const [periodFromDate, setPeriodFromDate] = useState(null);
  const [periodToDate, setPeriodToDate] = useState(null);

  // Function to disable dates before the selected 'from' date in the 'to' date picker
  const disableToDate = (current) => {
    // return current && current.isSameOrBefore(periodFromDate, 'day');
    return (
      current &&
      (current.isBefore(periodFromDate, 'day') ||
        current.isAfter(moment(), 'day'))
    );
    // return current && current.isBefore(periodFromDate, 'day');
  };
  // console.log('disableToDate', periodFromDate);
  console.log('periodToDate', periodToDate);

  // Function to disable future dates or any other logic for 'from' date picker (optional)
  // const disableFromDate = (current) => {
  //   // Example: Disable future dates, you can modify this logic as needed
  //   return current && current > moment().endOf('day');
  // };

  const isProforma = (type) => type === 'Proforma Invoice';

  return (
    <Row gutter={24}>
      {/* Rohit Code */}

      <Col xs={24} xl={6} span={6}>
        <FormItem
          label="Deal slip / Order no"
          name="dealslip_orderno"
          // rules={[{ required: true, message: 'Enter deal slip/order no' }]}
          rules={[
            { required: true, message: 'Enter deal slip/order no' }
            // { validator: validateDealslip }
          ]}
          className={styles.customLable}
        >
          <Input size="large" placeholder="Enter deal slip/order no" />
        </FormItem>
      </Col>

      <Col xs={24} xl={6} span={6}>
        <FormItem
          // label="Name of buyer"
          label={
            <Space>
              <span>Name of buyer</span>
              <Tooltip
                title="Name of Buyer should be as per the Deal Slip"
                placement="topLeft"
                // overlayClassName={styles.customTooltip}
                className={styles.customTooltip}
              >
                <InfoCircleOutlined style={{ color: 'red' }} />
              </Tooltip>
            </Space>
          }
          // name="name_of_buyer"
          name="buyer_id"
          rules={[{ required: true, message: 'Enter the buyer name' }]}
          className={styles.customLable}
        >
          {/* <Input size="large" placeholder="Enter the buyer name" /> */}
          <Select
            value={buyername}
            // onChange={(e) => handleChangeVendor(e)}
            // placeholder="Select buyer name"
            // style={{ width: '100%' }}
            onChange={(e) => handleCompany(e)}
            placeholder="Select buyer name"
            showSearch
            optionFilterProp="children"
            // onChange={onChangeBatch}
            onSearch={handleSearch}
            className={styles.companytype}
          >
            {/* <option>Select buyer name</option> */}
            {buyerslist?.map((item, index) => {
              return (
                <option value={item?.id} key={index}>
                  {item?.buyer_name} - ({item?.buyer_email})
                </option>
              );
            })}
          </Select>
        </FormItem>
      </Col>

      <Col xs={24} xl={6} span={6}>
        <FormItem
          label="Bill to party"
          name="bill_to_party"
          rules={[{ required: true, message: 'Enter bill to party' }]}
          className={styles.customLable}
        >
          <Input size="large" placeholder="Enter bill to party" />
        </FormItem>
      </Col>

      <Col xs={24} xl={6} span={6}>
        <FormItem
          label="Work area"
          name="work_area"
          rules={[{ required: true, message: 'Enter work area' }]}
          className={styles.customLable}
        >
          <Input size="large" placeholder="Enter work area" />
        </FormItem>
      </Col>

      <Col xs={24} xl={8} span={8}>
        <FormItem
          label="Vendor name"
          name="vendor_name"
          rules={[{ required: true, message: 'Select vendor name' }]}
          className={styles.customLable}
        >
          {user?.user_role_des == 'VENDOR' ? (
            <Input size="large" placeholder="Select nendor name" disabled />
          ) : (
            <Select
              onChange={(e) => handleChangeVendor(e)}
              disabled={user?.user_role_des == 'VENDOR'}
            >
              <option>Select vendor name</option>
              {vendorslist?.map((item, index) => {
                return (
                  <option value={item?.id} key={index}>
                    {item?.vendor_name}
                  </option>
                );
              })}
            </Select>
          )}
        </FormItem>
      </Col>

      <Col xs={24} xl={8} span={8}>
        <FormItem
          label="Vendor code"
          // name="vendor_code"
          rules={[{ required: true, message: 'Select vendor code' }]}
          className={styles.customLable}
        >
          <Input
            size="large"
            placeholder="Select vendor code"
            disabled
            value={vendorData?.vendor_code || invoicesData.vendor_code}
          />
        </FormItem>
      </Col>
      <Col xs={24} xl={8} span={6}>
        <FormItem
          label="Currency"
          name="currency"
          rules={[
            {
              required: true,
              message: 'Select currency'
            }
          ]}
          className={styles.customLable}
        >
          <Select
            placeholder="Select currency"
            style={{ width: '100%' }}
            onChange={(e) => handleCurrency(e)}
          >
            {currencies?.length
              ? currencies?.map((currency, index) => {
                  return (
                    <option value={currency?.id} key={index}>
                      {currency?.currency_code}
                    </option>
                  );
                })
              : ''}
          </Select>
        </FormItem>
      </Col>

      <Col xs={24} xl={6} span={6}>
        <FormItem
          // label="Invoice no"
          label={
            <Space>
              <span>Invoice no</span>
              <Tooltip
                title="Allow only / and - as special characters"
                placement="topLeft"
                // overlayClassName={styles.customTooltip}
                className={styles.customTooltip}
              >
                <InfoCircleOutlined style={{ color: 'red' }} />
              </Tooltip>
            </Space>
          }
          name="invoice_no"
          rules={[
            { required: true, message: 'Enter invoice no' },
            { validator: validateInvoiceNo }
          ]}
          className={styles.customLable}
        >
          <Input size="large" placeholder="Enter invoice no" maxLength={16} />
        </FormItem>
      </Col>

      <Col xs={24} xl={6} span={6}>
        <FormItem
          label="Invoice date"
          name="invoice_date"
          rules={[
            {
              required: true,
              message: 'Select invoice date'
            }
          ]}
          className={styles.customLable}
        >
          <DatePicker
            placeholder="Select invoice date"
            style={{ width: '100%' }}
            disabledDate={(current) => {
              return (
                // moment().add(-60, 'days') >= current ||
                // moment().add(0, 'month') <= current
                moment().add(0, 'month') <= current ||
                (current && current <= moment('2024-04-01'))
              );
            }}
          />
        </FormItem>
      </Col>

      <Col xs={24} xl={6} span={6}>
        <FormItem
          label="Invoice basic value"
          name="invoice_basic_value"
          rules={[
            {
              required: true,
              message: 'Enter invoice basic value'
            },
            {
              validator: validateAmount // Custom validator for numbers only
            }
          ]}
          className={styles.customLable}
        >
          <InputNumber
            size="large"
            placeholder="Enter invoice basic value"
            min="0"
            style={{ width: '100%' }}
            onChange={(e) => handleTotalInvoice(e, 'basicInvoice')}
            // parser={(value) => value.replace(/[^\d]/g, '')} // Ensure non-numeric characters are stripped out
          />
        </FormItem>
      </Col>

      {currency === 'INR' ? (
        <>
          <Col xs={24} xl={6} span={6}>
            <FormItem
              label="GST no"
              name="gst_no"
              rules={[
                { required: false, message: 'Enter GST no' },
                { validator: validateGSTNo }
              ]}
              className={styles.customLable}
            >
              <Input size="large" placeholder="Enter GST no" maxLength={15} />
            </FormItem>
          </Col>
          <Col xs={24} xl={6} span={6}>
            <FormItem
              label="GST type"
              name="gst_type"
              rules={[
                {
                  required: false,
                  message: 'Select GST type'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select GST type"
                style={{ width: '100%' }}
                onChange={(e) => handleGst(e)}
              >
                {gstTypes?.length
                  ? gstTypes?.map((gstType, index) => {
                      return (
                        <option value={gstType?.id} key={index}>
                          {gstType?.gst_type_code}
                        </option>
                      );
                    })
                  : ''}
              </Select>
            </FormItem>
          </Col>
          {gstData === 'CGST/SGST' ? (
            <>
              <Col xs={24} xl={6} span={6}>
                <FormItem
                  label="CGST"
                  name="cgst"
                  rules={[
                    { required: true, message: 'Enter CGST' },
                    {
                      validator: validateAmount // Custom validator for numbers only
                    }
                  ]}
                  className={styles.customLable}
                >
                  <InputNumber
                    size="large"
                    placeholder="Enter CGST"
                    min="0"
                    style={{ width: '100%' }}
                    onChange={(e) => handleTotalInvoice(e, 'cgst')}
                  />
                </FormItem>
              </Col>

              <Col xs={24} xl={6} span={6}>
                <FormItem
                  label="SGST"
                  name="sgst"
                  rules={[
                    { required: true, message: 'Enter SGST' },
                    {
                      validator: validateAmount // Custom validator for numbers only
                    }
                  ]}
                  className={styles.customLable}
                >
                  {/* InputNumber */}
                  <InputNumber
                    size="large"
                    placeholder="Enter SGST"
                    min="0"
                    style={{ width: '100%' }}
                    onChange={(e) => handleTotalInvoice(e, 'sgst')}
                  />
                </FormItem>
              </Col>
            </>
          ) : (
            ''
          )}
          {gstData === 'IGST' ? (
            <Col xs={24} xl={6} span={6}>
              <FormItem
                label="IGST"
                name="igst"
                rules={[
                  { required: true, message: 'Enter IGST' },
                  {
                    validator: validateAmount // Custom validator for numbers only
                  }
                ]}
                className={styles.customLable}
              >
                <InputNumber
                  size="large"
                  placeholder="Enter IGST"
                  min="0"
                  style={{ width: '100%' }}
                  onChange={(e) => handleTotalInvoice(e, 'igst')}
                />
              </FormItem>
            </Col>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <Col xs={24} xl={6} span={6}>
        <FormItem
          label="Total Invoice amount"
          // name="total_invoice_amount"
          rules={[{ required: true, message: 'Total invoice amount' }]}
          className={styles.customLable}
        >
          <Input
            size="large"
            disabled
            placeholder="Total invoice amount"
            value={
              currency !== 'INR'
                ? (basicInvoice > 0
                    ? parseFloat(basicInvoice)
                    : 0
                  ).toLocaleString(currency === 'EUR' ? 'de-DE' : 'en', {
                    style: 'currency',
                    currency: currency
                  })
                : gstData === 'CGST/SGST'
                ? (
                    (cgstData > 0 ? parseFloat(cgstData) : 0) +
                    (sgstData > 0 ? parseFloat(sgstData) : 0) +
                    (basicInvoice > 0 ? parseFloat(basicInvoice) : 0)
                  ).toLocaleString(currency === 'EUR' ? 'de-DE' : 'en', {
                    style: 'currency',
                    currency: currency
                  })
                : gstData === 'IGST'
                ? (
                    (igstData > 0 ? parseFloat(igstData) : 0) +
                    (basicInvoice > 0 ? parseFloat(basicInvoice) : 0)
                  ).toLocaleString(currency === 'EUR' ? 'de-DE' : 'en', {
                    style: 'currency',
                    currency: currency
                  })
                : basicInvoice
                ? basicInvoice
                : 0
            }
          />
        </FormItem>
      </Col>

      {/* <Col xs={24} xl={8} span={12}>
        <FormItem
          label="Period of rendered service"
          name="period_from_date"
          rules={[
            {
              required: true,
              message: 'Choose Yes/No'
            }
          ]}
          className={styles.customLable}
        >
          <DatePicker
            placeholder="Select from Date"
            style={{ width: '100%' }}
          />
        </FormItem>
      </Col>

      <Col xs={24} xl={6} span={6}>
        <FormItem
          label=""
          // "Period of rendered service to Date"
          name="period_to_date"
          rules={[
            {
              required: true,
              message: 'Choose Yes/No'
            }
          ]}
          className={styles.customLable}
        >
          <DatePicker placeholder="Select to Date" style={{ width: '100%' }} />
        </FormItem>
      </Col> */}

      {invoicesData.invoice_category_code === 'SRVC' ||
      localStorage.getItem('category') === 'SRVC' ? (
        <>
          <Col xs={24} xl={10} span={12}>
            <FormItem
              // label="Period of rendered service"
              label={
                <span>
                  Period of rendered service
                  {/* <span style={{ color: 'red' }}>*</span> */}
                  {(!isProforma(invoicesData?.invoice_type_des) ||
                    !isProforma(invoiceTypeCode?.invoice_type_des)) &&
                  !isProforma(invoiceTypeCode?.invoice_type_des) ? (
                    <span className={styles.period}>* </span>
                  ) : (
                    ''
                  )}
                </span>
              }
              name="period"
              className="customLabel"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem
                    name="period_from_date"
                    rules={[
                      {
                        // required: true,
                        required:
                          (!isProforma(invoicesData?.invoice_type_des) ||
                            !isProforma(invoiceTypeCode?.invoice_type_des)) &&
                          !isProforma(invoiceTypeCode?.invoice_type_des),
                        message: 'Select from date'
                      }
                    ]}
                    noStyle
                  >
                    <DatePicker
                      placeholder="Select from date"
                      style={{ width: '100%' }}
                      onChange={(date) => {
                        setPeriodFromDate(date); // Set 'from' date
                        setPeriodToDate(null); // Reset 'to' date
                      }}
                      // disabledDate={disableFromDate}
                      disabledDate={(current) => {
                        return (
                          moment().add(0, 'month') <= current
                          // (current && current <= moment('2024-04-01'))
                        );
                      }}
                      // disabledDate={(current) => {
                      //   return (
                      //     moment().add(-60, 'days') >= current ||
                      //     moment().add(0, 'month') <= current
                      //   );
                      // }}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    name="period_to_date"
                    rules={[
                      {
                        // required: true,
                        required:
                          (!isProforma(invoicesData?.invoice_type_des) ||
                            !isProforma(invoiceTypeCode?.invoice_type_des)) &&
                          !isProforma(invoiceTypeCode?.invoice_type_des),
                        message: 'Select to date'
                      }
                    ]}
                    noStyle
                  >
                    <DatePicker
                      placeholder="Select to date"
                      style={{ width: '100%' }}
                      onChange={(date) => setPeriodToDate(date)} // Set 'to' date
                      disabledDate={disableToDate} // Disable dates before 'from' date
                      // disabledDate={(current) => {
                      //   return (
                      //     moment().add(-60, 'days') >= current ||
                      //     moment().add(0, 'month') <= current
                      //   );
                      // }}
                    />
                  </FormItem>
                </Col>
              </Row>
            </FormItem>
          </Col>
        </>
      ) : (
        ''
      )}

      <Col xs={24} xl={24} span={24}>
        <FormItem
          label="Job/Material description (as per deal slip)"
          name="job_materila_Description"
          rules={[
            {
              required: true,
              message: 'Enter job/material description(as per deal slip)'
            }
          ]}
          className={styles.customLable}
        >
          <TextArea
            placeholder="Enter job/material description(as per deal slip)"
            style={{ width: '100%' }}
          />
        </FormItem>
      </Col>
    </Row>
    // </Form>
  );
};
export default invoice;
