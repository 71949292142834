import React from 'react';
import { Card, Row, Divider, Tooltip, Col } from 'antd';
import styles from './index.module.less';
import { FileTextOutlined, DownloadOutlined } from '@ant-design/icons';

// import { Buffer } from 'buffer';
// Spin

// const index = () => {
const index = ({ documents }) => {
  // const [form] = Form.useForm();
  // const [exports, setExports] = useState(false);

  // const { Meta } = Card;
  //   const handleDownload = (url) => {
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', ''); // Optional: You can specify a file name here
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   };

  // const [documents, setDocuments] = useState([]);
  // const [loading, setLoading] = useState(true);
  console.log('formslist', documents);
  //  setDocuments(formslist);
  const documentsArray = Array.isArray(documents) ? documents : [];

  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', ''); // You can set a specific file name if you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // if (loading) {
  //   return <Spin size="large" />;
  // }

  return (
    <Card className={styles.cardbody}>
      {/* <Meta title="#Reports" className={styles.metaData} /> */}
      <h1 className={styles.title}>Forms</h1>
      <Divider className="divider" />
      <div className={styles.maincard}>
        <div className={styles.nestedCard1}>
          {/* <div className="document-cards-container">
      <Row gutter={[16, 16]}>
        {documentsArray.map((doc, index) => (
          <Col xs={24} sm={12} md={12} lg={8} xl={6} key={index}>
            <Card className="document-card">
              <div className="document-content">
                <FileTextOutlined className="document-icon" />
                <span className="document-title">{doc.form_title}</span>
                <Tooltip title="Download">
                  <DownloadOutlined className="download-icon" onClick={() => handleDownload(doc.form_url)} />
                </Tooltip>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div> */}

          <div className="documentcardscontainer">
            <Row gutter={[24, 24]}>
              {documentsArray.map((doc, index) => (
                <Col xs={24} sm={12} md={12} lg={8} xl={8} key={index}>
                  <Card className={styles.documentcard}>
                    <div className={styles.documentcontent}>
                      <FileTextOutlined className={styles.fileicon} />
                      <span className={styles.documenttitle}>
                        {doc.form_title}
                      </span>
                      <Tooltip title="Download">
                        <DownloadOutlined
                          className={styles.downloadicon}
                          onClick={() => handleDownload(doc.form_url)}
                        />
                      </Tooltip>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default index;
