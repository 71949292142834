import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Spin, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import styles from './index.module.less';
import Layout from '@/common/Layout';
// import MessageIcon from '../images/shape.svg';
import { ProgressBar, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import pdffile from './JavaScript-Handwritten-Notes.pdf';
import { getInvoiceDataById } from '../Invoice/redux/slice';

// @/src/pages/invoice/redux/slice
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '@/app/history';

const FinalReviewPage = ({ allFileLoading }) => {
  const { invoicesData, attachmentData } = useSelector(
    (state) => state.invoices
  );
  console.log('invoicesData', invoicesData?.generated_inv_url);

  console.log('raattachment_details', attachmentData);
  let specificAttachment = null;
  if (attachmentData && attachmentData.length > 0) {
    specificAttachment = attachmentData.find(
      (attachment) => attachment.attachment_type_code === 'RA'
    );

    if (specificAttachment) {
      console.log('specificAttachmentv', specificAttachment.file_url);
      // Use specificAttachment.file_name as needed
    } else {
      console.log('No attachment found with type code RA');
    }
  } else {
    console.log('No attachments available');
  }
  // if(attachments?.attachment_type_code === 'RA'){
  // console.log('attachments', attachments?.file_url);
  // }

  const pdf = invoicesData?.generated_inv_url;
  const RAAbstract = specificAttachment?.file_url;
  console.log('RAAbstract', RAAbstract);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const params = useParams();
  const dispatch = useDispatch();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const pdfVersion = '2.10.377';
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleWindowResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    getInvoicedataById();
  }, []);

  const getInvoicedataById = () => {
    dispatch(getInvoiceDataById(params.id));
  };

  const handleDownload = () => {
    if (pdf) {
      const link = document.createElement('a');
      link.href = pdf;
      link.setAttribute('download', 'file.pdf'); // Set the desired file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('PDF URL is undefined');
    }
  };

  const handleRADownload = () => {
    if (RAAbstract) {
      const link = document.createElement('a');
      link.href = RAAbstract;
      link.setAttribute('download', 'file.xlsx'); // Set the desired file name here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('PDF URL is undefined');
    }
  };

  const handleCancel = () => {
    history.push('/dashboard');
  };

  const Header = () => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <Col className={`text-right `} xs={24} xl={8} span={9}>
              <Space>
                <CustomButton
                  className={`mr-2`}
                  type="primary"
                  ghost
                  onClick={handleCancel}
                >
                  {' '}
                  Cancel
                </CustomButton>
                <CustomButton
                  className={`mr-2`}
                  type="primary"
                  onClick={handleDownload}
                >
                  {/* {' '} */}
                  Download Invoice
                </CustomButton>
                <CustomButton
                  className={`mr-2`}
                  type="primary"
                  onClick={handleRADownload}
                >
                  {/* {' '} */}
                  Download RA Attachment
                </CustomButton>
                {/* </a> */}
              </Space>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return (
    <Layout sider={true}>
      {allFileLoading ? (
        <div style={{ flexDirection: 'column' }} className="df-jc-ac">
          <Spin />
        </div>
      ) : (
        <>
          {' '}
          <Header />
          <div className={styles.pdfFile}>
            <Row>
              <Col xs={24} xl={4} span={16}></Col>
              <Col className={` mr-4 text-left `} xs={24} xl={16} span={16}>
                <Card
                  hoverable
                  className={` text-center ${styles.image_viewer_card}`}
                  // title={
                  //   <div className="df-jc-ac sfprotext-bold ">
                  //     Preview your document below
                  //   </div>
                  // }
                  bordered={false}
                >
                  <Worker
                    workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfVersion}/pdf.worker.js`}
                  >
                    <div
                      style={{
                        border: '1px solid #eaeaea',
                        height: '750px',
                        width: 'auto'
                      }}
                    >
                      {pdf ? (
                        <Viewer
                          renderLoader={(percentages) => (
                            <div style={{ width: '240px' }}>
                              <ProgressBar progress={Math.round(percentages)} />
                            </div>
                          )}
                          defaultScale={windowWidth < 768 ? 0.4 : 1.2}
                          plugins={[defaultLayoutPluginInstance]}
                          fileUrl={`${pdf}`}
                        />
                      ) : (
                        <Spin
                          tip="Loading"
                          size="large"
                          className={styles.pdf_loader}
                        ></Spin>
                      )}
                    </div>
                  </Worker>
                </Card>
              </Col>
              <Col xs={24} xl={4} span={16}></Col>
            </Row>
            <br />
          </div>
        </>
      )}
    </Layout>
  );
};

export default FinalReviewPage;
