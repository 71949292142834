import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  userDashboard,
  categories,
  exportEntity,
  snsharedList,
  snsharedUpload,
  FCAList,
  FCAUploadList,
  FCAUsersList
} from './api';
// ./api
const initialState = {
  loading: false,
  allOnBoardingList: [],
  pagination: {},
  categories: [],
  statuses: [],
  percentCompleted: 0,
  FCAUsersList: []
};

const actions = {
  VENDORDASHBOARD: 'vendorDashboard/VENDORDASHBOARD',
  GETINVOICECATEGORY: 'getInvoiceCategory/GETINVOICECATEGORY',
  GETINVOICESTATUSES: 'getInvoiceStatuses/GETINVOICESTATUSES',
  DOWNLOADENTITY: 'downloadEntity/DOWNLOADENTITY',
  ALLSNSHAREDLIST: 'allSNSharedList/ALLSNSHAREDLIST',
  UPLOADSNSHARED: 'uploadSNShared/UPLOADSNSHARED',
  ALLFCALIST: 'allFCAList/ALLFCALIST',
  UPLOADFCALIST: 'uploadFCAList/UPLOADFCALIST',
  FCAUSERSLIST: 'fcaUsersList/FCAUSERSLIST',
  DOWNLOADSNENTITY: 'downloadSNEntity/DOWNLOADSNENTITY'
};

export const vendorDashboard = createAsyncThunk(
  actions.VENDORDASHBOARD,
  async (payload) => {
    const response = await userDashboard(payload);
    return response;
  }
);

export const getInvoiceCategory = createAsyncThunk(
  actions.GETINVOICECATEGORY,
  async (payload) => {
    const response = await categories('invoice-category', payload);
    return response;
  }
);

export const getInvoiceStatuses = createAsyncThunk(
  actions.GETINVOICESTATUSES,
  async (payload) => {
    const response = await categories('invoice-status', payload);
    return response;
  }
);

export const downloadEntity = createAsyncThunk(
  actions.DOWNLOADENTITY,
  async (payload) => {
    // const response = await exportEntity('export-invoices', payload);
    const response = await exportEntity(
      'export-sn-entity-shared-invoices',
      payload
    );
    return response;
  }
);
// downloadSNEntity
export const downloadSNEntity = createAsyncThunk(
  actions.DOWNLOADSNENTITY,
  async (payload) => {
    // const response = await exportEntity('export-invoices', payload);
    const response = await exportEntity(
      'share-downloaded-sn-entity-checked-invoices',
      payload
    );
    return response;
  }
);

// allSNSharedList
export const allSNSharedList = createAsyncThunk(
  actions.ALLSNSHAREDLIST,
  async (payload) => {
    const response = await snsharedList('export-sn-shared-invoices', payload);
    return response;
  }
);

// Upload SN Shared excel
export const uploadSNShared = createAsyncThunk(
  actions.UPLOADSNSHARED,
  async (payload) => {
    const response = await snsharedUpload('import-sn-shared-invoices', payload);
    return response;
  }
);

// All FCA List
export const allFCAList = createAsyncThunk(
  actions.ALLFCALIST,
  async (payload) => {
    const response = await FCAList('export-fca-approved-invoices', payload);
    return response;
  }
);
// Upload FC&A Invoices
export const uploadFCAList = createAsyncThunk(
  actions.UPLOADFCALIST,
  async (payload) => {
    const response = await FCAUploadList(
      'import-fca-approved-invoices',
      payload
    );
    return response;
  }
);
// FC&A Users List
export const fcaUsersList = createAsyncThunk(
  actions.FCAUSERSLIST,
  async (payload) => {
    const response = await FCAUsersList('fca_persons', payload);
    return response;
  }
);
export const accessVendor = createSlice({
  name: 'vendorDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(vendorDashboard.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(vendorDashboard.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.allOnBoardingList = data.items ? data.items : [];
        state.pagination = data.meta || null;
        state.acceptedLoading = false;

        // console.log('slicelog', data);
      })
      .addCase(vendorDashboard.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getInvoiceCategory.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getInvoiceCategory.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.categories = data ? data : [];

        // console.log('slicelog', data);
      })
      .addCase(getInvoiceCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getInvoiceStatuses.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getInvoiceStatuses.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.statuses = data ? data : [];

        // console.log('slicelog', data);
      })
      .addCase(getInvoiceStatuses.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
    builder
      .addCase(downloadEntity.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(downloadEntity.fulfilled, (state, action) => {
        const { message: msg, success } = action.payload;
        // const { message: msg } = action.error;
        // message.success(msg);
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
        // console.log('slicelog', data);
      })
      .addCase(downloadEntity.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(downloadSNEntity.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(downloadSNEntity.fulfilled, (state, action) => {
        const { message: msg, success } = action.payload;
        // const { message: msg } = action.error;
        // message.success(msg);
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
        console.log('slicelog', msg);
      })
      .addCase(downloadSNEntity.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(allFCAList.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(allFCAList.fulfilled, (state, action) => {
        const { message: msg } = action.payload;
        // const { message: msg } = action.error;
        message.success(msg);
        // console.log('slicelog', data);
      })
      .addCase(allFCAList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(allSNSharedList.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(allSNSharedList.fulfilled, (state, action) => {
        const { message: msg } = action.payload;
        // const { message: msg } = action.error;
        message.success(msg);
        // console.log('slicelog', data);
      })
      .addCase(allSNSharedList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(uploadFCAList.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(uploadFCAList.fulfilled, (state, action) => {
        const { message: msg } = action.payload;
        // const { message: msg } = action.error;
        // message.success(msg);
        console.log('slicelog', msg);
      })
      .addCase(uploadFCAList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(fcaUsersList.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(fcaUsersList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.FCAUsersList = data;
        state.acceptedLoading = false;
        // const { message: msg } = action.payload;
        // state.acceptedLoading = true;
        // const { message: msg } = action.error;
        // message.success(msg);
        // console.log('slicelog', data);
      })
      .addCase(fcaUsersList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
  }
});

export default accessVendor.reducer;
