import React from 'react';
import Layout from '@/common/Layout';
import Reports from '@/pages/Report/components';

const index = () => {
  return (
    <>
      <Layout sider={true}>
        <Reports />
        {/* Reports Page testing */}
      </Layout>
    </>
  );
};

export default index;
