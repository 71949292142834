// src/VideoPlayer.js

import React, { useState } from 'react';
import { Card } from 'antd';
import ReactPlayer from 'react-player';
import styles from './index.module.less';
import TrainingImg from '../images/trainingVideo.png';
// import Playimg from '../images/playcircle.svg'

const VideoPlayer = ({ url }) => {
  const [playing, setPlaying] = useState(false);
  // const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setPlaying(!playing);
  };

  return (
    <Card className={styles.cardbody}>
      <h1 className={styles.title}>Training Video</h1>
      <div className={styles.container}>
        <img src={TrainingImg} />
        {playing ? (
          <ReactPlayer
            url={url}
            playing={playing}
            controls
            width="100%"
            height="100%"
            // display="flow-root"
            className={styles.video}
          />
        ) : (
          ''
          // <div>Loading video...</div>
        )}
        <div className={styles.playbutton} onClick={togglePlayPause}>
          {playing ? '' : '▶️'}
          {/* ⏸️ */}
        </div>
      </div>
    </Card>
  );
};

export default VideoPlayer;
