import React, { useState } from 'react';
import {
  Card,
  Row,
  Divider,
  Input,
  Col,
  // DatePicker,
  Form,
  Radio,
  Typography,
  Tag,
  Timeline,
  Skeleton
  // Tooltip,
  // Empty,
  // Select
  // message
  // Spin
} from 'antd';
import styles from './index.module.less';
import FormItem from '@/common/FormItem';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import CustomButton from '@/common/CustomButton';
import CustomTable from '@/common/Content/CustomTable';
import CustomModal from '@/common/CustomModal';
import { HistoryOutlined } from '@ant-design/icons';
const { Meta } = Card;
const { Text } = Typography;
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getAuditTrails } from '../../Validation/redux/slice';
// import { getLoggedInUser } from 'utilities/helpers';
// import CustomTable from '@/common/Content/CustomTable';
// import { useSelector } from 'react-redux';
import { getfetchinvoiceData, getundoinvoiceData } from '../redux/slice';

const index = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [isShow, setIsShow] = useState(true);
  const [isView, setIsView] = useState(false);
  const [invoicesData, setInvoiceData] = useState();
  const [performaInvoices, setPerformaInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState();
  const [auditListData, setAuditListData] = useState([]);
  const [auditData, setAuditData] = useState({});
  // const { IVTUsersList } = useSelector((state) => state.companydetails);
  // console.log('IVTUsersList', IVTUsersList);

  // console.log('selectedRadio', selectedRadio);
  // console.log('setIsView', setIsView);

  const onFinish = (values) => {
    setLoading(true);
    // setResult(values);
    const { category, request_no, scroll_no } = values || {};
    // console.log('values', values);
    // const invoice_id = id;

    let payload = {
      category: category ? category : '',
      request_no: request_no ? request_no : '',
      scroll_no: scroll_no ? scroll_no : ''
    };
    console.log('payload', payload);
    onSubmit(payload);
  };

  const onSubmit = (payload) => {
    // dispatch(getfetchinvoiceData(payload));
    dispatch(getfetchinvoiceData(payload))
      .then((response) => {
        console.log('response.success', response.payload.data.invoiceDetails);

        if (response && response.payload.success === true) {
          // Assuming `response.success` indicates success
          setLoading(false);
          setIsView(true);
          setInvoiceData(response.payload.data.invoiceDetails);
          setPerformaInvoices(response.payload.data.proformaInvoices);
          // message.success('Data fetched successfully!');
        } else {
          setIsView(false);
          setLoading(false);
          // Handle any specific failure case
          // message.error('Failed to fetch data.');
        }
      })
      .catch(() => {
        setIsView(false);
        setLoading(false);
        // message.error('Error fetching data.');
      });
  };
  // const handleProject = () => {
  //   // setProjectId(e);
  // };
  // const handleSearch = (e) => {
  //   setSearch(e);
  // };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedRadio(value);
  };
  console.log('setIsModal', setIsModal);

  const getAuditDetails = (id) => {
    setAuditData([]);
    setIsModal(true);
    dispatch(getAuditTrails(id)).then((res) => {
      if (res?.payload?.success) {
        setAuditData(res?.payload?.data);
        // setIsModal(true);
      }
    });
  };

  const columns = [
    {
      title: 'Deal Slip No.',
      dataIndex: 'deal_slip',
      key: 'deal_slip'
    },
    {
      title: 'Scroll No.',
      dataIndex: 'scroll_no',
      key: 'scroll_no'
    },
    {
      title: 'Proforma Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no'
    },
    {
      title: 'Proforma Invoice Amount',
      dataIndex: 'invoice_amount',
      key: 'invoice_amount'
    },
    {
      title: 'Proforma Invoice Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      render: (text) => {
        return moment(text).format('DD-MM-YYYY');
      }
    },
    {
      title: 'Proforma Adjusted Amount',
      dataIndex: 'adjustment_amount',
      key: 'adjustment_amount'
    },
    {
      title: 'Proforma Remaining Amount',
      dataIndex: 'total_invoice_amount',
      key: 'total_invoice_amount'
    },
    {
      title: 'Vendor Name & Code',
      dataIndex: 'vendorName',
      key: 'vendorName',
      render: (text, record) => {
        return (
          <span>
            {record.vendor_name} ({record.vendor_code})
          </span>
        );
      }
    },
    {
      title: 'Project name',
      dataIndex: 'project_name',
      key: 'project_name'
    },
    {
      title: '',
      dataIndex: 'audit_trail',
      key: 'audit_trail',
      render: (text, record) => {
        return record?.has_audit_trail ? (
          !isModal ? (
            <Tag
              color="default"
              size="small"
              className={styles.tag}
              onClick={() => handleAudit(record?.id)}
              style={{ cursor: 'pointer' }}
            >
              Audit Trail
            </Tag>
          ) : (
            ''
          )
        ) : (
          ''
        );
      }
    }
  ];

  const handleAudit = (id) => {
    // setBtnLoading(true);

    const audit = performaInvoices.filter((item) => item.id === id);
    setAuditListData(audit);
    getAuditDetails(id);
  };

  const cancelscreenadjustadvance = () => {
    form.resetFields();
    setIsView(false);
    setSelectedRadio('null');
    setLoading(false);
    history.push('/revertadvance');
  };

  const adjustadvance = () => {
    let payload = {
      scroll_no: invoicesData?.scroll_no ? invoicesData?.scroll_no : '',
      request_no: invoicesData?.request_no ? invoicesData?.request_no : '',
      validation_performed_dt: invoicesData?.updated_at
        ? invoicesData?.updated_at
        : ''
    };

    console.log('payload_adjustadvance', payload);
    dispatch(getundoinvoiceData(payload))
      .then((response) => {
        console.log('response.success', response.payload.data.invoiceDetails);

        if (response && response.payload.success === true) {
          // Assuming `response.success` indicates success
          setLoading(false);
          setIsView(false);
          setSelectedRadio('null');

          // message.success('Data fetched successfully!');
        } else {
          setIsView(true);
          setLoading(false);
          // setSelectedRadio('null');
        }
      })
      .catch(() => {
        // setIsView(false);
        setLoading(false);
        // setSelectedRadio('null');
      });
  };

  return (
    <Card className={styles.cardbody}>
      {/* <Meta title="#Reports" className={styles.metaData} /> */}
      <h1 className={styles.title}>Advance Search</h1>
      <Divider className="divider" />

      <div className={styles.container}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col xs={24} xl={6} span={8}>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  { required: true, message: 'Please select a category' }
                ]}
              >
                <Radio.Group onChange={handleRadioChange}>
                  <Radio value="requestNo">Request No</Radio>
                  <Radio value="scrollNo">Scroll No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {selectedRadio === 'requestNo' && (
              <Col xs={24} xl={6} span={8}>
                <FormItem
                  label="Request No."
                  name="request_no"
                  className={styles.customLable}
                  rules={[{ required: true, message: 'Enter request no.' }]}
                >
                  <Input placeholder="Enter request no." />
                </FormItem>
              </Col>
            )}

            {selectedRadio === 'scrollNo' && (
              <Col xs={24} xl={6} span={8}>
                <FormItem
                  label="Scroll No."
                  name="scroll_no"
                  className={styles.customLable}
                  rules={[{ required: true, message: 'Enter Scroll no.' }]}
                >
                  <Input placeholder="Enter scroll no." />
                </FormItem>
              </Col>
            )}

            {/* <Col xs={24} xl={6} span={8}>
              <FormItem
                label="Last Upadation Date"
                name="startDate"
                className={styles.customLable}
                rules={[
                  { required: true, message: 'Select last updation date' }
                ]}
              >
                <DatePicker
                  placeholder="Select last updation date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col> */}

            <Form.Item className={styles.formactions}>
              <button
                type="submit"
                className={styles.applybutton}
                loading={loading}
                // onClick={() => {
                //   // setButtonClicked('apply');
                //   form.submit(); // Triggers the onFinish function
                // }}
              >
                {/* Apply filters */}
                {loading ? 'Fetching...' : 'Fetch'}
              </button>
            </Form.Item>
          </Row>
        </Form>
      </div>

      {/*  */}
      <br></br>
      {isView && (
        <div className={styles.collapse}>
          <Row gutter={24}>
            <Col xs={24} xl={12} span={8}>
              <Meta title="Invoice Details" className={styles.metaData1} />
            </Col>
            <Col xs={24} xl={12} span={8}>
              {!isShow ? (
                <DownOutlined
                  style={{ float: 'right' }}
                  onClick={() => setIsShow(true)}
                />
              ) : (
                <CloseOutlined
                  style={{ float: 'right' }}
                  onClick={() => setIsShow(false)}
                />
              )}
            </Col>
          </Row>
          {isShow ? (
            <div className={styles.nestedCard1}>
              {/* <div className={styles.tablelist}> */}
              <Row gutter={24} className={styles.rowclass}>
                <Col span={6} className={styles.colclass}>
                  <Text strong>Deal slip</Text>
                  <div>{invoicesData?.deal_slip}</div>
                </Col>
                <Col span={6} className={styles.colclass}>
                  <Text strong>Tax Invoice Number</Text>
                  <div>{invoicesData?.invoice_no}</div>
                </Col>
                <Col span={6} className={styles.colclass}>
                  <Text strong>Tax Invoice Date</Text>
                  <div>
                    {moment(invoicesData?.inv_date).format('YYYY-MM-DD')}
                  </div>
                </Col>
                <Col span={6} className={styles.colclass}>
                  <Text strong>Vendor Name & Code</Text>
                  <div>{`${invoicesData?.vendor_name} (${invoicesData?.vendor_code})`}</div>
                </Col>
              </Row>
              <Row gutter={24}>
                {/* <Col span={6} className={styles.colclass}>
                <Text strong>DC No.</Text>
                <div>23</div>
              </Col> */}
                <Col span={6} className={styles.colclass}>
                  <Text strong>Project Name</Text>
                  <div>{invoicesData?.project_name}</div>
                </Col>
                <Col span={6} className={styles.colclass}>
                  <Text strong>Invoice Amount</Text>
                  <div>{invoicesData?.total_invoice_amount}</div>
                </Col>
                <Col span={6} className={styles.colclass}>
                  <Text strong></Text>
                  <div></div>
                </Col>
              </Row>
            </div>
          ) : (
            ''
          )}
        </div>
      )}

      {isView && (
        <div className={styles.collapse1}>
          {/* <Meta title="Invoice details" className={styles.metaData2} /> */}
          <div className={styles.tableheader}>
            List of Proforma Invoices for the vendor
          </div>
          <CustomTable
            dataSource={performaInvoices}
            columns={columns}
            className={styles.tabledata}
            // rowSelection={rowSelection}
            rowKey={(record) => record.id}
            pagination={false}
          />

          {isModal ? (
            <CustomModal
              title={
                <>
                  <span>
                    <HistoryOutlined className={styles.historyOutline} />
                  </span>
                  <span className={styles.audittrail}> Audit Trail</span>
                </>
              }
              visible={isModal}
              width="100%"
              footer={null}
              className={styles.customModal}
              closable={false}
            ></CustomModal>
          ) : (
            ''
          )}
          <div className={styles.fetchbtnstyles}>
            <Form.Item className={styles.formactions}>
              <button
                type="submit"
                className={styles.CancelButton}
                loading={loading}
                // onClick={() => {
                //   // setButtonClicked('apply');
                //   form.submit(); // Triggers the onFinish function
                // }}
                onClick={cancelscreenadjustadvance}
              >
                {/* Apply filters */}
                {/* {loading ? 'Fetching...' : 'Fetch'} */}
                {loading ? 'Cancelling...' : 'Cancel'}
              </button>
            </Form.Item>

            <Form.Item className={styles.formactions}>
              <button
                type="submit"
                className={styles.submitbutton}
                loading={loading}
                // onClick={() => {
                //   // setButtonClicked('apply');
                //   form.submit(); // Triggers the onFinish function
                // }}
                onClick={adjustadvance}
              >
                {/* Apply filters */}
                {/* {loading ? 'Fetching...' : 'Fetch'} */}
                {loading ? 'Revertinging...' : 'Revert Advance'}
              </button>
            </Form.Item>
          </div>
        </div>
      )}
      {isModal ? (
        <CustomModal
          title={
            <>
              <span>
                <HistoryOutlined className={styles.historyOutline} />
              </span>
              <span className={styles.audittrail}> Audit Trail</span>
            </>
          }
          visible={isModal}
          width="100%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          {auditData?.length ? (
            <>
              <CustomTable
                dataSource={auditListData}
                columns={columns}
                className={styles.tabledata}
                // rowSelection={rowSelection}
                // rowKey={(record) => record.id}
                pagination={false}
              />
              <br />
              <Timeline>
                {auditData?.map((item, index) => (
                  <Timeline.Item key={index}>
                    {moment(item?.created_at).format('DD/MM/YYYY')},{' '}
                    {moment(item?.created_at).format('hh:mm')}{' '}
                    {`proforma invoice amount changed to ${item?.utilization_amount}`}
                  </Timeline.Item>
                ))}
              </Timeline>
              <FormItem className="text-center">
                <CustomButton
                  className={styles.inwardButton}
                  onClick={() => setIsModal(false)}
                  type="primary"
                  // size="small"
                >
                  Close
                </CustomButton>
              </FormItem>
            </>
          ) : (
            <Skeleton />
          )}
        </CustomModal>
      ) : (
        ''
      )}
    </Card>
  );
};

export default index;
