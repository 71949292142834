import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { exportreport } from './api';
// import { history } from 'app/history';

const initialState = {
  loading: false
  // user: null
};

const actions = {
  EXPORTED: 'Exported/EXPORTED'
};

export const Exported = createAsyncThunk(actions.EXPORTED, async (payload) => {
  const response = await exportreport(payload);
  if (response.success === true) {
    const messageText = response.message;
    message.success(messageText);
  } else {
    //   // Set the error message
    const errorMessage = response.message;
    message.error(errorMessage);
  }
  return response;
});

export const exportedSlice = createSlice({
  name: 'exported',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Exported.pending, (state) => {
        state.loading = true;
      })
      .addCase(Exported.fulfilled, (state, action) => {
        const { data, message: msg } = action.payload;
        state.loading = false;
        state.data = data;
        message.success(msg);
      })
      .addCase(Exported.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default exportedSlice.reducer;
