import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { signin } from './api';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';

const initialState = {
  loading: false,
  token: '',
  user: null
};

const actions = {
  LOGIN: 'login/LOGIN'
};

export const login = createAsyncThunk(actions.LOGIN, async (payload) => {
  const response = await signin(payload);

  if (response?.data?.access_token) {
    const { access_token, user } = response.data;
    let newUser = {
      access_token,
      user
    };

    LocalStorage.setItem('RIL_user', JSON.stringify(newUser));
    if (newUser.access_token) {
      // .user_role_des === 'VENDOR'
      //   const messageText = response.message;
      // message.success(messageText);
      history.push('/dashboard');
    }
  } else {
    localStorage.clear();
    logout();
    // message.success('Logged out successfully');
    // const messageText = response.message || 'Logged out successfully';
    // message.error(messageText);
    history.push('/login');
  }

  return response;
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginDetails: (state) => {
      state.token = '';
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { data, success, message: msg } = action.payload;
        state.loading = false;
        state.token = data?.access_token;
        state.user = data?.user;

        if (success) {
          message.success(msg, [1]);
        } else {
          message.error(msg);
        }
      })
      .addCase(login.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export const { resetLoginDetails } = loginSlice.actions;

export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch(resetLoginDetails());
  message.success('Thanks! You have logged out successfully');
  history.push('/login');
};

export default loginSlice.reducer;
