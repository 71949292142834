import apiClient from 'utilities/apiClient';

export const invoicetypes = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoiceMaster}/${url}`, payload, true);
};

export const submitInvoices = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.invoice}/${url}`,
    payload,
    true,
    'file'
  );
};

export const invoiceByid = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};

export const vendorByID = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};

export const allVendors = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};

export const allBuyers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};
