import React from 'react';
import { Modal } from 'antd';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '../CustomButton';

const CustomModal = ({
  children,
  className,
  cancelText = 'Cancel',
  cancelButtonProps = {},
  okText = 'Ok',
  okButtonProps = {},
  customFooter = true,
  type,
  ...rest
}) => {
  console.log(type);
  return (
    <Modal
      className={
        type === 'standard'
          ? `${styles.custom_modal_contianer1}${
              customFooter ? ` ${styles.custom_footer}` : ''
            }${className ? ` ${className}` : ''}`
          : `${styles.custom_modal_contianer}${
              customFooter ? ` ${styles.custom_footer}` : ''
            }${className ? ` ${className}` : ''}`
      }
      {...(customFooter
        ? {
            footer: [
              <CustomButton key="cancel" {...cancelButtonProps}>
                {cancelText}
              </CustomButton>,
              <CustomButton key="submit" type="primary" {...okButtonProps}>
                {okText}
              </CustomButton>
            ]
          }
        : {})}
      {...rest}
      {...(customFooter
        ? {}
        : { cancelText, cancelButtonProps, okText, okButtonProps })}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
