import React, { useEffect } from 'react';
import Layout from '@/common/Layout';
import AdvanceSearch from '../AdvanceSearch/components';
import { useDispatch, useSelector } from 'react-redux';
import { getadvancesearchData } from './redux/slice';

const index = () => {
  const dispatch = useDispatch();
  // const [page, setPage] = useState(1);
  useEffect(() => {
    advancesearchData();
  }, []);

  const advancesearchData = () => {
    const payload = {};
    dispatch(getadvancesearchData(payload));
  };
  const { allOnBoardingList, pagination } = useSelector(
    (state) => state.SearchData
  );
  // const { invoicetypes } = useSelector((state) => state.invoices);
  // console.log('invoicetypes', invoicetypes);

  return (
    <>
      <Layout sider={true}>
        <AdvanceSearch
          allOnBoardingList={allOnBoardingList}
          pagination={pagination}
        />
        {/* Testing Advance Search page */}
      </Layout>
    </>
  );
};

export default index;
