import React, { useEffect } from 'react';
import { Card, Col, Divider, Form, Row, Empty, Tooltip } from 'antd';
import styles from './index.module.less';
import CustomTable from '@/common/Content/CustomTable';
// import { useDispatch } from 'react-redux';
import { getNoticeBoard } from '../redux/slice';
import moment from 'moment';
// import { getLoggedInUser } from 'utilities/helpers';
// import { Pagination } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

const { Meta } = Card;

const DashBoardPage = () => {
  //   const { allOnBoardingList, pagination, categories, statuses } = useSelector(
  //     (state) => state.vendorDashboard
  //   );

  // const { user } = getLoggedInUser();
  // const [page, setPage] = useState(1);
  // const [radioFilterName, setRadioFilterName] = useState('');
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  useEffect(() => {
    // getVendorData();
    dispatch(getNoticeBoard());
  }, []);
  const { data } = useSelector((state) => state.NotiveBoard);
  // console.log('itemstesting111', invoicedata);
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no Notice to display
        </div>
      </span>
    )
  };
  console.log('data', data.items);

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'serial_no',
      key: 'serial_no'
    },
    {
      title: 'Notice.',
      dataIndex: 'notice_desc',
      key: 'notice_desc'
      //   width: 150
    },
    {
      title: 'Date',
      dataIndex: 'notice_date',
      key: 'notice_date',
      sorter: (a, b) =>
        moment(a[`notice_date`]).unix() - moment(b[`notice_date`]).unix(),
      render: (notice_date) => (
        <>
          <Tooltip
            title={
              notice_date === null
                ? 'Not generated'
                : moment(notice_date).format('DD MM YYYY')
            }
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
          >
            {notice_date === null
              ? 'Not generated'
              : moment(notice_date).format('DD MM YYYY')}
          </Tooltip>
        </>
      )
    },
    {
      title: 'View',
      dataIndex: 'notice_url',
      key: 'View',
      width: 80,
      className: styles.highlightedColumn,
      render: (notice_url) => (
        <Tooltip
          title={notice_url === null ? 'Not Generated' : 'View Notice'}
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter={false}
        >
          {notice_url !== null ? (
            <a href={notice_url} target="_blank" rel="noopener noreferrer">
              <DownloadOutlined className="view-icon" />
            </a>
          ) : (
            <DownloadOutlined className="view-icon disabled" />
          )}
        </Tooltip>
      )
    }
  ];

  //   const handleOnChangePdfUpload = async (e) => {
  //     const formData = new FormData();
  //     setAllUplods(true);
  //     formData.append('file', e.file.originFileObj);
  //     dispatch(uploadSNShared(formData)).then((response) => {
  //       if (response?.payload?.success) {
  //         setAllUplods(false);
  //         setRadioFilterName('All');
  //         getVendorData();
  //         message.success(response?.payload?.message);
  //       } else {
  //         setAllUplods(false);
  //         message.error(response?.payload?.message);
  //       }
  //     });
  //   };

  return (
    <>
      <Form form={form} layout="vertical">
        <Card className={styles.cardbody}>
          <Row gutter={24}>
            <Col span={6} xl={10} xs={24}>
              <Meta
                title=" Notice Board"
                // description="A complete repository that records the details and progress of all invoices"
              />
            </Col>
          </Row>
          <Divider />

          <div className={`${styles.table_container}`}>
            <CustomTable
              locale={locale}
              // loading={getAllMaterilLoading}
              columns={columns}
              // dataSource={allOnBoardingList}
              dataSource={data.items}
              pagination={false}
              className={styles.pagination_style}
              scroll={{ x: 100 }}
            />
            {/* {data.items.length ? (
              <div className={styles.pagination}>
                <span
                  className={page === 1 ? styles.prevDisabled : styles.prev}
                  onClick={() => setPage(page - 1)}
                >
                  <img className={styles.prev} src={prev} /> Previous
                </span>
                <Pagination
                  className={styles.pagination_style}
                  current={page}
                  total={pagination?.totalItems || 0}
                  // itemRender={itemRender}
                  onChange={(page) => setPage(page)}
                  showSizeChanger={false}
                  scroll={{ x: 'auto' }}
                />
                <span
                  span={8}
                  className={
                    page === pagination?.totalPages
                      ? styles.nextdisabled
                      : styles.next
                  }
                  onClick={() => setPage(page + 1)}
                >
                  Next
                  <img className={styles.prev} src={next} />
                </span>
              </div>
            ) : (
              ''
            )} */}
          </div>
        </Card>
      </Form>
    </>
  );
};
// };
export default DashBoardPage;
