import React, { useState } from 'react';
import {
  Col,
  Row,
  // Tooltip,
  Typography,
  Card,
  Spin,
  message,
  Space,
  Popover,
  Modal,
  Form,
  Checkbox
} from 'antd';
import FormItem from 'common/FormItem';
import styles from './index.module.less';
import CustomButton from '@/common/CustomButton';
// import symbol from './images/info-hexagon.png';
import moment from 'moment';
import { submitInvoice } from '../../redux/slice';
import { useDispatch } from 'react-redux';
import { FileOutlined } from '@ant-design/icons';
import { history } from '@/app/history';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CheckCircleTwoTone, CloseOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;
function index({
  invoiceValues,
  invoiceId,
  invoicetypes,
  currencies,
  invoicesData,
  attachmentsData
}) {
  const [isSpin, setIsSpin] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const inv_type = invoicetypes.filter(
    (item) => item.id === invoiceValues?.invoice_type
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currency_code = currencies.filter(
    (item) => item.id === invoiceValues?.currency
  );

  console.log('invoice_type', invoiceId, invoicesData);
  const Supply = [
    {
      id: 1,
      name: 'Certified by EIC',
      value: invoiceValues?.certified_by_eic === 'yes' ? 'YES' : 'NO'
    },
    invoiceValues?.certified_by_eic === 'yes'
      ? {
          id: 2,
          name: 'Name of EIC',
          value: invoiceValues?.name_of_eic
            ? invoiceValues?.name_of_eic.toUpperCase()
            : 'NA'
        }
      : '',
    {
      id: 3,
      name: 'Date of Certification',
      value: moment(invoiceValues?.date_of_certification).format('DD/MM/YYYY')
    },
    {
      id: 4,
      name: 'Invoice Type',
      value: inv_type?.[0]?.invoice_type_des.toUpperCase()
    },
    {
      id: 5,
      name: 'Reference Invoice Number',
      // value: invoiceValues?.reference_invoice_number
      //   ? invoiceValues?.reference_invoice_number.toUpperCase()
      //   : 'NA'
      value:
        invoiceId?.invoice_type_des === 'Debit Note' ||
        invoiceId?.invoice_type_des === 'Credit Note'
          ? invoiceValues?.reference_invoice_number
            ? invoiceValues.reference_invoice_number.toUpperCase()
            : 'NA'
          : 'NA'
    },
    {
      id: 6,
      name: 'Reference Invoice Date',
      // value: moment(invoiceValues?.reference_invoice_date).format('DD/MM/YYYY')
      value:
        invoiceId?.invoice_type_des === 'Debit Note' ||
        invoiceId?.invoice_type_des === 'Credit Note'
          ? invoiceValues?.reference_invoice_date
            ? moment(invoiceValues.reference_invoice_date).format('DD/MM/YYYY')
            : 'NA'
          : // : invoiceValues?.reference_invoice_date
            // ? moment(invoiceValues.reference_invoice_date).format('DD/MM/YYYY')
            'NA'
    },
    {
      id: 7,
      name: 'Certified abstract in standard Format',
      value: invoiceValues?.certified_abstract === 'yes' ? 'YES' : 'NO'
    },
    {
      id: 8,
      name: 'Final Bill',
      value: invoiceValues?.final_bill === 'yes' ? 'YES' : 'NO'
    },
    {
      id: 9,
      name: 'FIM Reconciliation Statement',
      value: invoiceValues?.fim_Reconciliation_statement
        ? invoiceValues?.fim_Reconciliation_statement === 'yes'
          ? 'YES'
          : 'NO'
        : 'NA'
    },
    {
      id: 10,
      name: 'No Claim Certificate',
      value: invoiceValues?.no_claim_certificate
        ? invoiceValues?.no_claim_certificate === 'yes'
          ? 'YES'
          : 'NO'
        : 'NA'
    },
    {
      id: 11,
      name: 'E-Way Bill',
      value: invoiceValues?.eway_bill
        ? invoiceValues?.eway_bill === 'yes'
          ? 'YES'
          : 'NO'
        : 'NA'
    },
    {
      id: 12,
      name: 'Delivery Challan',
      value: invoiceValues?.delivery_challan === 'yes' ? 'YES' : 'NO'
    },
    {
      id: 13,
      name: 'LR Copy',
      value: invoiceValues?.lr_copy === 'yes' ? 'YES' : 'NO'
    },
    {
      id: 14,
      name: 'LD Certification',
      value: invoiceValues?.ld_certification_by_EIC === 'yes' ? 'YES' : 'NO'
    },
    {
      id: 15,
      name: 'MSME',
      value: invoiceValues?.msme === 'yes' ? 'YES' : 'NO'
    },
    {
      id: 16,
      name: 'Udayam Number',
      value: invoiceValues?.udayam_number
        ? invoiceValues?.udayam_number.toUpperCase()
        : 'NA'
    }
  ];
  console.log('invoiceId?.name_of_buyer', invoiceId?.name_of_buyer);

  const Invoices = [
    {
      id: 1,
      name: 'Name of Buyer',
      value: invoiceId?.name_of_buyer?.toUpperCase()
    },
    {
      id: 2,
      name: 'Bill to Party',
      value: invoiceValues?.bill_to_party.toUpperCase()
    },
    {
      id: 3,
      name: 'Deal Slip / Order No',
      value: invoiceValues?.dealslip_orderno.toUpperCase()
    },
    { id: 4, name: 'Work Area', value: invoiceValues?.work_area.toUpperCase() },
    { id: 5, name: 'Vendor Name', value: invoiceId?.vendor_name.toUpperCase() },
    { id: 6, name: 'Vendor Code', value: invoiceId?.vendor_code.toUpperCase() },
    {
      id: 16,
      name: 'Currency',
      value: currency_code?.[0]?.currency_code.toUpperCase()
    },
    currency_code?.[0]?.currency_code === 'INR'
      ? {
          id: 7,
          name: 'GST No',
          value: invoiceValues?.gst_no?.toUpperCase() || 'NA'
        }
      : {},
    {
      id: 8,
      name: 'Invoice No',
      value: invoiceValues?.invoice_no.toUpperCase()
    },
    {
      id: 9,
      name: 'Invoice Date',
      value: moment(invoiceId?.inv_date).format('DD/MM/YYYY')
    },
    {
      id: 10,
      name: 'Invoice Basic Value',
      // value: invoiceValues?.invoice_basic_value
      value: (invoiceValues?.invoice_basic_value
        ? parseFloat(invoiceValues.invoice_basic_value)
        : 0
      )?.toLocaleString(
        currency_code?.[0]?.currency_code === 'EUR' ? 'de-DE' : 'en',
        {
          style: 'currency',
          currency: currency_code?.[0]?.currency_code || 'USD' // Fallback to 'USD' if currency_code is undefined
        }
      )
    },
    currency_code?.[0]?.currency_code === 'INR'
      ? {
          id: 11,
          name: 'GST Amount',
          // value:
          //   invoiceId?.gst_type_code === 'IGST'
          //     ? parseFloat(invoiceValues?.igst)
          //     : (invoiceValues?.cgst > 0
          //         ? parseFloat(invoiceValues?.cgst)
          //         : 0) +
          //       (invoiceValues?.sgst > 0 ? parseFloat(invoiceValues?.sgst) : 0)
          //     ? (invoiceValues?.cgst > 0
          //         ? parseFloat(invoiceValues?.cgst)
          //         : 0) +
          //       (invoiceValues?.sgst > 0 ? parseFloat(invoiceValues?.sgst) : 0)
          //     : 0

          value: (invoiceId?.gst_type_code === 'IGST'
            ? parseFloat(invoiceValues?.igst)
            : (invoiceValues?.cgst > 0 ? parseFloat(invoiceValues?.cgst) : 0) +
              (invoiceValues?.sgst > 0 ? parseFloat(invoiceValues?.sgst) : 0)
            ? (invoiceValues?.cgst > 0 ? parseFloat(invoiceValues?.cgst) : 0) +
              (invoiceValues?.sgst > 0 ? parseFloat(invoiceValues?.sgst) : 0)
            : 0
          )?.toLocaleString(
            currency_code?.[0]?.currency_code === 'EUR' ? 'de-DE' : 'en',
            {
              style: 'currency',
              currency: currency_code?.[0]?.currency_code || 'USD' // Fallback to 'USD'
            }
          )
        }
      : {},
    {
      id: 12,
      name: 'Total Invoice Amount',
      // value:
      //   currency_code?.[0]?.currency_code === 'INR'
      //     ? invoiceId?.gst_type_code === 'IGST'
      //       ? (invoiceValues?.invoice_basic_value > 0
      //           ? parseFloat(invoiceValues?.invoice_basic_value)
      //           : 0) +
      //         (invoiceValues?.igst > 0 ? parseFloat(invoiceValues?.igst) : 0)
      //       : (invoiceValues?.cgst > 0 ? parseFloat(invoiceValues?.cgst) : 0) +
      //         (invoiceValues?.sgst > 0 ? parseFloat(invoiceValues?.sgst) : 0) +
      //         (invoiceValues?.invoice_basic_value > 0
      //           ? parseFloat(invoiceValues?.invoice_basic_value)
      //           : 0)
      //     : parseFloat(invoiceValues?.invoice_basic_value)

      value: (currency_code?.[0]?.currency_code === 'INR'
        ? invoiceId?.gst_type_code === 'IGST'
          ? (invoiceValues?.invoice_basic_value > 0
              ? parseFloat(invoiceValues?.invoice_basic_value)
              : 0) +
            (invoiceValues?.igst > 0 ? parseFloat(invoiceValues?.igst) : 0)
          : (invoiceValues?.cgst > 0 ? parseFloat(invoiceValues?.cgst) : 0) +
            (invoiceValues?.sgst > 0 ? parseFloat(invoiceValues?.sgst) : 0) +
            (invoiceValues?.invoice_basic_value > 0
              ? parseFloat(invoiceValues?.invoice_basic_value)
              : 0)
        : parseFloat(invoiceValues?.invoice_basic_value)
      )?.toLocaleString(
        currency_code?.[0]?.currency_code === 'EUR' ? 'de-DE' : 'en',
        {
          style: 'currency',
          currency: currency_code?.[0]?.currency_code || 'USD' // Fallback to 'USD'
        }
      )
    },
    invoiceId?.invoice_category_code === 'SRVC' ||
    localStorage.getItem('category') === 'SRVC'
      ? {
          id: 13,
          name: 'Period of rendered service (From Date)',
          // value: moment(invoiceValues?.period_from_date).format('DD/MM/YYYY')
          value: invoiceValues?.period_from_date
            ? moment(invoiceValues.period_from_date).format('DD/MM/YYYY')
            : 'NA'
        }
      : {
          id: 13,
          name: 'Period of rendered service (From Date)',
          value: 'NA'
        },
    invoiceId?.invoice_category_code === 'SRVC' ||
    localStorage.getItem('category') === 'SRVC'
      ? {
          id: 14,
          name: 'Period of rendered service (To Date)',
          // value: moment(invoiceValues?.period_to_date).format('DD/MM/YYYY')
          value: invoiceValues?.period_to_date
            ? moment(invoiceValues?.period_to_date).format('DD/MM/YYYY')
            : 'NA'
        }
      : {
          id: 14,
          name: 'Period of rendered service (To Date)',
          value: 'NA'
        },
    {
      id: 15,
      name: 'Material Description',
      value: invoiceValues?.job_materila_Description
    }
  ];

  // console.log(invoiceValues.invoice_attachment.fileList);
  const attachements = [
    invoiceValues?.invoice_attachment?.fileList?.length
      ? {
          name: invoiceValues.invoice_attachment.fileList[0].name,
          size: `${invoiceValues.invoice_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.certified_abstract_attachment?.fileList?.length
      ? {
          name: invoiceValues.certified_abstract_attachment.fileList[0].name,
          size: `${invoiceValues.certified_abstract_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.fim_reconciliation_statement_attachment?.fileList?.length
      ? {
          name: invoiceValues.fim_reconciliation_statement_attachment
            .fileList[0].name,
          size: `${invoiceValues.fim_reconciliation_statement_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.eway_bill_attachment?.fileList?.length
      ? {
          name: invoiceValues.eway_bill_attachment.fileList[0].name,
          size: `${invoiceValues.eway_bill_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.delivery_challan_attachment?.fileList?.length
      ? {
          name: invoiceValues.delivery_challan_attachment.fileList[0].name,
          size: `${invoiceValues.delivery_challan_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.lr_attachment?.fileList?.length
      ? {
          name: invoiceValues.lr_attachment.fileList[0].name,
          size: `${invoiceValues.lr_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.approved_dealslip_attachment?.fileList?.length
      ? {
          name: invoiceValues.approved_dealslip_attachment.fileList[0].name,
          size: `${invoiceValues.approved_dealslip_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.others1_attachment?.fileList?.length
      ? {
          name: invoiceValues.others1_attachment.fileList[0].name,
          size: `${invoiceValues.others1_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.others2_attachment?.fileList?.length
      ? {
          name: invoiceValues.others2_attachment.fileList[0].name,
          size: `${invoiceValues.others2_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.others3_attachment?.fileList?.length
      ? {
          name: invoiceValues.others3_attachment.fileList[0].name,
          size: `${invoiceValues.others3_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.others4_attachment?.fileList?.length
      ? {
          name: invoiceValues.others4_attachment.fileList[0].name,
          size: `${invoiceValues.others4_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.abstract_Standard_attachment?.fileList?.length
      ? {
          name: invoiceValues.abstract_Standard_attachment.fileList[0].name,
          size: `${invoiceValues.abstract_Standard_attachment.fileList[0].size} KB`
        }
      : '',
    //
    invoiceValues?.deal_slip_attachment?.fileList?.length
      ? {
          name: invoiceValues.deal_slip_attachment.fileList[0].name,
          size: `${invoiceValues.deal_slip_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.ld_certification_attachment?.fileList?.length
      ? {
          name: invoiceValues.ld_certification_attachment.fileList[0].name,
          size: `${invoiceValues.ld_certification_attachment.fileList[0].size} KB`
        }
      : '',
    invoiceValues?.no_claim_certification_attachment?.fileList?.length
      ? {
          name: invoiceValues.no_claim_certification_attachment.fileList[0]
            .name,
          size: `${invoiceValues.no_claim_certification_attachment.fileList[0].size} KB`
        }
      : ''
    //
  ];
  const filteredArray = attachements.filter(
    (item) => item !== '' && item !== null && item !== undefined
  );

  const raAbstract = invoiceValues?.ra_attachment?.fileList.length
    ? {
        name: invoiceValues.ra_attachment.fileList[0].name,
        size: `${invoiceValues.ra_attachment.fileList[0].size} KB`
        // status: invoiceValues.ra_attachment.fileList[0].attachment_status
      }
    : '';

  const status =
    attachmentsData?.length > 0
      ? attachmentsData.find((data) => data.attachment_type_code === 'RA')
          ?.attachment_status
      : 'false';
  // const name =
  //   attachmentsData?.length > 0
  //     ? attachmentsData.find((data) => data.attachment_type_code === 'RA')
  //         ?.file_name
  //     : 'false';

  const AttachmentCard = ({ name, size }) => (
    <Card
      bodyStyle={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '4px',
        padding: '4px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        // width: '50%',
        whitesspace: 'wrap',
        overflow: 'hidden',
        fontSize: '12px'
        // marginRight: '10px'
      }}
    >
      <FileOutlined
        style={{
          fontSize: 18,
          marginRight: 10,
          color: '#dfa161',
          background: '#fff9ec',
          border: '4px solid #fffcf6',
          borderRadius: '50%',
          padding: '10px'
        }}
      />
      <div>
        <Text>{name}</Text>
        <br />
        <Text type="secondary">{size}</Text>
      </div>
    </Card>
  );
  const showModal = () => {
    setIsModalVisible(true);
  };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleGeneratePDF = () => {
    console.log('genarate');
    setIsSpin(true);
    setIsSubmitting(true);
    let current_currency = currencies.filter(
      (currency) => currency.id === invoiceValues.currency
    );
    console.log('invoice_basic_value', invoiceValues?.invoice_basic_value);
    let total_invoice_amount =
      current_currency?.[0]?.currency_code === 'INR'
        ? invoiceId?.gst_type_code === 'IGST'
          ? (invoiceValues?.invoice_basic_value > 0
              ? parseFloat(invoiceValues?.invoice_basic_value)
              : 0) +
            (invoiceValues?.igst > 0 ? parseFloat(invoiceValues?.igst) : 0)
          : (invoiceValues?.cgst > 0 ? parseFloat(invoiceValues?.cgst) : 0) +
            (invoiceValues?.sgst > 0 ? parseFloat(invoiceValues?.sgst) : 0) +
            (invoiceValues?.invoice_basic_value > 0
              ? parseFloat(invoiceValues?.invoice_basic_value)
              : 0)
        : parseFloat(invoiceValues?.invoice_basic_value);
    console.log('total_invoice_amount', total_invoice_amount);
    let formData = new FormData();
    formData.append('certified_by_eic', invoiceValues?.certified_by_eic);
    formData.append(
      'name_of_eic',
      invoiceValues?.name_of_eic ? invoiceValues?.name_of_eic : ''
    );
    formData.append(
      'date_of_certification',
      invoiceValues?.date_of_certification
    );
    formData.append('invoice_type_id', invoiceValues?.invoice_type);
    formData.append(
      'reference_invoice_number',
      invoiceValues?.reference_invoice_number
    );
    formData.append(
      'reference_invoice_date',
      invoiceValues?.reference_invoice_date
    );
    formData.append(
      'certified_abstruct_in_stnd_format',
      invoiceValues?.certified_abstract
    );
    formData.append('final_bill', invoiceValues?.final_bill);
    formData.append(
      'fim_reconcilliation_statement',
      invoiceValues?.fim_Reconciliation_statement
        ? invoiceValues?.fim_Reconciliation_statement
        : ''
    );
    formData.append(
      'no_claim_certification',
      invoiceValues?.no_claim_certificate
        ? invoiceValues?.no_claim_certificate
        : ''
    );
    formData.append(
      'e_way_bill',
      invoiceValues?.eway_bill ? invoiceValues?.eway_bill : ''
    );
    formData.append('delivery_challan', invoiceValues?.delivery_challan);
    formData.append('lr_copy', invoiceValues?.lr_copy);
    formData.append(
      'ld_certification_by_eic',
      invoiceValues?.ld_certification_by_EIC
    );
    formData.append('msme', invoiceValues?.msme);
    formData.append(
      'udayam_number',
      invoiceValues?.udayam_number ? invoiceValues?.udayam_number : ''
    );
    // formData.append('name_of_buyer', invoiceValues?.name_of_buyer);
    formData.append('buyer_id', invoiceValues?.buyer_id);
    formData.append('deal_slip', invoiceValues?.dealslip_orderno);
    formData.append('bill_to_party', invoiceValues?.bill_to_party);
    formData.append('work_area', invoiceValues?.work_area);
    formData.append('vendor_id', invoiceId?.vendor_id);
    formData.append('gst_no', invoiceValues?.gst_no);
    formData.append('invoice_no', invoiceValues?.invoice_no);
    formData.append('inv_date', invoiceValues?.invoice_date);
    formData.append('inv_basic_value', invoiceValues?.invoice_basic_value);
    formData.append('currency_id', invoiceValues?.currency);
    formData.append('gst_type_id', invoiceValues?.gst_type);
    formData.append(
      'cgst_amount',
      invoiceId?.gst_type_code === 'IGST' ? 0 : invoiceValues?.cgst || 0
    );
    formData.append(
      'sgst_amount',
      invoiceId?.gst_type_code === 'IGST' ? 0 : invoiceValues?.sgst || 0
    );
    formData.append(
      'igst_amount',
      invoiceId?.gst_type_code === 'IGST' ? invoiceValues?.igst || 0 : 0
    );
    // formData.append(
    //   'total_invoice_amount',
    //   (invoiceValues?.cgst > 0 ? parseFloat(invoiceValues?.cgst) : 0) +
    //     (invoiceValues?.sgst > 0 ? parseFloat(invoiceValues?.sgst) : 0) +
    //     (invoiceValues?.invoice_basic_value > 0
    //       ? parseFloat(invoiceValues?.invoice_basic_value)
    //       : 0) +
    //     (invoiceValues?.igst > 0 ? parseFloat(invoiceValues?.igst) : 0)
    // ); total_invoice_amount
    formData.append('total_invoice_amount', total_invoice_amount || 0);
    formData.append(
      'rendered_service_start_dt',
      invoiceValues?.period_from_date ? invoiceValues?.period_from_date : ''
    );
    formData.append(
      'rendered_service_end_dt',
      invoiceValues?.period_to_date ? invoiceValues?.period_to_date : ''
    );
    formData.append(
      'job_material_description',
      invoiceValues?.job_materila_Description
    );
    invoiceValues?.invoice_attachment
      ? formData.append(
          'invoice_attachment',
          invoiceValues?.invoice_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.certified_abstract_attachment
      ? formData.append(
          'certified_abstract_attachment',
          invoiceValues?.certified_abstract_attachment?.fileList[0]
            .originFileObj
        )
      : '';
    invoiceValues?.fim_reconciliation_statement_attachment
      ? formData.append(
          'fim_reconciliation_statement_attachment',
          invoiceValues?.fim_reconciliation_statement_attachment?.fileList[0]
            .originFileObj
        )
      : '';
    invoiceValues?.eway_bill_attachment
      ? formData.append(
          'eway_bill_attachment',
          invoiceValues?.eway_bill_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.delivery_challan_attachment
      ? formData.append(
          'delivery_challan_attachment',
          invoiceValues?.delivery_challan_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.lr_attachment
      ? formData.append(
          'lr_attachment',
          invoiceValues?.lr_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.approved_dealslip_attachment
      ? formData.append(
          'approved_dealslip_attachment',
          invoiceValues?.approved_dealslip_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.others1_attachment
      ? formData.append(
          'others1_attachment',
          invoiceValues?.others1_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.others2_attachment
      ? formData.append(
          'others2_attachment',
          invoiceValues?.others2_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.others3_attachment
      ? formData.append(
          'others3_attachment',
          invoiceValues?.others3_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.others4_attachment
      ? formData.append(
          'others4_attachment',
          invoiceValues?.others4_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.abstract_Standard_attachment
      ? formData.append(
          'abstract_Standard_attachment',
          invoiceValues?.abstract_Standard_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.ra_attachment
      ? formData.append(
          'ra_attachment',
          invoiceValues?.ra_attachment?.fileList[0].originFileObj
        )
      : '';
    //
    invoiceValues?.deal_slip_attachment
      ? formData.append(
          'deal_slip_attachment',
          invoiceValues?.deal_slip_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.ld_certification_attachment
      ? formData.append(
          'ld_certification_attachment',
          invoiceValues?.ld_certification_attachment?.fileList[0].originFileObj
        )
      : '';
    invoiceValues?.no_claim_certification_attachment
      ? formData.append(
          'no_claim_certification_attachment',
          invoiceValues?.no_claim_certification_attachment?.fileList[0]
            .originFileObj
        )
      : '';
    //
    formData.append(
      'status',
      invoiceId?.invoice_sts_des === 'ST REJECTED'
        ? 'st-submitted'
        : invoiceId?.invoice_sts_des === 'IVT REJECTED'
        ? 'sn-generated'
        : 'st-submitted'
    );
    formData.append('invoice_id', invoiceId?.id);
    formData.append('declaration_signed', true);
    formData.append(
      'invoice_category_id',
      invoiceId?.invoice_category_id
        ? invoiceId?.invoice_category_id
        : localStorage.getItem('invoice_category_id')
    );
    console.log(formData);
    dispatch(submitInvoice(formData)).then((response) => {
      if (response?.payload?.success) {
        // history.push('/invoice/list');
        history.push('/dashboard');
        setIsSpin(false);
      } else {
        setIsSpin(true);
        message.error(response?.payload?.message);
      }
    });
  };
  const pageCancel = () => {
    history.push('/dashboard');
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    // <Form layout="vertical" className={styles.container}>
    <div className={styles.supply}>
      <Row gutter={24}>
        <Col span={12}>
          <div className={styles.divider}>
            <div className={styles.menu}>
              {Supply.map((item, index) => {
                return (
                  <Row gutter={24} key={index}>
                    <Col span={12}>
                      <ul>
                        <li style={{ listStyleType: 'none' }} key={index}>
                          {' '}
                          {item.name}
                        </li>
                      </ul>
                    </Col>
                    <Col span={12}>
                      <ul>
                        <li
                          key={index}
                          style={{
                            listStyleType: 'none',
                            color: '#667085',
                            fontWeight: '100'
                          }}
                        >
                          {' '}
                          {item.value}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </div>
          {/* Supply List End */}

          {/* Invoices List */}
          <div className={styles.divider}>
            <div className={styles.menu}>
              {Invoices.map((item, index) => {
                return (
                  <Row gutter={24} key={index}>
                    <Col span={12}>
                      <ul>
                        <li style={{ listStyleType: 'none' }} key={index}>
                          {' '}
                          {item.name}
                        </li>
                      </ul>
                    </Col>
                    <Col span={12}>
                      <ul>
                        <li
                          key={index}
                          style={{
                            listStyleType: 'none',
                            color: '#667085',
                            fontWeight: '100'
                          }}
                        >
                          {' '}
                          {item.value}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </div>
          {/* Invoices List End */}
        </Col>

        {/* Attachments */}
        <Col span={12}>
          <div className={styles.cardattachments}>
            <div className={styles.attachments}>
              <div style={{ padding: 20 }}>
                <Title level={4} style={{ fontSize: '16px ' }}>
                  Attachments
                </Title>
                <Row gutter={24}>
                  {filteredArray?.map((item, index) => {
                    return (
                      <Col span={12} key={index}>
                        <AttachmentCard
                          key={index}
                          name={item.name}
                          size={item.size}
                          className={styles.attachmentcard1}
                        />
                      </Col>
                    );
                  })}
                </Row>

                {/* <AttachmentCard name={raAbstract.name} size={raAbstract.size} /> */}
                <Space>
                  <Title level={4} style={{ marginTop: 15, fontSize: '16px ' }}>
                    RA Abstract
                  </Title>
                  <Popover
                    placement="top"
                    content="RA Abstract will not be attached as a  part of the Generated PDF. RA Abstract file will be shared as a standalone file with the Reliance Industries personal."
                    overlayClassName={styles.customPopover}
                  >
                    <sup>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                    </sup>
                  </Popover>
                </Space>
                {(() => {
                  // console.log('raAbstract:', raAbstract);
                  // console.log('raAbstract status:', status);
                })()}
                {raAbstract && raAbstract.name ? (
                  <AttachmentCard
                    name={raAbstract.name}
                    size={raAbstract.size}
                  />
                ) : status === 'st-approve' || status === 'ivt-approve' ? (
                  <p className={styles.noRA}> RA abstract approved</p>
                ) : (
                  <p className={styles.noRA}>
                    No RA abstract for the selected invoice type
                  </p>
                )}
              </div>
              {/* <p style={{ fontSize: '14px', fontFamily: '500' }}>
                {' '}
                Attachments
              </p> */}

              {/* RA Abstract */}
              {/* <p style={{ fontSize: '14px', fontFamily: '500' }}>
                {' '}
                RA Attachments
              </p> */}
            </div>
            <FormItem className={styles.sectionfooter}>
              <Space>
                <CustomButton
                  // block
                  // type="secondary"
                  htmlType="submit"
                  className={styles.cancel_button}
                  onClick={pageCancel}
                >
                  Cancel
                </CustomButton>
                {/* {isSpin ? ( */}
                {/* <CustomButton
                  // htmlType="submit"
                  type="primary"
                  ghost
                  // onClick={() => next()}
                  className={styles.border_button}
                >
                  <Spin size="small" />
                </CustomButton> */}
                {/* ) : ( */}
                <CustomButton
                  // block
                  // type="primary"
                  // onClick={() => handleGeneratePDF()}
                  onClick={showModal}
                  htmlType="submit"
                  className={styles.submit_button}
                >
                  {/* Generate PDF */}
                  Submit
                </CustomButton>
                {/* )} */}
              </Space>
              {/* <Tooltip title="RA Abstract will not be attached as a  part of the Generated PDF. RA Abstract file will be shared as a standalone file with the Reliance Industries personal.">
                <CustomButton htmlType="hover" className={styles.symbol}>
                  <img
                    style={{
                      width: '110%'
                    }}
                    src={symbol}
                    alt="Symbol"
                  />
                </CustomButton>
              </Tooltip> */}
            </FormItem>
          </div>

          {/* Modal Code */}

          <Modal
            className={styles.approvemodel}
            // form={form}
            title={
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: '30px' }}
              />
            }
            visible={isModalVisible}
            onCancel={handleModalCancel}
            footer={null}
            closeIcon={<CloseOutlined />}
          >
            {/* <Form form={form} layout="vertical" onFinish={handleGeneratePDF}> */}
            <Form
              form={form}
              layout="vertical"
              onFinish={handleGeneratePDF}
              className={styles.modalform}
            >
              {/* <div className={styles.checkboxcontainer}>
                <input
                  type="checkbox"
                  id="agreement"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="agreement">
                  <p>
                    All entered data is accurate and has not been altered. We
                    accept responsibility for any discrepancies found.
                  </p>
                </label>
              </div> */}

              <Row>
                <Col
                  span={24}
                  className={styles.checkbox}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                >
                  <Checkbox
                    className="sfprotext-medium font-12"
                    // onChange={(e) => handleOnChange(e)}
                  >
                    {/* I declare that the bill is complete as per all the above
                    check points. Invoice will be rejected if above applicable
                    points are not complied. */}
                    I hereby assume full responsibility for the accuracy and
                    authenticity of the submitted data. If any discrepancies or
                    inaccuracies are found, I agree to take full responsibility
                    and face the consequences.
                  </Checkbox>
                </Col>
              </Row>

              <Form.Item className={styles.modalbtn}>
                <CustomButton
                  type="default"
                  onClick={handleModalCancel}
                  style={{ marginRight: 8 }}
                  className={styles.cancelled_button}
                  disabled={isSubmitting}
                >
                  Cancel
                </CustomButton>
                {isSpin ? (
                  <CustomButton
                    // htmlType="submit"
                    type="primary"
                    ghost
                    // onClick={() => next()}
                    className={styles.cancelbutton}
                  >
                    <Spin size="small" />
                  </CustomButton>
                ) : (
                  <CustomButton
                    // block
                    // type="primary"
                    // onClick={() => handleGeneratePDF()}
                    // onClick={showModal}
                    htmlType="submit"
                    className={styles.success_button}
                    disabled={!isChecked} // Disabled if the checkbox is not checked
                  >
                    {/* Generate PDF */}
                    Submit
                  </CustomButton>
                )}
              </Form.Item>
            </Form>
          </Modal>
          {/* Modal Code Completed */}
        </Col>
      </Row>
    </div>
    // </Form>
  );
}

export default index;
