import React, { useState } from 'react';
import { Card, Col, Row, Typography, Form, Input } from 'antd';
import styles from '../../index.module.less';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
// import { TextArea } from 'semantic-ui-react';
const { TextArea } = Input;
const { Text } = Typography;
const { Meta } = Card;
const index = ({ invoicesData, projectid, projects, form }) => {
  const [isShow, setIsShow] = useState(true);
  console.log(invoicesData);
  const projectName = projects?.length
    ? projects.filter((item) => item.id === projectid)
    : [];
  return (
    <>
      <div className={styles.collapse}>
        <Row gutter={24}>
          <Col xs={24} xl={12} span={8}>
            <Meta title="Invoice Details" className={styles.metaData1} />
          </Col>
          <Col xs={24} xl={12} span={8}>
            {!isShow ? (
              <DownOutlined
                style={{ float: 'right' }}
                onClick={() => setIsShow(true)}
              />
            ) : (
              <CloseOutlined
                style={{ float: 'right' }}
                onClick={() => setIsShow(false)}
              />
            )}
          </Col>
        </Row>
        {/* </Space> */}
        {isShow ? (
          <div className={styles.nestedCard1}>
            {/* <div className={styles.tablelist}> */}
            <Row gutter={24} className={styles.rowclass}>
              <Col span={6} className={styles.colclass}>
                <Text strong>Deal slip</Text>
                <div>{invoicesData?.deal_slip}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong>Tax Invoice Number</Text>
                <div>{invoicesData?.invoice_no}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong>Tax Invoice Date</Text>
                <div>{moment(invoicesData?.inv_date).format('YYYY-MM-DD')}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong>Vendor Name & Code</Text>
                <div>{`${invoicesData?.vendor_name} (${invoicesData?.vendor_code})`}</div>
              </Col>
            </Row>
            <Row gutter={24}>
              {/* <Col span={6} className={styles.colclass}>
                <Text strong>DC No.</Text>
                <div>23</div>
              </Col> */}
              <Col span={6} className={styles.colclass}>
                <Text strong>Project Name</Text>
                <div>{projectName?.[0]?.project_name}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong>Invoice Amount</Text>
                <div>{invoicesData?.invoice_amount}</div>
              </Col>
              <Col span={6} className={styles.colclass}>
                <Text strong></Text>
                <div></div>
              </Col>
            </Row>
          </div>
        ) : (
          ''
        )}
        {invoicesData?.invoice_type_code === 'CN' ||
        invoicesData?.invoice_type_code === 'PI' ? (
          <Form form={form} layout="vertical">
            <br />
            <Row gutter={24}>
              <Col xs={24} xl={24} span={16}>
                {/* <Meta title="Invoice Details" className={styles.metaData2} /> */}
                <Form.Item
                  label="Remarks"
                  // name="rejection_message"
                  name="ivt_remarks"
                  className={styles.labelrejection}
                  rules={[{ required: false, message: 'Enter remarks' }]}
                >
                  <TextArea
                    placeholder="Enter remarks"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default index;
