import React from 'react';
// import { Checkbox } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styles from './AttachmentCard.module.css';
import { FileOutlined } from '@ant-design/icons';

const AttachmentCard = ({ name, url }) => {
  //   const [selectedFiles, setSelectedFiles] = useState({});
  // const [isApproved, setIsApproved] = useState(false);
  // const [isRejected, setIsRejected] = useState(false);

  //   const handleCheckboxChange = (e) => {
  //     const { name, checked } = e.target;
  //     onCheckboxChange(fileId, name, checked);
  //   };
  // const handleApproveChange = () => {
  //   setIsApproved(!isApproved);
  //   setIsRejected(false);
  //   onApproval(fileId, !isApproved, false);
  // };

  // const handleRejectChange = () => {
  //   setIsRejected(!isRejected);
  //   setIsApproved(false);
  //   onApproval(fileId, false, !isRejected);
  // };

  //   setSelectedFiles((prevState) => ({
  //     ...prevState,
  //     [fileId]: {
  //       ...prevState[fileId],
  //       [name]: checked
  //     }
  //   }));
  return (
    <>
      <div className={styles.container}>
        <div className={styles.attachmentCard}>
          <div className={styles.attachmentInfo}>
            <div className={styles.attachmentIcon1}>
              <FileOutlined
                style={{
                  fontSize: 18,
                  marginRight: 10,
                  color: '#dfa161',
                  background: '#fff9ec',
                  border: '4px solid #fffcf6',
                  borderRadius: '50%',
                  padding: '10px'
                }}
              />
            </div>
            <div>
              <p className={styles.attachmentName}>{name}</p>
              {/* <p className={styles.attachmentSize}>600 KB</p> */}
            </div>
          </div>
          {/* <div className={styles.attachmentActions}>
            <DownloadOutlined
              className={styles.downloadIcon}
              onClick={() => window.open(url)}
            />
          </div> */}
          <div className={styles.attachmentActions}>
            <DownloadOutlined
              className={styles.downloadIcon}
              onClick={() => {
                // Create a hidden anchor element
                const link = document.createElement('a');
                link.href = url; // URL to the file
                link.setAttribute('download', name); // Provide the file name to download as
                document.body.appendChild(link);
                link.click(); // Programmatically click the link
                document.body.removeChild(link); // Clean up after click
              }}
            />
          </div>
        </div>
        {/* <div className={styles.actionbuttons}>
          <label className={styles.checkbox1styles}>
            <input
              type="checkbox"
              checked={isApproved}
              onChange={handleApproveChange}
              disabled={isRejected}
              className={styles.approvecheckboxstyles}
              style={{ fontSize: '30px' }}
            />
            Approve
          </label>
          <label className={styles.checkbox2styles}>
            <input
              type="checkbox"
              checked={isRejected}
              onChange={handleRejectChange}
              disabled={isApproved}
              className={styles.rejectcheckboxstyles}
            />
            Reject
          </label>
          <input type="hidden" name={`file_name_${fileId}`} value={name} />
          <input type="hidden" name={`file_id_${fileId}`} value={fileId} />
        </div> */}
      </div>
    </>
  );
};

export default AttachmentCard;
