import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  // Card,
  Button,
  Typography,
  // Skeleton,
  List,
  Tabs,
  Collapse,
  Spin
} from 'antd';
import styles from './index.module.less';
import moment from 'moment';
import { getProjectTypeDetails } from '../../Validation/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { getInvoiceData, getHeaderData } from '../redux/slice';
// import { FileOutlined } from '@ant-design/icons';
import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import Attachmentcard from './AttachmentCard';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const index = (Remarks) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let payload = { id };
    getProjectTypes();
    dispatch(getInvoiceData(payload));
  }, []);

  const getProjectTypes = () => {
    const payload = { id: invoicedata?.vendor_id };
    dispatch(getProjectTypeDetails(payload));
  };

  // const formatNumberWithCommasAndDecimals = (number) => {
  //   if (isNaN(number)) return 'NA';
  //   return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  // };

  const { invoicedata } = useSelector((state) => state.getInvoiceData);
  // console.log('itemstesting111', invoicedata);
  const { invoice_details } = invoicedata || {};
  const header = invoice_details?.request_no;
  const { attachments } = invoicedata || {};
  // console.log('raattachment_details', attachments);
  // const { projectsTypes } = useSelector((state) => state.IVTinvoices);
  // console.log('projectsTypes', projectsTypes);

  const Supply = [
    {
      id: 1,
      name: 'Certified by EIC',
      value: invoice_details?.certified_by_eic ? 'YES' : 'NO'
    },
    {
      id: 2,
      name: 'Name of EIC',
      value: invoice_details?.name_of_eic.toUpperCase()
    },
    {
      id: 3,
      name: 'Date of Certification',
      value: moment(invoice_details?.date_of_certification).format('DD/MM/YYYY')
    },
    {
      id: 4,
      name: 'Invoice Type',
      value: invoice_details?.invoice_type_des.toUpperCase()
    },
    {
      id: 5,
      name: 'Reference Invoice Number',
      // value: invoice_details?.reference_invoice_number
      //   ? invoice_details?.reference_invoice_number.toUpperCase()
      //   : 'NA'
      value:
        invoice_details?.invoice_type_des === 'Debit Note' ||
        invoice_details?.invoice_type_des === 'Credit Note'
          ? invoice_details?.reference_invoice_number
            ? invoice_details.reference_invoice_number.toUpperCase()
            : 'NA'
          : 'NA'
    },
    {
      id: 6,
      name: 'Reference Invoice Date',
      // value: moment(invoice_details?.reference_invoice_date).format(
      //   'DD/MM/YYYY'
      // )
      value:
        invoice_details?.invoice_type_des === 'Debit Note' ||
        invoice_details?.invoice_type_des === 'Credit Note'
          ? invoice_details?.reference_invoice_date
            ? moment(invoice_details.reference_invoice_date).format(
                'DD/MM/YYYY'
              )
            : 'NA'
          : invoice_details?.reference_invoice_date
          ? moment(invoice_details.reference_invoice_date).format('DD/MM/YYYY')
          : 'NA'
    },
    {
      id: 7,
      name: 'Certified abstract in standard Format',
      value: invoice_details?.certified_abstruct_in_stnd_format ? 'YES' : 'NO'
    },
    {
      id: 8,
      name: 'Final Bill',
      value: invoice_details?.final_bill ? 'YES' : 'NO'
    },
    {
      id: 9,
      name: 'FIM Reconciliation Statement',
      value: invoice_details?.fim_reconcilliation_statement
        ? invoice_details?.fim_reconcilliation_statement
          ? 'YES'
          : 'NO'
        : 'NA'
    },
    {
      id: 10,
      name: 'No Claim Certificate',
      value: invoice_details?.no_claim_certification
        ? invoice_details?.no_claim_certification
          ? 'YES'
          : 'NO'
        : 'NA'
    },
    {
      id: 11,
      name: 'E-Way Bill',
      value: invoice_details?.e_way_bill ? 'YES' : 'NO'
    },
    {
      id: 12,
      name: 'Delivery Challan',
      value: invoice_details?.delivery_challan ? 'YES' : 'NO'
    },
    { id: 13, name: 'LR Copy', value: invoice_details?.lr_copy ? 'YES' : 'NO' },
    {
      id: 14,
      name: 'LD Certification',
      value: invoice_details?.ld_certification_by_eic ? 'YES' : 'NO'
    },
    { id: 15, name: 'MSME', value: invoice_details?.msme ? 'YES' : 'NO' },
    {
      id: 16,
      name: 'Udayam Number',
      value: invoice_details?.udayam_number
        ? invoice_details?.udayam_number.toUpperCase()
        : 'NA'
    }
  ];
  // console.log('invoice_details', invoice_details?.invoice_sts_des);
  const Invoices = [
    {
      id: 1,
      name: 'Name of Buyer',
      value: invoice_details?.name_of_buyer.toUpperCase()
    },
    {
      id: 2,
      name: 'Deal Slip / Order No',
      value: invoice_details?.deal_slip.toUpperCase()
    },
    {
      id: 3,
      name: 'Bill to Party',
      value: invoice_details?.bill_to_party.toUpperCase()
    },
    {
      id: 4,
      name: 'Work Area',
      value: invoice_details?.work_area.toUpperCase()
    },
    {
      id: 5,
      name: 'Vendor Name',
      value: invoice_details?.vendor_name.toUpperCase()
    },
    {
      id: 6,
      name: 'Vendor Code',
      value: invoice_details?.vendor_code.toUpperCase()
    },
    {
      id: 8,
      name: 'Currency',
      value: invoice_details?.currency_code.toUpperCase()
    },
    {
      id: 7,
      name: 'GST No',
      value: invoice_details?.gst_no
        ? invoice_details?.gst_no?.toUpperCase()
        : 'NA'
    },
    {
      id: 8,
      name: 'Invoice No',
      value: invoice_details?.invoice_no.toUpperCase()
    },
    {
      id: 9,
      name: 'Invoice Date',
      value: moment(invoice_details?.inv_date).format('DD/MM/YYYY')
    },
    {
      id: 10,
      name: 'Invoice Basic Value',
      // value: invoice_details?.inv_basic_value
      //   ? formatNumberWithCommasAndDecimals(invoice_details?.inv_basic_value)
      //   : 'NA'
      value: invoice_details?.inv_basic_value
        ? parseFloat(invoice_details?.inv_basic_value)?.toLocaleString(
            invoice_details?.currency_code === 'EUR' ? 'de-DE' : 'en',
            {
              style: 'currency',
              currency: invoice_details?.currency_code
            }
          )
        : 'NA'
    },

    {
      id: 11,
      name: 'GST Amount',
      // value:
      //   parseFloat(invoice_details?.cgst_amount) +
      //   parseFloat(invoice_details?.sgst_amount)
      //     ? parseFloat(invoice_details?.cgst_amount) +
      //       parseFloat(invoice_details?.sgst_amount)
      //     : parseFloat(invoice_details?.igst_amount) || 0
      value: (parseFloat(invoice_details?.cgst_amount) +
      parseFloat(invoice_details?.sgst_amount)
        ? parseFloat(invoice_details?.cgst_amount) +
          parseFloat(invoice_details?.sgst_amount)
        : parseFloat(invoice_details?.igst_amount) || 0
      )?.toLocaleString(
        invoice_details?.currency_code === 'EUR' ? 'de-DE' : 'en',
        {
          style: 'currency',
          currency: invoice_details?.currency_code || 'USD'
        }
      )
    },
    {
      id: 12,
      name: 'Total Invoice Amount',
      // value: invoice_details?.total_invoice_amount
      //   ? invoice_details?.total_invoice_amount.toLocaleString(
      //       invoice_details?.currency_code === 'EUR' ? 'de-DE' : 'en',
      //       {
      //         style: 'currency',
      //         currency: invoice_details?.currency_code
      //       }
      //     )
      //   : 0 || 0
      value: (invoice_details?.invoice_amount
        ? parseFloat(invoice_details.invoice_amount)
        : 0
      ).toLocaleString(
        invoice_details?.currency_code === 'EUR' ? 'de-DE' : 'en',
        {
          style: 'currency',
          currency: invoice_details?.currency_code || 'USD' // Fallback to 'USD' if currency_code is undefined
        }
      )
    },
    invoice_details?.invoice_category_code === 'SRVC'
      ? {
          id: 13,
          name: 'Period of rendered service (From Date)',
          // value: moment(invoice_details?.rendered_service_start_dt).format(
          //   'DD/MM/YYYY'
          // )
          value: invoice_details?.rendered_service_start_dt
            ? moment(invoice_details?.rendered_service_start_dt).format(
                'DD/MM/YYYY'
              )
            : 'NA'
        }
      : {
          id: 13,
          name: 'Period of rendered service (From Date)',
          value: 'NA'
        },
    invoice_details?.invoice_category_code === 'SRVC'
      ? {
          id: 14,
          name: 'Period of rendered service (To Date)',
          // value: moment(invoice_details?.rendered_service_end_dt).format(
          //   'DD/MM/YYYY'
          // )
          value: invoice_details?.rendered_service_end_dt
            ? moment(invoice_details?.rendered_service_end_dt).format(
                'DD/MM/YYYY'
              )
            : 'NA'
        }
      : {
          id: 14,
          name: 'Period of rendered service (To Date)',
          value: 'NA'
        },
    {
      id: 15,
      name: 'Material Description',
      value: invoice_details?.job_material_description
    }
  ];

  const { Title } = Typography;

  let specificAttachment = null;
  if (attachments && attachments.length > 0) {
    specificAttachment = attachments.find(
      (attachment) => attachment.attachment_type_code === 'RA'
    );
  }
  // console.log('specificAttachment.file_url', specificAttachment.file_url);

  const filteredAttachments = attachments?.filter(
    (attachment) => attachment.attachment_type_code !== 'RA'
  );

  // const AttachmentCard = ({ name, size }) => (
  //   <Card
  //     bodyStyle={{
  //       display: 'flex',
  //       alignItems: 'center',
  //       marginBottom: '0',
  //       padding: '10px',
  //       border: '1px solid #ddd',
  //       borderRadius: '8px',
  //       whitesspace: 'nowrap',
  //       overflow: 'hidden',
  //       marginRight: '10px'
  //     }}
  //   >
  //     <FileOutlined
  //       style={{
  //         fontSize: 18,
  //         marginRight: 10,
  //         color: '#dfa161',
  //         background: '#fff9ec',
  //         border: '4px solid #fffcf6',
  //         borderRadius: '50%',
  //         padding: '10px'
  //       }}
  //     />
  //     <div>
  //       <Text>{name}</Text>
  //       <br />
  //       <Text type="secondary">{size}</Text>
  //     </div>
  //   </Card>
  // );

  const [form] = Form.useForm();
  useEffect(() => {
    if (invoice_details) {
      form.setFieldsValue({
        to: invoice_details.email
      });
    }
  }, [invoice_details, form]);

  const data = Remarks.Remarks;
  // console.log('data', data);

  const renderPanelContent = (items) => {
    if (!items || items.length === 0) {
      return <p>No content available</p>;
    }
    // return items.map((item, index) => (
    //   <div key={index} className={styles.panelItem}>
    //     <p className={styles.commenttext}>
    //       <strong>Comment:</strong> {item.comment}
    //     </p>
    //     <p>
    //       <strong>Commented Date:</strong>{' '}
    //       {new Date(item.commented_date).toLocaleString()}
    //     </p>
    //   </div>
    // ));
    return items.map((item, index) => (
      <div key={index} className={styles.panelitem}>
        <div className={styles.commentbubble}>
          <div className={styles.commentheader}>
            {index === 0 && <span className={styles.newtag}>New</span>}
            <p className={styles.commenttext}>{item.comment}</p>
          </div>
          <p className={styles.commentdate}>
            {new Date(item.commented_date).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: 'long',
              year: 'numeric'
            })}
          </p>
        </div>
      </div>
    ));
  };

  const handleBackClick = () => {
    history.push('/dashboard');
  };

  const handleDownload = async () => {
    setLoading(true);
    let payload = { id };
    console.log('payload', payload);
    // const resultAction = await dispatch(getHeaderData(payload));
    // const resultAction = dispatch(getHeaderData(payload));

    // if (getHeaderData.fulfilled.match(resultAction)) {
    //   const buffer = resultAction.payload.data; // This should be the buffer array
    //   const blob = new Blob([Uint8Array.from(buffer)], {
    //     type: 'application/pdf'
    //   });

    //   // Create a URL for the Blob
    //   const url = window.URL.createObjectURL(blob);

    //   // Create a link element
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', 'invoice.pdf'); // Specify the file name here

    //   // Append the link to the body
    //   document.body.appendChild(link);

    //   // Trigger a click event on the link
    //   link.click();

    //   // Clean up
    //   link.parentNode.removeChild(link);
    //   window.URL.revokeObjectURL(url);
    // } else {
    //   console.error('Failed to fetch the PDF', resultAction.error);
    // }

    //
    // Dispatch the thunk to get the PDF data
    const resultAction = await dispatch(getHeaderData(payload));
    console.log('resultAction.payload?.data', resultAction.payload?.data?.data);

    // Check if the response is valid and contains the Buffer data
    if (getHeaderData.fulfilled.match(resultAction)) {
      const bufferData = resultAction.payload?.data?.data;
      // blob?.payload?.data
      // Convert the Buffer to a Uint8Array and then to a Blob
      const uint8Array = new Uint8Array(bufferData);
      const blob = new Blob([uint8Array], { type: 'application/pdf' });

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf'); // Specify the file name here

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger a click event on the link
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Failed to fetch the PDF', resultAction.error);
    }

    // dispatch(getHeaderData(payload)).then((blob) => {
    //   const buffer = Buffer.from(blob?.payload?.data);
    //   const url = window.URL.createObjectURL(new Blob([buffer]));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', `SN Entity Checked.pdf`);
    //   document.body.appendChild(link);
    //   link.click();
    //   link.parentNode.removeChild(link);
    //   // message.success('Report Downloaded successfully');
    //   form.resetFields();
    //   // setDownloadReports(false);
    //   // setLoading(false);
    // });
    setLoading(false);
  };

  return (
    <>
      <Form layout="vertical" className={styles.container}>
        <div className={styles.heading}>
          {header}
          <div className={styles.closebutton}>
            <CustomButton
              onClick={handleBackClick}
              className={styles.closebutton}
            >
              Close
            </CustomButton>
          </div>
        </div>

        <div className={styles.supply}>
          <Row gutter={24} style={{ width: '100%' }}>
            <Col xl={12}>
              <div className={styles.divider}>
                <div className={styles.frame}>
                  <div className={styles.frame1}>
                    <Button
                      type="primary"
                      className={styles.download}
                      // icon={<DownloadOutlined />}
                      icon={
                        loading ? <Spin size="small" /> : <DownloadOutlined />
                      }
                      // href="path/to/your/file"
                      onClick={handleDownload}
                      download
                    ></Button>
                    <div className={styles.frame2}>
                      <div className={styles.menu}>
                        {Supply.map((item, index) => {
                          return (
                            <Row gutter={24} key={index}>
                              <Col span={12}>
                                <ul>
                                  <li
                                    style={{ listStyleType: 'none' }}
                                    key={index}
                                  >
                                    {' '}
                                    {item.name}
                                  </li>
                                </ul>
                              </Col>
                              <Col span={12}>
                                <ul>
                                  <li
                                    key={index}
                                    style={{
                                      listStyleType: 'none',
                                      color: '#667085',
                                      fontWeight: '100'
                                    }}
                                  >
                                    {' '}
                                    {item.value}
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                      <div className={styles.menu}>
                        {Invoices.map((item, index) => {
                          return (
                            <Row gutter={24} key={index}>
                              <Col span={12}>
                                <ul>
                                  <li
                                    style={{ listStyleType: 'none' }}
                                    key={index}
                                  >
                                    {' '}
                                    {item.name}
                                  </li>
                                </ul>
                              </Col>
                              <Col span={12}>
                                <ul>
                                  <li
                                    key={index}
                                    style={{
                                      listStyleType: 'none',
                                      color: '#667085',
                                      fontWeight: '100'
                                    }}
                                  >
                                    {item.value}
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12}>
              <div className={styles.cardattachments}>
                <div className={styles.attachments}>
                  <Title level={5}>Attachments</Title>
                  {filteredAttachments?.length > 0 ? (
                    <List
                      className={styles.attachments}
                      itemLayout="vertical"
                      dataSource={filteredAttachments}
                      renderItem={(item) => (
                        <List.Item className={styles.attachmentcardstyles}>
                          <Attachmentcard
                            name={
                              item.file_name
                                ? item.file_name
                                : 'defaultName.pdf'
                            }
                            url={item.file_url}
                          />
                        </List.Item>
                      )}
                    />
                  ) : (
                    // <Skeleton />
                    <p className={styles.noRA}>
                      No attachments for the selected invoice type{' '}
                    </p>
                  )}
                </div>

                <div className={styles.raabstract}>
                  <Title level={5}>RA Abstract</Title>

                  {specificAttachment && specificAttachment.file_name ? (
                    <>
                      <Attachmentcard
                        name={specificAttachment.file_name}
                        url={specificAttachment.file_url}
                        data-tip="Your tooltip content here"
                      />
                    </>
                  ) : (
                    // <Skeleton />
                    <p className={styles.noRA}>
                      No RA abstract for the selected invoice type{' '}
                    </p>
                  )}
                </div>

                {/* <div className={styles.raabstract}>
              <Title level={5}>RA Abstract</Title>
              </div> */}

                {/* Comments */}

                <div className={styles.commentscon}>
                  <h3 className={styles.commentstitle}>Comments</h3>
                  <Tabs defaultActiveKey="1" className={styles.customtabs}>
                    {data && Object.keys(data).length > 0 ? (
                      Object.keys(data).map((role, index) => (
                        <TabPane
                          tab={role}
                          key={index + 1}
                          className={styles.tabcontent}
                        >
                          <div className={styles.contentbox}>
                            <Collapse
                              bordered={false}
                              className={styles.customcollapse}
                            >
                              <Panel
                                header="Reject"
                                key="1"
                                className={styles.custompanel}
                              >
                                {renderPanelContent(data[role]?.reject)}
                              </Panel>
                              {/* <Panel
                                header="Approve"
                                key="2"
                                className={styles.custompanel}
                              >
                                {renderPanelContent(data[role]?.approve)}
                              </Panel> */}
                              <Panel
                                header="Hold"
                                key="3"
                                className={styles.custompanel}
                              >
                                {renderPanelContent(data[role]?.hold)}
                              </Panel>
                            </Collapse>
                          </div>
                        </TabPane>
                      ))
                    ) : (
                      <p>No comments to display</p>
                    )}
                  </Tabs>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default index;
