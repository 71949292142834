import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Radio,
  Empty,
  Tooltip,
  Checkbox,
  message,
  Modal,
  // Button,
  Upload,
  Spin
  // Select
  // Tag
} from 'antd';
import styles from './index.module.less';
import CustomButton from '@/common/CustomButton';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  vendorDashboard,
  getInvoiceCategory,
  getInvoiceStatuses,
  downloadEntity,
  allSNSharedList,
  uploadSNShared,
  downloadSNEntity,
  allFCAList,
  uploadFCAList,
  fcaUsersList
} from '../../redux/slice';
import { history } from '@/app/history';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import { Pagination } from 'antd';
import prev from '../../images/arrow-left.svg';
import next from '../../images/arrow-right.svg';
import { EyeFilled } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
// import { useParams } from 'react-router-dom';
// import symbol from '../../../Invoice/components/Generatepdf/images/info-hexagon.png';
import {
  CheckCircleTwoTone,
  CloseOutlined
  // SaveOutlined
} from '@ant-design/icons';
import { Buffer } from 'buffer';
import { UploadOutlined } from '@ant-design/icons';
import { getColumnsByRole } from 'utilities/helpers';
// import MyScrollComponent from '../../../ScrollComponent';
import NewsTicker from '../../../NewsTicker';

// import { useNavigate } from 'react-router-dom';
// import { removeDuplicatesFromArray } from 'utilities/helpers';

// const Dashboard = () => {
// const {} = useSelector((state) => state.dashboard);

const { Meta } = Card;

const DashBoardPage = () => {
  const { allOnBoardingList, pagination, categories, statuses, FCAUsersList } =
    useSelector((state) => state.vendorDashboard);
  console.log('FCAUsersList', FCAUsersList);

  const { user } = getLoggedInUser();
  // const navigate = useNavigate();
  const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  const [radiofilter, setRadiofilter] = useState('all');
  const [search, setSearch] = useState('');
  const [radioFilterName, setRadioFilterName] = useState('All');
  const [entityData, setEntityData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isApproveSub, setIsApproveSub] = useState(false);
  const [allDwnlds, setAllDwnlds] = useState(false);
  const [allUplods, setAllUplods] = useState(false);
  const [form] = Form.useForm();

  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no Invoices to display
        </div>
      </span>
    )
  };
  const handleClick = (id, category) => {
    localStorage.setItem('invoice_category_id', id);
    localStorage.setItem('category', category);
    history.push('/checklist');
  };

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.removeItem('category');
  }, []);

  useEffect(() => {
    getVendorData();
    InvoiceCategory();
    InvoiceStatuses();
    getFCAList();
  }, [page, search, radiofilter, radioFilterName]);

  const getVendorData = () => {
    let payload =
      radiofilter !== 'all'
        ? { page, q: search, invoice_status_id: radiofilter }
        : { page, q: search };
    dispatch(vendorDashboard(payload));
  };

  const getFCAList = () => {
    let payload = {};
    dispatch(fcaUsersList(payload));
  };

  const InvoiceCategory = () => {
    dispatch(getInvoiceCategory());
  };

  const InvoiceStatuses = () => {
    dispatch(getInvoiceStatuses());
  };

  // const handleCompany = (e) => {
  //   console.log('e', e);
  //   // setCompanyId(e);
  // };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // console.log('itemstesting', statuses);
  const handleRadioFilter = (e, type) => {
    setRadiofilter(e);
    setRadioFilterName(type);
  };
  const filters = [
    {
      invoice_sts_des: 'All',
      id: 'all'
    }
  ];
  const allstatuses = [...filters, ...statuses];

  const handleChangeNum = (e, id) => {
    if (e.target.checked) {
      let list = [...entityData];
      list.push(id);
      setEntityData(list);
    } else {
      let list = [...entityData];
      list.splice(entityData.indexOf(id), 1);
      setEntityData(list);
    }
  };
  const handleDownload = () => {
    setLoading(true);
    const payload = { invoice_ids: entityData };
    dispatch(downloadEntity(payload)).then((blob) => {
      setLoading(false);
      // setRadioFilterName('SN Entity Checked');
      const buffer = Buffer.from(blob?.payload?.data);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `SN Shared.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // message.success('Report Downloaded successfully');
      // setRadiofilter('all');
      // window.location.reload('SN Entity Checked');
      form.resetFields();
      setRadioFilterName('SN Entity Checked');
      form.resetFields();
      // await refetchData();
      // form.resetFields();
      // setDownloadReports(false);
      // setLoading(false);
    });
  };

  const handleDownloadSNS = () => {
    setAllDwnlds(true);
    dispatch(allSNSharedList()).then((blob) => {
      setAllDwnlds(false);
      const buffer = Buffer.from(blob?.payload?.data);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `All SN Shared Invoice.xlsx`
        // `SharedInvoice_${Date.now().toLocaleDateTimeString()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // history.push('/dashboard');
      // setRadiofilter('SN Shared');
      setRadioFilterName('SN Shared');
      // form.resetFields();
    });
  };
  //
  const handleDownloadFCA = () => {
    setAllDwnlds(true);
    dispatch(allFCAList()).then((blob) => {
      setAllDwnlds(false);
      const buffer = Buffer.from(blob?.payload?.data);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `All FC&A Invoice.xlsx`
        // `SharedInvoice_${Date.now().toLocaleDateTimeString()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // history.push('/dashboard');
      // setRadiofilter('all');
      setRadioFilterName('FC&A Approved');
      // form.resetFields();
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleApproveClick = () => {
    setIsModalVisible(true);
    // setIs_approved(true);
    // setAction(false);
  };
  // const { id } = useParams();

  const onFinish = (values) => {
    setIsApproveSub(true);
    setLoading(true);
    const { additionalEmails = '' } = values || {};
    console.log('values', values);
    // const invoice_id = id;  //
    const management_emails = additionalEmails
      ? additionalEmails
          .split(',')
          .map((email) => email.trim())
          .filter((email) => email)
      : [];
    // if (Array.isArray(additionalEmails)) {
    //   const emailString = additionalEmails.join(',');
    //   console.log('Comma-separated emails:', emailString);
    // }

    let payload = {
      invoice_ids: entityData,
      // fca_emails: emailString
      fca_emails: management_emails
    };
    console.log('payload', payload);
    dispatch(downloadSNEntity(payload)).then((response) => {
      console.log('response?.payload', response?.payload?.success);
      // if (response?.payload?.success) {
      setIsApproveSub(false);
      setIsModalVisible(false);
      form.resetFields();
      console.log(JSON.parse(response.toString('utf8')));
      setLoading(false);
      // setRadioFilterName('SN Shared');
      setRadioFilterName('SN Shared');
      // Refresh the page after API call success
      // window.location.reload();
    });
    setLoading(false);
    setRadioFilterName('SN Shared');
    // window.location.reload();
  };
  console.log(radioFilterName);

  // const columns = [
  //   {
  //     title: 'Request No.',
  //     dataIndex: 'request_no',
  //     key: 'request_no',
  //     width:
  //       radioFilterName == 'SN ENTITY CHECKED' ||
  //       radioFilterName == 'SN Entity Checked' ||
  //       radioFilterName == 'All' ||
  //       radioFilterName == '' ||
  //       radioFilterName == 'Paid' ||
  //       radioFilterName == 'ST Rejected' ||
  //       radioFilterName == 'ST Submitted' ||
  //       radioFilterName == 'SN Generated' ||
  //       radioFilterName == 'SN Validated' ||
  //       radioFilterName == 'SN Shared' ||
  //       radioFilterName == 'ST Entity Checked' ||
  //       radioFilterName == 'IVT Hold' ||
  //       radioFilterName == 'IVT Rejected'
  //         ? 210
  //         : 150,
  //     // ellipsis: true,
  //     // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
  //     render: (request_no, record, index) => {
  //       return (
  //         <>
  //           <Tooltip
  //             title={request_no}
  //             color={'white'}
  //             overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
  //             arrowPointAtCenter="false"
  //           >
  //             {/* || user?.user_role_des == 'SURRGATE USER' */}
  //             {user?.user_role_des == 'VENDOR' &&
  //             (record?.invoice_sts_des == 'DRAFT' ||
  //               record?.invoice_sts_des == 'ST REJECTED' ||
  //               record?.invoice_sts_des == 'Draft' ||
  //               record?.invoice_sts_des == 'ST Rejected' ||
  //               record?.invoice_sts_des == 'IVT Rejected') ? (
  //               //  <a href={`invoice-view/${record.id}`}></a>
  //               <a href={`invoices/${record.id}`}>
  //                 {request_no === null ? 'Not generated' : request_no}
  //               </a>
  //             ) : user?.user_role_des == 'SURRGATE USER' &&
  //               (record?.invoice_sts_des == 'DRAFT' ||
  //                 record?.invoice_sts_des == 'ST REJECTED' ||
  //                 record?.invoice_sts_des == 'Draft' ||
  //                 record?.invoice_sts_des == 'ST Rejected' ||
  //                 record?.invoice_sts_des == 'IVT Rejected') ? (
  //               //  <a href={`invoice-view/${record.id}`}></a>
  //               <a href={`invoices/${record.id}`}>
  //                 {request_no === null ? 'Not generated' : request_no}
  //               </a>
  //             ) : user?.user_role_des == 'SCROLL TEAM' &&
  //               (record?.invoice_sts_des == 'ST SUBMITTED' ||
  //                 record?.invoice_sts_des == 'ST Submitted') ? (
  //               <a href={`scrollview/${record.id}`}>
  //                 {request_no === null ? 'Not generated' : request_no}
  //               </a>
  //             ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
  //               (record?.invoice_sts_des == 'SN Generated' ||
  //                 record?.invoice_sts_des == 'IVT Hold') ? (
  //               <a href={`scrollview/${record.id}`}>
  //                 {request_no === null ? 'Not generated' : request_no}
  //               </a>
  //             ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
  //               record?.invoice_sts_des == 'SN Validated' ? (
  //               <a href={`scrollview/${record.id}`}>
  //                 {request_no === null ? 'Not generated' : request_no}
  //               </a>
  //             ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
  //               record?.invoice_sts_des == 'SN Shared' ? (
  //               <a href={`approveInvoice/${record.id}`}>
  //                 {request_no === null ? 'Not generated' : request_no}
  //               </a>
  //             ) : request_no === null ? (
  //               'Not generated'
  //             ) : radioFilterName == 'SN ENTITY CHECKED' ||
  //               (radioFilterName == 'SN Entity Checked' &&
  //                 user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
  //               <>
  //                 <Checkbox onChange={(e) => handleChangeNum(e, record?.id)} />{' '}
  //                 {request_no}
  //               </>
  //             ) : (
  //               request_no
  //             )}
  //           </Tooltip>
  //           {/* {index === 0 && (
  //             <span
  //               style={{
  //                 display: 'inline-block',
  //                 width: '8px',
  //                 height: '8px',
  //                 backgroundColor: '#3C9C51',
  //                 borderRadius: '50%',
  //                 marginLeft: '8px',
  //                 padding: '8px 0px 0px 0px',
  //                 position: 'absolute',
  //                 top: '15px'
  //               }}
  //             ></span>
  //           )} */}
  //           {index === 0 && (
  //             <span
  //               className={
  //                 index === 0 ? styles.defaultStyle : styles.anotherStyle
  //               }
  //             ></span>
  //           )}
  //         </>
  //       );
  //     }
  //   },
  //   {
  //     title: 'Scroll No.',
  //     dataIndex: 'scroll_no',
  //     header: 'Scroll No.',
  //     key: 'scroll_no',
  //     width: 150
  //   },
  //   {
  //     title: 'Deal Slip No.',
  //     dataIndex: 'deal_slip',
  //     key: 'deal_slip'
  //   },
  //   {
  //     title: 'Invoice Type',
  //     dataIndex: 'invoice_type_des',
  //     key: 'invoice_type_des'
  //   },
  //   {
  //     title: 'Invoice No.',
  //     dataIndex: 'invoice_no',
  //     key: 'invoice_no'
  //   },
  //   {
  //     title: 'Invoice Amount',
  //     dataIndex: 'total_invoice_amount',
  //     key: 'total_invoice_amount'
  //   },
  //   {
  //     title: 'Invoice Date',
  //     dataIndex: 'inv_date',
  //     key: 'inv_date',
  //     sorter: (a, b) =>
  //       moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
  //     render: (inv_date) => (
  //       <>
  //         <Tooltip
  //           title={
  //             inv_date === null
  //               ? 'Not generated'
  //               : moment(inv_date).format('DD/MM/YY')
  //           }
  //           color={'white'}
  //           overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
  //           arrowPointAtCenter="false"
  //         >
  //           {inv_date === null
  //             ? 'Not generated'
  //             : moment(inv_date).format('DD/MM/YY')}
  //         </Tooltip>
  //       </>
  //     )
  //   },
  //   {
  //     title: 'Vendor Name & Code',
  //     dataIndex: 'vendor_name (vendor_code)',
  //     key: 'vendor_name (vendor_code)',
  //     render: (text, record) => (
  //       <span>
  //         {record.vendor_name} ({record.vendor_code})
  //       </span>
  //     )
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'invoice_sts_des',
  //     key: 'invoice_sts_des',
  //     width: 150,
  //     // ellipsis: true,
  //     // textWrap: 'word-break',
  //     sorter: (a, b) =>
  //       (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
  //     render: (text, record) => {
  //       const status = record?.invoice_sts_des || 'NA';
  //       const color =
  //         status == 'ST GENERATED' || status == 'ST Generated'
  //           ? '#007bff'
  //           : status == 'ST SUBMITTED' || status == 'ST Submitted'
  //           ? '#9747FF'
  //           : status == 'ST REJECTED' || status == 'ST Rejected'
  //           ? '#E3486C'
  //           : status == 'SN VALIDATED' || status == 'SN Validated'
  //           ? '#344054'
  //           : status == 'IVT HOLD' || status == 'IVT Hold'
  //           ? '#FF5E00'
  //           : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
  //           ? '#15B0E0'
  //           : status == 'SN SHARED' || status == 'SN Shared'
  //           ? '#FF00FF'
  //           : status == 'PAID' || status == 'Paid'
  //           ? '#3C9C51'
  //           : status == 'DRAFT' || status == 'Draft'
  //           ? '#6c757d'
  //           : status == 'SN GENERATED' || status == 'SN Generated'
  //           ? '#2F80ED'
  //           : status == 'IVT REJECTED' || status == 'IVT Rejected'
  //           ? '#D92D20'
  //           : 'green';
  //       return (
  //         <span style={{ color, fontSize: '11px !important' }}>{status}</span>
  //       );
  //     }
  //   },
  //   {
  //     title: 'View',
  //     dataIndex: 'View',
  //     key: 'View',
  //     className: styles.highlightedColumn,
  //     render: (request_no, record) => {
  //       return (
  //         <>
  //           <Tooltip
  //             title={
  //               record?.request_no === null
  //                 ? 'Not Generated'
  //                 : record?.request_no
  //             }
  //             color={'white'}
  //             overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
  //             arrowPointAtCenter="false"
  //           >
  //             {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
  //             {record?.request_no !== null ? (
  //               <a href={`invoice-view/${record.id}`}>
  //                 {request_no === null ? 'Not generated' : request_no}
  //                 <EyeFilled className={`${styles.edit_icon}`} />
  //               </a>
  //             ) : (
  //               <EyeFilled />
  //             )}
  //           </Tooltip>
  //         </>
  //       );
  //     }
  //   }
  // ];

  const handleOnChangePdfUpload = async (e) => {
    const formData = new FormData();
    setAllUplods(true);
    formData.append('file', e.file.originFileObj);
    dispatch(uploadSNShared(formData)).then((response) => {
      if (response?.payload?.success) {
        setAllUplods(false);
        // setRadioFilterName('All');
        setRadioFilterName('SN Shared');
        getVendorData();
        message.success(response?.payload?.message);
      } else {
        setAllUplods(false);
        message.error(response?.payload?.message);
      }
    });
  };
  //
  const handleOnChangeFCAUpload = async (e) => {
    const formData = new FormData();
    setAllUplods(true);
    formData.append('file', e.file.originFileObj);
    dispatch(uploadFCAList(formData)).then((response) => {
      if (response?.payload?.success) {
        setAllUplods(false);
        setRadioFilterName('All');
        getVendorData();
        message.success(response?.payload?.message);
      } else {
        setAllUplods(false);
        message.error(response?.payload?.message);
      }
    });
  };

  return (
    <>
      {/* <div className="App">
        <MyScrollComponent />
      </div> */}

      <Form form={form} layout="vertical">
        <Card className={styles.cardbody}>
          <Row gutter={24}>
            <Col span={6} xl={10} xs={24}>
              <Meta
                title=" Records of all Invoices"
                description="A complete repository that records the details and progress of all invoices"
              />
            </Col>
            {user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
            (radioFilterName == 'SN SHARED' ||
              radioFilterName == 'SN Shared') ? (
              <>
                <Col span={24} xl={7} xs={24}>
                  {/* <Input ></Input> */}
                </Col>
                <Col span={6} xl={7} xs={12}>
                  <Space className={styles.buttonwrap}>
                    {allDwnlds ? (
                      <Spin />
                    ) : (
                      <CustomButton
                        type="secondary"
                        // loading={loading}
                        onClick={handleDownloadSNS}
                      >
                        Download All SN Shared
                      </CustomButton>
                    )}
                    {allUplods ? (
                      <Spin />
                    ) : (
                      <Upload
                        // {...uploadProps}
                        accept=".xlsx"
                        file_name="file"
                        // fileList={fileList}
                        onChange={handleOnChangePdfUpload}
                        customRequest={({ onSuccess }) => {
                          setTimeout(() => {
                            onSuccess('ok');
                          }, 0);
                        }}
                        showUploadList={false}
                      >
                        <CustomButton
                          type="primary"
                          icon={<UploadOutlined />}
                          // loading={loading}
                        >
                          Upload
                        </CustomButton>
                      </Upload>
                    )}
                    {/* <CustomButton type="primary" onClick={handleApproveClick}>
                      Upload
                    </CustomButton> */}
                  </Space>
                </Col>
              </>
            ) : // user?.user_role_des == 'SCROLL TEAM' ||
            user?.user_role_des == 'INVOICE VALIDATION TEAM' ? (
              <>
                <Col span={24} xl={7} xs={24}>
                  {/* <Input ></Input> */}
                </Col>
                <Col span={24} xl={7} xs={24}>
                  {radioFilterName == 'SN ENTITY CHECKED' ||
                  radioFilterName == 'SN Entity Checked' ? (
                    <Space className={styles.buttonwrap}>
                      <CustomButton
                        type="secondary"
                        onClick={handleDownload}
                        loading={loading}
                      >
                        Download
                      </CustomButton>
                      <CustomButton
                        type="primary"
                        onClick={handleApproveClick}
                        // loading={loading}
                      >
                        Share with FC&A
                      </CustomButton>
                    </Space>
                  ) : (
                    <Input
                      placeholder="Search..."
                      prefix={<SearchOutlined />}
                      style={{ width: '100%', borderRadius: '5px' }} // Adjust the style as needed
                      className={styles.search}
                      onChange={(e) => handleSearch(e)}
                    />
                  )}
                </Col>
              </>
            ) : // user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' ? (
            //   <>
            //     <Col span={24} xl={7} xs={24}>
            //       {/* <Input ></Input> */}
            //     </Col>
            //     <Col span={24} xl={7} xs={24}>
            //       {radioFilterName == 'FC&A Approved' ||
            //       radioFilterName == 'fc&a approved' ? (
            //         <>
            //           <Col span={24} xl={7} xs={24}>
            //             {/* <Input ></Input> */}
            //           </Col>
            //           <Col span={6} xl={7} xs={12}>
            //             <Space className={styles.buttonwrap}>
            //               {allDwnlds ? (
            //                 <Spin />
            //               ) : (
            //                 <CustomButton
            //                   type="secondary"
            //                   // loading={loading}
            //                   onClick={handleDownloadFCA}
            //                 >
            //                   Download All FC&A Approved
            //                 </CustomButton>
            //               )}
            //               {allUplods ? (
            //                 <Spin />
            //               ) : (
            //                 <Upload
            //                   // {...uploadProps}
            //                   accept=".xlsx"
            //                   file_name="file"
            //                   // fileList={fileList}
            //                   onChange={handleOnChangeFCAUpload}
            //                   customRequest={({ onSuccess }) => {
            //                     setTimeout(() => {
            //                       onSuccess('ok');
            //                     }, 0);
            //                   }}
            //                   showUploadList={false}
            //                 >
            //                   <CustomButton
            //                     type="primary"
            //                     icon={<UploadOutlined />}
            //                     // loading={loading}
            //                   >
            //                     Upload
            //                   </CustomButton>
            //                 </Upload>
            //               )}
            //               {/* <CustomButton type="primary" onClick={handleApproveClick}>
            //                 Upload
            //               </CustomButton> */}
            //             </Space>
            //           </Col>
            //         </>
            //       ) : (
            //         <Input
            //           placeholder="Search..."
            //           prefix={<SearchOutlined />}
            //           style={{ width: '100%', borderRadius: '5px' }} // Adjust the style as needed
            //           className={styles.search}
            //           onChange={(e) => handleSearch(e)}
            //         />
            //       )}
            //     </Col>
            //   </>
            // )
            user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
              (radioFilterName == 'FC&A Approved' ||
                radioFilterName == 'fc&a approved') ? (
              <>
                <Col span={24} xl={7} xs={24}>
                  {/* <Input ></Input> */}
                </Col>
                <Col span={6} xl={7} xs={12}>
                  <Space className={styles.buttonwrap}>
                    {allDwnlds ? (
                      <Spin />
                    ) : (
                      <CustomButton
                        type="secondary"
                        // loading={loading}
                        onClick={handleDownloadFCA}
                      >
                        Download All FC&A Approved
                      </CustomButton>
                    )}
                    {allUplods ? (
                      <Spin />
                    ) : (
                      <Upload
                        // {...uploadProps}
                        accept=".xlsx"
                        file_name="file"
                        // fileList={fileList}
                        onChange={handleOnChangeFCAUpload}
                        customRequest={({ onSuccess }) => {
                          setTimeout(() => {
                            onSuccess('ok');
                          }, 0);
                        }}
                        showUploadList={false}
                      >
                        <CustomButton
                          type="primary"
                          icon={<UploadOutlined />}
                          // loading={loading}
                        >
                          Upload
                        </CustomButton>
                      </Upload>
                    )}
                    {/* <CustomButton type="primary" onClick={handleApproveClick}>
                      Upload
                    </CustomButton> */}
                  </Space>
                </Col>
              </>
            ) : user?.user_role_des == 'SCROLL TEAM' ? (
              <>
                <Col span={24} xl={7} xs={24}>
                  {/* <Input ></Input> */}
                </Col>
                <Col span={24} xl={7} xs={24}>
                  <Input
                    placeholder="Search..."
                    prefix={<SearchOutlined />}
                    style={{ width: '100%', borderRadius: '5px' }} // Adjust the style as needed
                    className={styles.search}
                    onChange={(e) => handleSearch(e)}
                  />
                </Col>
              </>
            ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' ? (
              <>
                <Col span={24} xl={7} xs={24}>
                  {/* <Input ></Input> */}
                </Col>
                <Col span={24} xl={7} xs={24}>
                  <Input
                    placeholder="Search..."
                    prefix={<SearchOutlined />}
                    style={{ width: '100%', borderRadius: '5px' }} // Adjust the style as needed
                    className={styles.search}
                    onChange={(e) => handleSearch(e)}
                  />
                </Col>
              </>
            ) : (
              <Col span={24} xl={7} xs={24}>
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined />}
                  style={{ width: '100%', borderRadius: '5px' }} // Adjust the style as needed
                  className={styles.search}
                  onChange={(e) => handleSearch(e)}
                />
              </Col>
            )}
            {user?.user_role_des == 'VENDOR' ||
            user?.user_role_des == 'SURRGATE USER' ? (
              <Col
                span={18}
                xl={7}
                xs={11}
                // size={10}
                className={styles.customebuttons}
              >
                <Space className={styles.spacestyles}>
                  <CustomButton
                    onClick={() =>
                      handleClick(
                        categories[0]?.id,
                        categories[0]?.invoice_category_code
                      )
                    }
                    // type="secondary"
                    className={styles.buttonstyles}
                  >
                    Submit Supply Invoice
                  </CustomButton>
                  <CustomButton
                    onClick={() =>
                      handleClick(
                        categories[1]?.id,
                        categories[1]?.invoice_category_code
                      )
                    }
                    // type="secondary"
                    className={styles.buttonstyles}
                  >
                    Submit Service Invoice
                  </CustomButton>
                </Space>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Divider />

          <div className="App">
            <NewsTicker styles={{ color: 'red' }} />
          </div>

          <Row gutter={24} className={styles.navwrap}>
            <Col span={24}>
              <Radio.Group
                value={radiofilter}
                size="small"
                className={styles.button_import_style}
              >
                {allstatuses.map((item, index) => {
                  return (
                    <Radio.Button
                      value={item.id}
                      key={index}
                      className={`${styles.RadioButton_import_style}`}
                      onChange={(e) =>
                        handleRadioFilter(e.target.value, item.invoice_sts_des)
                      }
                    >
                      {item.invoice_sts_des}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Col>
          </Row>
          <div className={`${styles.table_container}`}>
            <CustomTable
              locale={locale}
              // loading={getAllMaterilLoading}
              columns={getColumnsByRole(
                radioFilterName,
                Tooltip,
                user,
                Checkbox,
                handleChangeNum,
                styles,
                moment,
                EyeFilled
              )}
              dataSource={allOnBoardingList}
              pagination={false}
              className={styles.pagination_style}
              scroll={{ x: 100 }}
              // pagination={{
              //   position: ['bottomCenter'],
              //   size: 'small',
              //   total: pagination?.totalItems || 0,
              //   showSizeChanger: false,
              //   onChange: (page) => {
              //     setPage(page);
              //   }
              // }}
              // pagination={{
              //   position: ['bottomCenter'],
              //   current: page,
              //   size: 'small',
              //   total: pagination?.total_records || 0,
              //   onChange: (page) => {
              //     setPage(page);
              //     // setPageSize(pageSize);
              //   }
              // }}
              // className={styles.scrolling}
              // scroll={{ x: 'auto' }}
            />
            {/* <br /> */}
            {allOnBoardingList.length ? (
              <div className={styles.pagination}>
                <span
                  className={page === 1 ? styles.prevDisabled : styles.prev}
                  onClick={() => setPage(page - 1)}
                >
                  <img className={styles.prev} src={prev} /> Previous
                </span>
                <Pagination
                  className={styles.pagination_style}
                  current={page}
                  total={pagination?.totalItems || 0}
                  // itemRender={itemRender}
                  onChange={(page) => setPage(page)}
                  showSizeChanger={false}
                  scroll={{ x: 'auto' }}
                />
                <span
                  span={8}
                  className={
                    page === pagination?.totalPages
                      ? styles.nextdisabled
                      : styles.next
                  }
                  onClick={() => setPage(page + 1)}
                >
                  Next
                  <img className={styles.prev} src={next} />
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </Card>
      </Form>
      {/* Modal for Shared for Management */}
      <Modal
        className={styles.approvemodel}
        form={form}
        title={
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: '30px' }}
          />
        }
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        closeIcon={<CloseOutlined />}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            className={styles.formcc}
            label={
              <>
                {`Management mail Id's`}
                {/* <Tooltip
                  title="Separate different email id's with a comma"
                  overlayClassName="custom-tooltip"
                  className={styles.customtooltip}
                >
                  <img src={symbol} alt="info" style={{ marginLeft: 8 }} />
                </Tooltip> */}
              </>
            }
            name="additionalEmails"
            // rules={[
            //   {
            //     validator: async (_, value) => {
            //       if (!value || value.trim() === '') {
            //         return Promise.resolve();
            //       }
            //       const emailsArray = value
            //         .split(',')
            //         .map((email) => email.trim());
            //       if (emailsArray.length === 0) {
            //         return Promise.reject(
            //           new Error('Please enter at least one email address')
            //         );
            //       }
            //       return Promise.resolve();
            //     }
            //   }
            // ]}
          >
            <Input
              className={styles.mailbody}
              // prefix={
              //   <span className={styles.inputgrouptext} id="basic-addon2">
              //     cc:
              //   </span>
              // }
              placeholder="Enter mail id"
              aria-label="Enter mail id"
              aria-describedby="basic-addon2"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
            />
            {/* <Select
              // placeholder="Select Invoice Type"
              // style={{ width: '100%' }}
              // name="projectType"
              // value={emails}
              // onChange={(e) => setEmails(e.target.value)}
              // mode="multiple" // Allow multiple selections
              onChange={(e) => handleCompany(e)}
              placeholder="Select FC&A user mail id"
              showSearch
              optionFilterProp="children"
              // onChange={onChangeBatch}
              onSearch={handleSearch}
              aria-label="Select FC&A user mail id"
              aria-describedby="basic-addon2"
              className={styles.companytype}
            >
              {FCAUsersList?.map((item) => (
                <option key={item.id} value={item.email}>
                  {item.email}
                </option>
              ))}
            </Select> */}
          </Form.Item>
          <Form.Item>
            <CustomButton
              type="default"
              onClick={handleModalCancel}
              style={{ marginRight: 8 }}
              className={styles.cancelbutton}
              disabled={loading}
            >
              Cancel
            </CustomButton>
            {isApproveSub ? (
              <Spin />
            ) : (
              <CustomButton
                // type="primary"
                htmlType="submit"
                // onClick={handleApproveSubmit}
                loading={loading}
                className={styles.approvebutton}
              >
                Share
              </CustomButton>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
// };
export default DashBoardPage;
