import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Button, Card, Row, Col, Input } from 'antd';
import styles from './index.module.less';
// import './custom-steps.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import { PaidInv } from '../redux/slice';
import moment from 'moment';

const InvoicePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isShow, setIsShow] = useState(true);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { Meta } = Card;
  const { invoicesData } = useSelector((state) => state.invoices);

  useEffect(() => {}, [invoicesData]);
  const handleSave = (values) => {
    setLoading(true);
    // console.log('Form values:', values);
    const {
      // management_approval_dt,
      // expected_date
      sap_scroll_number,
      sap_scroll_dt,
      payment_advice_number,
      payment_date
    } = values;

    let payload = {
      // management_approval_dt: !management_approval_dt
      //   ? moment(management_approval_dt, 'YYYY-MM-DD')
      //   : null,
      // :values.management_approval_dt ? moment(values.management_approval_dt).format('DD/MM/YYYY') : null
      // management_approval_dt,
      // expected_date,
      sap_scroll_number,
      sap_scroll_dt,
      payment_advice_number,
      payment_date,
      id
    };
    console.log('payload', payload);
    onSubmit(payload);
  };

  const onSubmit = async (payload) => {
    setLoading(true);
    const response = await dispatch(PaidInv(payload));
    console.log('response', response);
    if (response.payload.success) {
      navigate('/dashboard');
    }
  };
  console.log('invoicesDataaaaaaaaaaa', invoicesData);

  // const handleManagementApprovalDateChange = (date) => {
  //   if (date) {
  //     const expectedDate = moment(date).add(15, 'days');
  //     form.setFieldsValue({ expected_date: expectedDate });
  //   } else {
  //     form.setFieldsValue({ expected_date: null });
  //   }
  // };

  return (
    <>
      <Card className={styles.cardbody}>
        <Meta
          title={
            localStorage.getItem('category') === 'SPPLY'
              ? '#SubmitSupplyInvoice'
              : '#SubmitServiceInvoice'
          }
          className={styles.metaData}
        />
        {/* <Meta title={invoicesData?.request_no} className={styles.metaData} /> */}
        {/* <div className={styles.collapse}>
        <Meta title="Invoice details" className={styles.metaData2} />
        <br /> */}
        <div className={styles.collapse}>
          <Row gutter={24}>
            <Col xs={24} xl={12} span={8}>
              <Meta title="Invoice Details" className={styles.metaData1} />
            </Col>
            <Col xs={24} xl={12} span={12}>
              {!isShow ? (
                <DownOutlined
                  style={{ float: 'right' }}
                  onClick={() => setIsShow(true)}
                />
              ) : (
                <CloseOutlined
                  style={{ float: 'right' }}
                  onClick={() => setIsShow(false)}
                />
              )}
            </Col>
          </Row>
          {/* </Space> */}
          {isShow ? (
            <div className={styles.nestedCard1}>
              <Form form={form} layout="vertical" onFinish={handleSave}>
                <Row gutter={24}>
                  {/* <Col span={12}>
                    <Form.Item
                      name="management_approval_dt"
                      label="Management approval date"
                      rules={[
                        {
                          required: true,
                          message: 'Select date'
                          // Select Management Approval Date
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        onChange={handleManagementApprovalDateChange}
                      />
                    </Form.Item>
                  </Col> */}

                  {/* <Col xs={24} xl={12} span={12}>
                    <Form.Item
                      name="expected_date"
                      label="Expected Date"
                      rules={[
                        {
                          required: true,
                          message: 'Expected date'
                        }
                      ]}
                      className={styles.customLable}
                      plaseholder="Expected date"
                    >
                      <DatePicker style={{ width: '100%' }} disabled />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} xl={12} span={12}>
                    <Form.Item
                      name="sap_scroll_number"
                      label="SAP scroll number"
                      rules={[
                        {
                          required: true,
                          message: 'Enter scroll number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter scroll number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} span={12}>
                    <Form.Item
                      name="sap_scroll_dt"
                      label="SAP scroll date"
                      rules={[
                        {
                          required: true,
                          message: 'Select date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <DatePicker
                        placeholder="Select SAP scroll date"
                        style={{ width: '100%' }}
                        disabledDate={(current) => {
                          return (
                            // moment().add(-30, 'days') >= current ||
                            moment().add(0, 'month') <= current ||
                            (current && current <= moment('2024-04-01'))
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} span={12}>
                    <Form.Item
                      name="payment_advice_number"
                      label="Payment advice number"
                      rules={[
                        {
                          required: true,
                          message: 'Enter payment advice number'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Input placeholder="Enter payment advice number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={12} span={12}>
                    <Form.Item
                      name="payment_date"
                      label="Payment advice date"
                      rules={[
                        {
                          required: true,
                          message: 'Select date'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <DatePicker
                        placeholder="Select payment advice date"
                        style={{ width: '100%' }}
                        disabledDate={(current) => {
                          return (
                            // moment().add(-30, 'days') >= current ||
                            moment().add(0, 'month') <= current ||
                            (current && current <= moment('2024-04-01'))
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item className={styles.modalbtn}>
                  <Button
                    htmlType="submit"
                    loading={loading}
                    className={styles.savebutton}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            ''
          )}
        </div>
      </Card>
    </>
  );
};
export default InvoicePage;
