import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { searchdata, searchexportdata } from './api';
// import { history } from 'app/history';

const initialState = {
  loading: false,
  allOnBoardingList: [],
  pagination: {}
};

const actions = {
  GETADVANCESEARCHDATA: 'getadvancesearchData/GETADVANCESEARCHDATA',
  ADSEARCHEXPORT: 'adSearchExport/ADSEARCHEXPORT'
};

export const getadvancesearchData = createAsyncThunk(
  actions.GETADVANCESEARCHDATA,
  async (payload) => {
    const response = await searchdata('/invoice/advance_search', payload);
    if (response.success === true) {
      // const messageText = response.message;
      // message.success(messageText);
    } else {
      //   // Set the error message
      const errorMessage = response.message;
      message.error(errorMessage);
    }
    return response;
  }
);

export const adSearchExport = createAsyncThunk(
  actions.ADSEARCHEXPORT,
  async (payload) => {
    const response = await searchexportdata(
      '/invoice/export_advance_search_results',
      payload
    );
    return response;
  }
);

export const exportedSlice = createSlice({
  name: 'getadvancesearchData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getadvancesearchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getadvancesearchData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        // console.log('data.items', data.items);
        // console.log('data.meta', data.meta);
        state.allOnBoardingList = data.items ? data.items : [];
        state.pagination = data.meta || null;
        // state.searchData = data;
        // message: msg
        // message.success(msg);
      })
      .addCase(getadvancesearchData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(adSearchExport.pending, (state) => {
        state.loading = true;
      })
      .addCase(adSearchExport.fulfilled, (state, action) => {
        const { data, message: msg } = action.payload;
        // state.searchData = data;
        console.log('data', data);

        state.loading = true;
        // message: msg
        message.success(msg);
      })
      .addCase(adSearchExport.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default exportedSlice.reducer;
