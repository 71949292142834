import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  invoicetypes,
  submitInvoices,
  invoiceByid,
  vendorByID,
  allVendors,
  allBuyers
} from './api';
// ./api
const initialState = {
  loading: false,
  invoicetypes: [],
  currencies: [],
  gstTypes: [],
  invoicesData: {},
  vendorData: {},
  vendorslist: [],
  attachmentData: [],
  buyerslist: []
};

const actions = {
  GETINVOICETYPES: 'GetInvoiceTypes/GETINVOICETYPES',
  GETCURRENCIES: 'GetCurrencies/GETCURRENCIES',
  GETGSTDATA: 'GetGSTData/GETGSTDATA',
  SUBMITINVOICE: 'submitInvoice/SUBMITINVOICE',
  INVOICEDATABYID: 'getInvoiceDataById/INVOICEDATABYID',
  GETVENDORBYID: 'GetVendorDataByID/GETVENDORBYID',
  GETVENDORS: 'GetVendors/GETVENDORS',
  GETBUYERS: 'GetBuyers/GETBUYERS'
};

export const GetInvoiceTypes = createAsyncThunk(
  actions.GETINVOICETYPES,
  async (payload) => {
    const response = await invoicetypes('invoice-types', payload);
    return response;
  }
);

export const GetCurrencies = createAsyncThunk(
  actions.GETCURRENCIES,
  async (payload) => {
    const response = await invoicetypes('currency', payload);
    return response;
  }
);

export const GetGSTData = createAsyncThunk(
  actions.GETGSTDATA,
  async (payload) => {
    const response = await invoicetypes('gst-types', payload);
    return response;
  }
);

export const submitInvoice = createAsyncThunk(
  actions.SUBMITINVOICE,
  async (payload) => {
    const response = await submitInvoices('invoice', payload);

    if (response.success === true) {
      const messageText = response.message;
      message.success(messageText);
      // history.push('/login');
    } else {
      //   // Set the error message
      const errorMessage = response.message;
      message.error(errorMessage);
    }
    return response;
  }
);

export const getInvoiceDataById = createAsyncThunk(
  actions.INVOICEDATABYID,
  async (payload) => {
    const response = await invoiceByid(`invoice/${payload}`, payload);
    return response;
  }
);

export const GetVendorDataByID = createAsyncThunk(
  actions.GETVENDORBYID,
  async (payload) => {
    const response = await vendorByID(`vendor/${payload}`, payload);
    return response;
  }
);

export const GetVendors = createAsyncThunk(
  actions.GETVENDORS,
  async (payload) => {
    const response = await allVendors('vendor', payload);
    return response;
  }
);

export const GetBuyers = createAsyncThunk(
  actions.GETBUYERS,
  async (payload) => {
    const response = await allBuyers('buyer', payload);
    return response;
  }
);

export const InvoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetInvoiceTypes.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetInvoiceTypes.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.invoicetypes = data;
        state.acceptedLoading = false;
      })
      .addCase(GetInvoiceTypes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(GetCurrencies.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetCurrencies.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.currencies = data;
        state.acceptedLoading = false;
      })
      .addCase(GetCurrencies.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(GetGSTData.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetGSTData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.gstTypes = data;
        state.acceptedLoading = false;
      })
      .addCase(GetGSTData.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(submitInvoice.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(submitInvoice.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.invoicesData = data;
        state.acceptedLoading = false;
      })
      .addCase(submitInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getInvoiceDataById.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(getInvoiceDataById.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.invoicesData = data?.invoice_details;
        state.attachmentData = data?.attachments;
        state.acceptedLoading = false;
      })
      .addCase(getInvoiceDataById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(GetVendorDataByID.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetVendorDataByID.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.vendorData = data.invoice_details;
      })
      .addCase(GetVendorDataByID.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(GetVendors.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetVendors.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.vendorslist = data;
      })
      .addCase(GetVendors.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });

    builder
      .addCase(GetBuyers.pending, (state) => {
        state.acceptedLoading = true;
      })
      .addCase(GetBuyers.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.buyerslist = data;
      })
      .addCase(GetBuyers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.acceptedLoading = false;

        message.error(msg);
      });
  }
});

export default InvoicesSlice.reducer;
