import React from 'react';
import Layout from '@/common/Layout';
import RevertAdvancing from '@/pages/RevertAdvance/components';

const index = () => {
  return (
    <>
      <Layout sider={true}>
        <RevertAdvancing />
        {/* Revert Advance */}
      </Layout>
    </>
  );
};

export default index;
