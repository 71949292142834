import React, { useEffect, useState } from 'react';
import { Row, Form, Input, Col } from 'antd';
import styles from './index.module.less';
import { Message, Icon } from 'semantic-ui-react';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import { useDispatch } from 'react-redux';
import { SetPassword } from '../redux/slice';
import { useLocation, useNavigate } from 'react-router-dom';
import RILLogo from '@/common/Layout/images/RILLogo.png';
// /redux/slice
// const { Link } = Typography;

const index = ({ loading }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');
  const [form] = Form.useForm();

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  const token = searchParams.get('token');

  useEffect(() => {
    // Check if passwords match and update the button's disabled state
    setIsButtonDisabled(password !== cpassword || !password || !cpassword);
  }, [password, cpassword]);

  const onFinish = (values) => {
    const { password, cpassword } = values;

    if (password !== cpassword) {
      setErrorMsg('Passwords do not match');
      return;
    } else {
      setIsButtonDisabled(false);
    }

    let payload = {
      password,
      token // Include the token in the payload
    };

    onSubmit(payload);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const onSubmit = (payload) => {
  //   dispatch(SetPassword(payload));
  // };
  const onSubmit = async (payload) => {
    const response = await dispatch(SetPassword(payload));
    if (response.payload.success) {
      navigate('/login');
    }
  };

  return (
    <>
      <Row className={styles.mainContainerStyle}>
        <Col xs={24} xl={16} md={12} sm={12} className={styles.backgroundImg}>
          <img className={`${styles.left_logo} mt-3 ml-5 mb-1`} src={RILLogo} />
        </Col>
        <Col xs={18} xl={8} sm={18} md={12} className={styles.sideheader}>
          <div className={styles.sideheader1}>
            <h1
              style={{
                marginTop: '50%',
                marginLeft: '7%',
                fontWeight: 'Bold',
                color: '#BFA161',
                fontSize: '26px'
                // fontFamily: 'Source Sans Pro'
              }}
            >
              {/* Let&apos;s get started */}
              Reset Password
            </h1>
          </div>
          {/* <AuthWrapper> */}
          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password
                  placeholder="Enter your new password"
                  className={styles.customLable}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormItem>
              <FormItem
                label="Confirm Password"
                name="cpassword"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('The passwords do not match for the above!')
                      );
                    }
                  })
                ]}
              >
                <Input.Password
                  placeholder="Enter your confirm password"
                  className={styles.customLable}
                  onChange={(e) => setCpassword(e.target.value)}
                />
              </FormItem>
              {/* <Row>
                <Col span={12}>
                  <Checkbox className=" sfprotext-medium font-12">
                    Remember
                  </Checkbox>
                </Col>
                <Col span={12}>
                  <Link
                    href="/reset-password"
                    className="d-block text-right mb-4 sfprotext-medium font-12"
                  >
                    Forgot password?
                  </Link>
                </Col>
              </Row> */}
              <FormItem>
                <CustomButton
                  // block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={isButtonDisabled}
                  className={styles.login_button}
                >
                  Submit
                  {/* <ArrowRightOutlined /> */}
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </Col>
      </Row>
      {/*  <div className={styles.bakground_img}>
        <div className={styles.left_screen}>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
          <Row className={`mt-5 ml-5 mb-5`}></Row>
        </div>
        <div className={styles.right_screen}></div>
      </div> */}
      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </>
  );
};

export default index;
