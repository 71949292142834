import apiClient from 'utilities/apiClient';

export const userDashboard = (payload) => {
  return apiClient.get(`${apiClient.Urls.userDashboard}`, payload, true);
};
export const categories = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoiceMaster}/${url}`, payload, true);
};

// export const exportEntity = (url, payload) => {
//   return apiClient.post(`${apiClient.Urls.ivtTeam}/${url}`, payload, true);
// };
export const exportEntity = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.ivtTeam}/${url}`, payload, true);
};

export const snsharedList = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.fcateam}/${url}`, payload, true);
};

export const snsharedUpload = (url, payload) => {
  // return apiClient.post(`${apiClient.Urls.snUpload}`, payload, true, 'file');
  return apiClient.post(
    `${apiClient.Urls.fcateam}/${url}`,
    payload,
    true,
    'file'
  );
};

export const FCAList = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.fcateam}/${url}`, payload, true);
};

export const FCAUploadList = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.fcateam}/${url}`,
    payload,
    true,
    'file'
  );
};
//
export const FCAUsersList = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.ivtusers}/${url}`, payload, true);
};
