import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { noticeboard } from './api';
// import { history } from 'app/history';

const initialState = {
  loading: false,
  data: []
  // user: null
};

const actions = {
  GETNOTICEBOARD: 'getNoticeBoard/GETNOTICEBOARD'
};

export const getNoticeBoard = createAsyncThunk(
  actions.GETNOTICEBOARD,
  async (payload) => {
    const response = await noticeboard(`/notice-board`, payload);
    return response;
  }
);

export const exportedSlice = createSlice({
  name: 'exported',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNoticeBoard.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNoticeBoard.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.data = data;
        // message.success(msg);
      })
      .addCase(getNoticeBoard.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default exportedSlice.reducer;
