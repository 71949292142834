import React, { useState, useEffect } from 'react';
import {
  Steps,
  Row,
  Col,
  Card,
  Menu,
  Space,
  Form,
  Spin,
  Button,
  Popover,
  message
} from 'antd';
import styles from '../../index.module.less';
import Header from '../HeaderInfo';
import InvoiceInfo from '../InvoiceInfo';
import Attachments from '../Attachments';
import RaAttachments from '../RAAbstract';
import GeneratePdf from '../Generatepdf';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import messageicon from '../../images/message-text-square.svg';
import './custom-steps.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetInvoiceTypes,
  GetCurrencies,
  GetGSTData,
  submitInvoice,
  getInvoiceDataById,
  GetVendors,
  GetBuyers
} from '../../redux/slice';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
// const { Step } = Steps;
const { Meta } = Card;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label
  };
}
const InvoicePage = () => {
  const { invoicetypes, currencies, gstTypes, vendorslist, buyerslist } =
    useSelector((state) => state.invoices);

  const { user } = getLoggedInUser();

  const dispatch = useDispatch();
  const params = useParams();

  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [invoiceValues, setInvoiceValues] = useState({});
  const [invoiceId, setInvoiceId] = useState('');
  const [isSpin, setIsSpin] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [search, setSearch] = useState('');
  const [invoicesData, setInvoicesData] = useState({});
  const [attachmentsData, setAttachmentsData] = useState({});
  // const [removeAttachment, setRemoveAttachment] = useState(true);
  const [vendorData, setVendorData] = useState(
    user?.user_role_des == 'VENDOR' ? user : {}
  );
  const [currency, setCurrency] = useState(
    invoiceValues?.currency_code || 'INR'
  );
  const [gstData, setGstData] = useState('');
  // const [fileData, setFileData] = useState(invoiceValues);

  const [cgstData, setCgstData] = useState(invoiceValues?.cgst || 0);
  const [sgstData, setSgstData] = useState(invoiceValues?.sgst || 0);
  const [igstData, setIgstData] = useState(invoiceValues?.igst || 0);
  const [basicInvoice, setBasicInvoice] = useState(
    invoiceValues?.invoice_basic_value || 0
  );
  const [buyername, setBuyername] = useState();
  // const next = () => {
  //   setCurrent(current + 1);
  // };
  console.log('fileList', fileList);

  // console.log(invoiceId);
  // console.log(invoicesData);
  let emptyObject = JSON.stringify(invoicesData) === '{}';

  useEffect(() => {
    invoiceData();
    getCurrencies();
    getGSTData();
    getVendors();
    getBuyers();
    params.id ? getInvoicedataById(params.id) : '';
  }, [current]);

  const invoiceData = () => {
    dispatch(GetInvoiceTypes());
  };
  const getCurrencies = () => {
    dispatch(GetCurrencies());
  };
  const getGSTData = () => {
    dispatch(GetGSTData());
  };

  const getVendors = () => {
    dispatch(GetVendors());
  };

  const getBuyers = () => {
    const payload = { q: search };
    dispatch(GetBuyers(payload));
  };
  const getInvoicedataById = (id) => {
    dispatch(getInvoiceDataById(id)).then((result) => {
      if (result?.payload?.success) {
        setInvoicesData(result.payload.data.invoice_details);
        setAttachmentsData(result.payload.data.attachments);
      }
    });
  };
  console.log('setAttachmentsData', attachmentsData);

  const handleCurrency = (e) => {
    const currency = currencies.filter((item) => item.id === e);
    // console.log(currency);
    setCurrency(currency[0]?.currency_code);
  };

  const handleGst = (e) => {
    const gsts = gstTypes.filter((item) => item.id === e);
    setGstData(gsts?.[0]?.gst_type_code);
  };
  // console.log('invoiceId.nameofBuyer', invoiceId.name_of_buyer);
  console.log('invoiceValues?.invoice_attachment', invoicesData);

  useEffect(() => {
    const dateValue =
      invoicesData?.reference_invoice_date && !emptyObject
        ? moment(invoicesData.reference_invoice_date, 'YYYY-MM-DD')
        : null;

    const startDate =
      invoicesData?.rendered_service_start_dt && !emptyObject
        ? moment(invoicesData.rendered_service_start_dt, 'YYYY-MM-DD')
        : null;

    const endDate =
      invoicesData?.rendered_service_end_dt && !emptyObject
        ? moment(invoicesData.rendered_service_end_dt, 'YYYY-MM-DD')
        : null;

    const fromDate =
      invoicesData?.rendered_service_start_dt && !emptyObject
        ? moment(invoicesData.rendered_service_start_dt, 'YYYY-MM-DD')
        : null;

    const toDate =
      invoicesData?.rendered_service_end_dt && !emptyObject
        ? moment(invoicesData.rendered_service_end_dt, 'YYYY-MM-DD')
        : null;

    form.setFieldsValue({
      invoice_attachment: invoiceValues?.invoice_attachment,
      certified_by_eic: !emptyObject
        ? invoicesData?.certified_by_eic
          ? 'yes'
          : 'no'
        : null,
      name_of_eic: !emptyObject ? invoicesData?.name_of_eic : null,
      date_of_certification: !emptyObject
        ? moment(invoicesData?.date_of_certification, 'YYYY-MM-DD')
        : null,
      invoice_type: !emptyObject ? invoicesData?.invoice_type_id : null,
      reference_invoice_number: !emptyObject
        ? invoicesData?.reference_invoice_number
        : null,
      // reference_invoice_date: !emptyObject
      //   ? moment(invoicesData?.reference_invoice_date, 'YYYY-MM-DD')
      //   : null,
      reference_invoice_date: moment(dateValue).isValid() ? dateValue : null,
      certified_abstract: !emptyObject
        ? invoicesData?.certified_abstruct_in_stnd_format
          ? 'yes'
          : 'no'
        : null,
      final_bill: !emptyObject
        ? invoicesData?.final_bill
          ? 'yes'
          : 'no'
        : null,
      fim_Reconciliation_statement: !emptyObject
        ? invoicesData?.fim_reconcilliation_statement
          ? 'yes'
          : 'no'
        : null,
      no_claim_certificate: !emptyObject
        ? invoicesData?.no_claim_certification
          ? 'yes'
          : 'no'
        : null,
      eway_bill: !emptyObject
        ? invoicesData?.e_way_bill
          ? 'yes'
          : 'no'
        : null,
      delivery_challan: !emptyObject
        ? invoicesData?.delivery_challan
          ? 'yes'
          : 'no'
        : null,
      lr_copy: !emptyObject ? (invoicesData?.lr_copy ? 'yes' : 'no') : null,
      ld_certification_by_EIC: !emptyObject
        ? invoicesData?.ld_certification_by_eic
          ? 'yes'
          : 'no'
        : null,
      msme: !emptyObject ? (invoicesData?.msme ? 'yes' : 'no') : null,
      udayam_number: !emptyObject ? invoicesData?.udayam_number : null,
      // name_of_buyer: !emptyObject ? invoicesData?.name_of_buyer : null,
      buyer_id: !emptyObject ? invoicesData?.buyer_id : null,
      dealslip_orderno: !emptyObject ? invoicesData?.deal_slip : null,
      bill_to_party: !emptyObject ? invoicesData?.bill_to_party : null,
      work_area: !emptyObject ? invoicesData?.work_area : null,
      vendor_name:
        user?.user_role_des == 'VENDOR'
          ? user?.vendor_name
          : !emptyObject
          ? invoicesData?.vendor_id
          : user?.user_role_des == 'SURRGATE USER'
          ? vendorData?.vendor_id
          : null,
      vendor_code:
        user?.user_role_des == 'VENDOR'
          ? user?.vendor_code
          : !emptyObject
          ? invoicesData?.vendor_code
          : user?.user_role_des == 'SURRGATE USER'
          ? vendorData?.vendor_code
          : null,
      gst_no: !emptyObject ? invoicesData?.gst_no : null,
      invoice_no: !emptyObject ? invoicesData?.invoice_no : null,
      invoice_date: !emptyObject
        ? moment(invoicesData?.inv_date, 'YYYY-MM-DD')
        : null,
      invoice_basic_value: !emptyObject ? invoicesData?.inv_basic_value : null,
      currency: !emptyObject ? invoicesData?.currency_id : null,
      gst_type: !emptyObject ? invoicesData?.gst_type_id : null,
      cgst: !emptyObject ? invoicesData?.cgst_amount : null,
      sgst: !emptyObject ? invoicesData?.sgst_amount : null,
      igst: !emptyObject ? invoicesData?.igst_amount : null,
      total_invoice_amount: !emptyObject
        ? invoicesData?.total_invoice_amount
        : null,
      // rendered_service_start_dt: !emptyObject
      //   ? moment(invoicesData?.rendered_service_start_dt, 'YYYY-MM-DD')
      //   : null,
      // rendered_service_end_dt: !emptyObject
      //   ? moment(invoicesData?.rendered_service_end_dt, 'YYYY-MM-DD')
      //   : null,
      rendered_service_start_dt: moment(startDate).isValid() ? startDate : null,
      rendered_service_end_dt: moment(endDate).isValid() ? endDate : null,
      // period_from_date: !emptyObject
      //   ? moment(invoicesData?.rendered_service_start_dt, 'YYYY-MM-DD')
      //   : null,
      // period_to_date: !emptyObject
      //   ? moment(invoicesData?.rendered_service_end_dt, 'YYYY-MM-DD')
      //   : null,
      period_from_date: fromDate && fromDate.isValid() ? fromDate : null,
      period_to_date: toDate && toDate.isValid() ? toDate : null,
      job_materila_Description: !emptyObject
        ? invoicesData?.job_material_description
        : null,
      status: !emptyObject ? invoicesData?.status : null,
      declaration_signed: !emptyObject ? invoicesData?.declaration_signed : null
    });
    setBuyername(invoicesData?.name_of_buyer);
  }, [invoicesData]);
  // console.log('buyername', buyername);
  // console.log('attachmentsData', attachmentsData);
  // const status = attachmentsData?.filter(
  //   (data) => data.attachment_type_code === 'INV'
  // )[0].attachment_status;
  // console.log('status', status);

  // attachmentsData?.forEach((attachment) => {
  //   console.log('attachmentsData', attachment?.attachment_type_code);
  // });

  const attchment_files = [
    {
      label: 'Invoice',
      file_name: 'invoice_attachment',
      fileList: invoiceValues?.invoice_attachment?.fileList,
      header_name: 'true',
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'INV'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'INV'
          )[0]?.file_name
        : ''
      // disabled:
      //   invoiceValues?.invoice_attachment?.attachment_type_code === 'INV' &&
      //   invoiceValues?.invoice_attachment?.attachment_status === 'approve'
    },
    {
      label: 'Certified abstract in standard Format',
      file_name: 'certified_abstract_attachment',
      fileList: invoiceValues?.certified_abstract_attachment?.fileList,
      header_name: invoiceValues?.certified_abstract,
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'CASF'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'CASF'
          )[0]?.file_name
        : ''
    },
    {
      label: 'FIM Reconciliation Statement',
      file_name: 'fim_reconciliation_statement_attachment',
      fileList:
        invoiceValues?.fim_reconciliation_statement_attachment?.fileList,
      // header_name: invoiceValues?.fim_Reconciliation_statement,
      header_name:
        invoiceValues?.final_bill === 'true' ||
        invoiceValues?.final_bill === 'yes'
          ? invoiceValues?.fim_Reconciliation_statement
          : '',
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'FRS'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'FRS'
          )[0]?.file_name
        : ''
    },
    {
      label: 'E-way Bill',
      file_name: 'eway_bill_attachment',
      fileList: invoiceValues?.eway_bill_attachment?.fileList,
      header_name: invoiceValues?.eway_bill,
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'EWB'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'EWB'
          )[0]?.file_name
        : ''
    },
    {
      label: 'Delivery Challan',
      file_name: 'delivery_challan_attachment',
      fileList: invoiceValues?.delivery_challan_attachment?.fileList,
      header_name: invoiceValues?.delivery_challan,
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'DC'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'DC'
          )[0]?.file_name
        : ''
    },
    {
      label: 'LR Copy',
      file_name: 'lr_attachment',
      fileList: invoiceValues?.lr_attachment?.fileList,
      header_name: invoiceValues?.lr_copy,
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'LR'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'LR'
          )[0]?.file_name
        : ''
    },
    {
      label: 'Deal Slip (Latest Approved)',
      file_name: 'approved_dealslip_attachment',
      fileList: invoiceValues?.approved_dealslip_attachment?.fileList,
      header_name: 'true',
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'LADS'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'LADS'
          )[0]?.file_name
        : ''
    },
    // {
    //   label: 'Latest Approved Deal Slip',
    //   file_name: 'deal_slip_attachment',
    //   fileList: invoiceValues?.deal_slip_attachment?.fileList,
    //   header_name: 'true',
    //   file_required: true
    // },
    {
      label: 'No Claim Certificate',
      file_name: 'no_claim_certification_attachment',
      fileList: invoiceValues?.no_claim_certification_attachment?.fileList,
      // header_name: invoiceValues?.no_claim_certificate,
      header_name:
        invoiceValues?.final_bill === 'true' ||
        invoiceValues?.final_bill === 'yes'
          ? invoiceValues?.no_claim_certificate
          : '',
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'NCC'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'NCC'
          )[0]?.file_name
        : ''
    },
    {
      label: 'LD Certification by EIC',
      file_name: 'ld_certification_attachment',
      fileList: invoiceValues?.ld_certification_attachment?.fileList,
      header_name: invoiceValues?.ld_certification_by_EIC,
      file_required: true,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'LD'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'LD'
          )[0]?.file_name
        : ''
    },
    {
      label: 'Others 1 (If any)',
      file_name: 'others1_attachment',
      fileList: invoiceValues?.others1_attachment?.fileList,
      header_name: 'true',
      file_required: false,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'OTHER1'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'OTHER1'
          )[0]?.file_name
        : ''
    },
    {
      label: 'Others 2 (If any)',
      file_name: 'others2_attachment',
      fileList: invoiceValues?.others2_attachment?.fileList,
      header_name: 'true',
      file_required: false,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'OTHER2'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'OTHER2'
          )[0]?.file_name
        : ''
    },
    {
      label: 'Others 3 (If any)',
      file_name: 'others3_attachment',
      fileList: invoiceValues?.others3_attachment?.fileList,
      header_name: 'true',
      file_required: false,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'OTHER3'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'OTHER3'
          )[0]?.file_name
        : ''
    },
    {
      label: 'Others 4 (If any)',
      file_name: 'others4_attachment',
      fileList: invoiceValues?.others4_attachment?.fileList,
      header_name: 'true',
      file_required: false,
      status: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'OTHER4'
          )[0]?.attachment_status
        : '',
      name: attachmentsData?.length
        ? attachmentsData.filter(
            (data) => data.attachment_type_code === 'OTHER4'
          )[0]?.file_name
        : ''
    }

    // {
    //   label: 'Abstract in standard Format',
    //   file_name: 'abstract_Standard_attachment',
    //   fileList: invoiceValues?.abstract_Standard_attachment?.fileList,
    //   header_name: 'true'
    // }
  ];
  const steps = [
    {
      title: 'First',
      content: (
        <Header
          invoicetypes={invoicetypes}
          invoiceValues={invoiceValues}
          invoicesData={invoicesData}
          id={params.id ? params.id : ''}
        />
      ),
      header: 'Header Information',
      dd: 0,
      sub: 1
    },
    {
      title: 'First',
      content: (
        <InvoiceInfo
          currencies={currencies}
          gstTypes={gstTypes}
          invoiceValues={invoiceValues}
          invoicesData={invoicesData}
          vendorslist={vendorslist}
          buyerslist={buyerslist}
          setInvoicesData={setInvoicesData}
          user={user}
          vendorData={vendorData}
          setVendorData={setVendorData}
          currency={currency}
          setCurrency={setCurrency}
          handleCurrency={handleCurrency}
          handleGst={handleGst}
          setGstData={setGstData}
          gstData={gstData}
          cgstData={cgstData}
          setCgstData={setCgstData}
          sgstData={sgstData}
          setSgstData={setSgstData}
          igstData={igstData}
          setIgstData={setIgstData}
          basicInvoice={basicInvoice}
          setBasicInvoice={setBasicInvoice}
          buyername={buyername}
          setSearch={setSearch}
          invoicetypes={invoicetypes}
        />
      ),
      header: 'Invoice Details',
      dd: 0,
      sub: 2
    },
    {
      title: 'Second',
      content: (
        <Attachments
          invoiceValues={invoiceValues}
          fileList={fileList}
          setFileList={setFileList}
          attchment_files={attchment_files}
          attachmentsData={attachmentsData}
          setInvoiceValues={setInvoiceValues}
          // removeAttachment={removeAttachment}
        />
      ),
      header: 'Attachments Details',
      dd: 1,
      sub: 3
    },
    {
      title: 'Second',
      content: (
        <RaAttachments
          invoiceValues={invoiceValues}
          invoicesData={invoicesData}
          invoicetypes={invoicetypes}
          attachmentsData={attachmentsData}
        />
      ),
      // header: 'RA Abstract Details',
      header:
        'RA Abstract (Provide an up-to-date RA-wise abstract in Excel, using the standard format only)',
      dd: 1,
      sub: 3
    },
    {
      title: 'Last',
      content: (
        <GeneratePdf
          invoiceValues={invoiceValues}
          invoiceId={invoiceId}
          invoicetypes={invoicetypes}
          currencies={currencies}
          attachmentsData={attachmentsData}
        />
      ),
      header: 'Supply',
      dd: 2
    }
  ];

  const items = [
    {
      title: 'Header & Invoice details',
      content: 'First-content'
    },
    {
      title: 'Attachments & RA Abstract',
      content: 'Second-content'
    },
    {
      title: 'Preview',
      content: 'Last-content'
    }
  ];
  // const contentStyle = {
  //   lineHeight: '260px',
  //   textAlign: 'center',
  //   marginTop: 16
  // };

  const items1 = [
    getItem('Header', 1),
    getItem('Invoice Details', 2),
    getItem('Attachments', 3),
    getItem('RA Abstract', 4),
    getItem('Preview', 5)
  ];

  // useEffect(() => {
  //   form.setFieldsValue({ ['invoice_attachment']: [] });
  //   console.log('invoice_attachment', 'Hello Testinggg');
  // }, [fileList]);

  console.log(current);
  const onFinish = (values) => {
    console.log('Success:', values, current, fileList);
    setInvoiceValues({ ...invoiceValues, ...values });
    console.log('dataChecking', invoiceValues, values, current);

    if (current === 1) {
      setIsSpin(true);
      let current_currency = currencies.filter(
        (currency) => currency.id === values.currency
      );
      let total_invoice_amount =
        current_currency?.[0]?.currency_code === 'INR'
          ? (values?.cgst > 0 ? parseFloat(values?.cgst) : 0) +
            (values?.sgst > 0 ? parseFloat(values?.sgst) : 0) +
            (values?.invoice_basic_value > 0
              ? parseFloat(values?.invoice_basic_value)
              : 0) +
            (values?.igst > 0 ? parseFloat(values?.igst) : 0)
          : parseFloat(values?.invoice_basic_value);
      let formData = new FormData();
      invoicesData?.id ? formData.append('invoice_id', invoicesData?.id) : '';
      formData.append('certified_by_eic', invoiceValues?.certified_by_eic);
      formData.append(
        'name_of_eic',
        invoiceValues?.certified_by_eic === 'yes'
          ? invoiceValues?.name_of_eic
            ? invoiceValues?.name_of_eic
            : ''
          : ''
      );
      formData.append(
        'date_of_certification',
        invoiceValues?.date_of_certification
        // moment(invoiceValues?.date_of_certification).format('DD/MM/YYYY')
      );
      formData.append('invoice_type_id', invoiceValues?.invoice_type);
      formData.append(
        'reference_invoice_number',
        invoiceValues?.reference_invoice_number
          ? invoiceValues?.reference_invoice_number
          : ''
      );
      formData.append(
        'reference_invoice_date',
        invoiceValues?.reference_invoice_date
          ? invoiceValues?.reference_invoice_date
          : ''
      );
      formData.append(
        'certified_abstruct_in_stnd_format',
        invoiceValues?.certified_abstract
      );
      formData.append('final_bill', invoiceValues?.final_bill);
      formData.append(
        'fim_reconcilliation_statement',
        invoiceValues?.fim_Reconciliation_statement
          ? invoiceValues?.fim_Reconciliation_statement
          : ''
      );
      formData.append(
        'no_claim_certification',
        invoiceValues?.no_claim_certificate
          ? invoiceValues?.no_claim_certificate
          : ''
      );
      formData.append(
        'e_way_bill',
        invoiceValues?.eway_bill ? invoiceValues.eway_bill : ''
      );
      formData.append('delivery_challan', invoiceValues?.delivery_challan);
      formData.append('lr_copy', invoiceValues?.lr_copy);
      formData.append(
        'ld_certification_by_eic',
        invoiceValues?.ld_certification_by_EIC
      );
      formData.append('msme', invoiceValues?.msme);
      formData.append(
        'udayam_number',
        invoiceValues?.udayam_number ? invoiceValues?.udayam_number : ''
      );
      // formData.append('name_of_buyer', values?.name_of_buyer);buyer_id
      formData.append('buyer_id', values?.buyer_id);
      formData.append('deal_slip', values?.dealslip_orderno);
      formData.append('bill_to_party', values?.bill_to_party);
      formData.append('work_area', values?.work_area);
      formData.append(
        'vendor_id',
        user?.user_role_des == 'VENDOR' ? user?.vendor_id : values?.vendor_name
      );
      formData.append('gst_no', values?.gst_no);
      formData.append('invoice_no', values?.invoice_no);
      formData.append(
        'inv_date',
        values?.invoice_date
        // moment(values?.invoice_date).format('DD/MM/YYYY')
      );
      formData.append('inv_basic_value', values?.invoice_basic_value);
      formData.append('currency_id', values?.currency);
      formData.append('gst_type_id', values?.gst_type);
      formData.append('cgst_amount', values?.cgst || 0);
      formData.append('sgst_amount', values?.sgst || 0);
      formData.append('igst_amount', values?.igst || 0);
      formData.append('total_invoice_amount', total_invoice_amount);
      formData.append(
        'rendered_service_start_dt',
        values?.period_from_date ? values?.period_from_date : ''
        // moment(values?.period_from_date).format('DD/MM/YYYY')
      );
      formData.append(
        'rendered_service_end_dt',
        values?.period_to_date ? values?.period_to_date : ''
        // moment(values?.period_to_date).format('DD/MM/YYYY')
      );
      formData.append(
        'job_material_description',
        values?.job_materila_Description
      );
      formData.append(
        'status',
        invoicesData?.invoice_sts_des === 'ST REJECTED'
          ? 'st-rejected'
          : invoicesData?.invoice_sts_des === 'IVT REJECTED'
          ? 'ivt-rejected'
          : 'draft'
      );
      formData.append(
        'declaration_signed',
        localStorage.getItem('declaration_signed')
      );
      formData.append(
        'invoice_category_id',
        invoicesData?.invoice_category_id
          ? invoicesData?.invoice_category_id
          : localStorage.getItem('invoice_category_id')
      );

      dispatch(submitInvoice(formData)).then((response) => {
        // console.log(response);
        if (response?.payload?.success) {
          setIsSpin(false);
          setCurrent(current + 1);
          setInvoiceId(response?.payload?.data);
        } else {
          setCurrent(current);
          setIsSpin(false);
        }
      });
    } else if (current === 2) {
      const req_files = attchment_files?.filter(
        (file) =>
          (file.header_name === 'true' || file.header_name === 'yes') &&
          file.file_required === true
      );
      const req_file_list_keys = Object.keys(fileList).filter((key) => {
        const value = fileList[key];
        return (
          value !== '' &&
          value !== null &&
          value !== undefined &&
          value !== false &&
          value !== 0 &&
          !key.includes('other')
        );
      });

      if (attachmentsData?.length) {
        const apprvd_list = attachmentsData?.filter(
          (file) =>
            (file.attachment_status === 'st-reject' ||
              file.attachment_status === 'ivt-reject') &&
            !file.attachment_type_des.includes('OTHER') &&
            !file.attachment_type_des.includes('OTHER1') &&
            !file.attachment_type_des.includes('OTHER2') &&
            !file.attachment_type_des.includes('OTHER3') &&
            !file.attachment_type_des.includes('OTHER4') &&
            file.attachment_type_des !== 'RA ABSTRUCT'
        );
        if (apprvd_list.length !== req_file_list_keys.length) {
          message.error('Please attach all required files');
        } else {
          setCurrent(current + 1);
        }
      } else {
        if (req_files.length !== req_file_list_keys.length) {
          message.error('Please attach all required files');
        } else {
          setCurrent(current + 1);
        }
      }
    } else if (current === 3) {
      const ra_list = attachmentsData?.length
        ? attachmentsData.filter(
            (attachment) => attachment.attachment_type_des === 'RA ABSTRUCT'
          )
        : [];
      if (
        invoiceValues.invoice_type === 'B5EE433E-F36B-1410-8194-00685A84348C' ||
        invoiceValues.invoice_type === 'BDEE433E-F36B-1410-8194-00685A84348C' ||
        invoicesData?.invoice_type_des === 'Proforma Invoice' ||
        invoicesData?.invoice_type_des === 'Credit Note'
      ) {
        setCurrent(current + 1);
      } else {
        if (values?.ra_attachment?.fileList?.length) {
          setCurrent(current + 1);
        } else if (ra_list.length) {
          setCurrent(current + 1);
        } else {
          message.error('Please attach RA attachment');
        }
      }
    } else if (current === steps.length - 1) {
      setCurrent(current);
      setIsSpin(false);
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (current === 2) {
      // console.log('yes');
      setInvoiceValues({
        ...invoiceValues,
        invoice_attachment: '',
        certified_abstract_attachment: '',
        fim_reconciliation_statement_attachment: '',
        eway_bill_attachment: '',
        delivery_challan_attachment: '',
        lr_attachment: '',
        ld_certification_by_EIC_attachment: '',
        msme_attachment: '',
        approved_dealslip_attachment: '',
        others1_attachment: '',
        others2_attachment: '',
        others3_attachment: '',
        others4_attachment: '',
        deal_slip_attachment: '',
        ld_certification_attachment: '',
        no_claim_certification_attachment: '',
        abstract_Standard_attachment: ''
      });
      setFileList([]);
      form.resetFields();
      getInvoicedataById(invoiceId?.id);
      setCurrent(current - 1);
    }
    if (current === 3) {
      // console.log(current, 'current');
      // setRemoveAttachment(false);
      setInvoiceValues({
        ...invoiceValues,
        invoice_attachment: '',
        certified_abstract_attachment: '',
        fim_reconciliation_statement_attachment: '',
        eway_bill_attachment: '',
        delivery_challan_attachment: '',
        lr_attachment: '',
        ld_certification_by_EIC_attachment: '',
        msme_attachment: '',
        approved_dealslip_attachment: '',
        others_attachment: '',
        deal_slip_attachment: '',
        ld_certification_attachment: '',
        no_claim_certification_attachment: '',
        abstract_Standard_attachment: ''
      });
      form.resetFields();
      setFileList([]);
      setCurrent(current - 1);
    } else {
      setCurrent(current - 1);
    }
  };

  // console.log(current);
  console.log(
    'invoicesData.invoice_category_code',
    invoicesData.invoice_category_code
  );

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card className={styles.cardbody}>
          {invoicesData?.invoice_sts_des == 'ST Rejected' ||
          invoicesData?.invoice_sts_des == 'IVT Rejected' ? (
            <>
              <Row gutter={24}>
                <Col span={24} xl={20}>
                  <Meta title="#SubmitInvoice" className={styles.metaData} />
                </Col>
                <Col span={24} xl={4}>
                  <Popover
                    content={invoicesData?.comment}
                    // title="ST Reason"
                    title={
                      invoicesData?.invoice_sts_code === 'STR'
                        ? 'ST Reason'
                        : 'IVT Reason'
                    }
                    trigger="hover"
                    className={styles.popover}
                  >
                    <Button>
                      {' '}
                      <Space size={10}>
                        <img className={styles.prev} src={messageicon} />
                        Rejection Reason
                      </Space>
                    </Button>
                  </Popover>
                </Col>
              </Row>
              <br />
            </>
          ) : (
            <Meta
              title={
                invoicesData.invoice_category_code === 'SPPLY' ||
                localStorage.getItem('category') === 'SPPLY'
                  ? '#SubmitSupplyInvoice'
                  : '#SubmitServiceInvoice'
              }
              className={styles.metaData}
            />
          )}
          <Row gutter={24} className={styles.metaHeader}>
            {invoicesData?.invoice_sts_des == 'ST Rejected' ||
            invoicesData?.invoice_sts_des == 'IVT Rejected' ? (
              <Col span={28} xl={22}></Col>
            ) : (
              <Col span={28} xl={22}>
                <Steps
                  current={steps[current]?.dd}
                  items={items}
                  labelPlacement="vertical"
                >
                  {items.map((item, index) => {
                    return <Steps.Step key={index} title={item.title} />;
                  })}
                </Steps>
              </Col>
            )}
          </Row>

          <Row gutter={24} className={styles.Maincard}>
            <Col span={24} xl={4} className={styles.card1}>
              <Card>
                <Menu
                  selectedKeys={[`${current + 1}`]}
                  // mode="inline"
                  items={items1}
                  className={styles.menu}
                />
              </Card>
            </Col>

            <Col span={24} xl={20} className={styles.card2}>
              <Card>
                <Meta
                  title={
                    steps[current]?.header === 'Supply' ? (
                      <>
                        <span style={{ color: '#312e2e', marginLeft: '21px' }}>
                          {'Invoice Type'}
                        </span>
                        {' : '}
                        <span>
                          {invoicesData.invoice_category_code === 'SPPLY' ||
                          localStorage.getItem('category') === 'SPPLY'
                            ? 'Supply'
                            : 'Service'}
                        </span>
                      </>
                    ) : (
                      steps[current]?.header
                    )
                  }
                  className={styles.metaDataForSecondCard}
                />
                <br />
                <div>{steps[current]?.content}</div>
              </Card>
            </Col>

            {/*  */}
          </Row>
          <br />
          <FormItem className="text-right">
            <Space>
              {current > 0 && current < steps.length - 1 && (
                <CustomButton
                  htmlType="button"
                  // type="primary"
                  onClick={() => prev()}
                  className={styles.border_button}
                >
                  Previous
                </CustomButton>
              )}
              {current < steps.length - 1 &&
                (isSpin ? (
                  <CustomButton
                    // htmlType="submit"
                    type="primary"
                    ghost
                    // onClick={() => next()}
                    className={styles.border_button}
                  >
                    <Spin size="small" />
                  </CustomButton>
                ) : current === 2 ? (
                  <CustomButton
                    htmlType="submit"
                    // type={current === 1 ? 'primary1' : 'secondary1'}
                    // onClick={handleNext}
                    className={styles.border_button}
                  >
                    Next
                  </CustomButton>
                ) : (
                  <CustomButton
                    htmlType="submit"
                    // type={current === 1 ? 'primary1' : 'secondary1'}
                    // onClick={() => next()}
                    className={styles.border_button}
                  >
                    Next
                  </CustomButton>
                ))}
            </Space>
          </FormItem>
        </Card>
      </Form>
    </>
  );
};
export default InvoicePage;
