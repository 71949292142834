import React from 'react';
import Layout from '@/common/Layout';
// import IVTApprove from '@/pages/IVT/components';

const index = () => {
  return (
    <>
      <Layout sider={true}>
        {/* <IVTApprove /> */}
        Settings Page
      </Layout>
    </>
  );
};

export default index;
