import React, { useState } from 'react';
import { Form, DatePicker, Button, Card, Row, Col, Spin, Divider } from 'antd';
import styles from './index.module.less';
import { useDispatch } from 'react-redux';
import { Exported } from '../redux/slice';
import { Buffer } from 'buffer';

const index = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [exports, setExports] = useState(false);

  // const { Meta } = Card;

  const handleSubmit = (values) => {
    console.log('start_end_Date', values);
    const { start_dt, end_dt } = values;

    let payload = {
      start_dt,
      end_dt
    };
    onSubmit(payload);
  };

  const onSubmit = async (payload) => {
    setExports(true);
    await dispatch(Exported(payload)).then((blob) => {
      const buffer = Buffer.from(blob?.payload?.data);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // message.success('Report Downloaded successfully');
      form.resetFields();
    });
    setExports(false);
  };

  return (
    <>
      <Card className={styles.cardbody}>
        {/* <Meta title="#Reports" className={styles.metaData} /> */}
        <h1 className={styles.title}>Reports</h1>
        <Divider className="divider" />
        <Button type="primary" className={styles.reportbutton}>
          Standard Report
        </Button>
        <div className={styles.maincard}>
          <div className={styles.nestedCard1}>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              className={styles.form}
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="start_dt"
                    label="Start Date"
                    rules={[
                      {
                        required: true,
                        message: 'Select Start Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select Start Date"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} xl={12} span={12}>
                  <Form.Item
                    name="end_dt"
                    label="End Date"
                    rules={[
                      {
                        required: true,
                        message: 'Select End Date'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      placeholder="Select End Date"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {exports ? (
                <Spin className={styles.spinbutton} />
              ) : (
                <Form.Item className={styles.savebutton}>
                  <Button type="primary" htmlType="submit">
                    Export
                  </Button>
                </Form.Item>
              )}
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

export default index;
