import React from 'react';
import ResetPassswordPage from './components';

function index() {
  return (
    <>
      <ResetPassswordPage />
    </>
  );
}

export default index;
