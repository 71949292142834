import React from 'react';
import Layout from '@/common/Layout';
import NoticeBoardPage from '../NoticeBoard/components';

const index = () => {
  return (
    <>
      <Layout sider={true}>
        <NoticeBoardPage />
        {/* Notice Board Page Testing */}
      </Layout>
    </>
  );
};

export default index;
