import apiClient from 'utilities/apiClient';

export const invoicetypes = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoiceMaster}/${url}`, payload, true);
};

export const submitInvoices = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.invoice}/${url}`,
    payload,
    true,
    'file'
  );
};

export const invoiceData = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.getinvoicelist}/${payload.id}`,
    payload,
    true
  );
};

export const appovedInvoice = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.userDashboard}/${url}`,
    payload,
    true
  );
};

export const appovedEntity = (payload) => {
  return apiClient.post(`${apiClient.Urls.ivtApproved}`, payload, true);
};

export const companies = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};
export const projects = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};
export const projectsTypes = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoiceMaster}/${url}`, payload, true);
};

export const ivtuserDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.ivtusers}/${url}`, payload, true);
};

export const allremarks = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invoice}/${url}`, payload, true);
};

export const headerdata = (url, payload) => {
  // console.log('url', url);
  return apiClient.post(`${apiClient.Urls.invoice}/${url}`, payload, true, {
    responseType: 'arraybuffer'
  });
};
