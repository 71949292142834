import React from 'react';
import Layout from '@/common/Layout';
import CheckList from '../Checklist/components/ChecklistPage';

const index = () => {
  return (
    <div>
      <Layout sider={true}>
        <CheckList />
      </Layout>
    </div>
  );
};

export default index;
