import React, { useEffect, useState } from 'react';
import Layout from '@/common/Layout';
import ScrollViewPDF from './components';
import {
  getCompanyDetails,
  getProjectsDetails,
  getProjectTypeDetails,
  getIVTUserDetails
} from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const index = () => {
  const [search, setSearch] = useState('');
  const { companies, projects, IVTUsersList } = useSelector(
    (state) => state.companydetails
  );
  const dispatch = useDispatch();
  // const { invoicesData } = useSelector((state) => state.invoices);
  useEffect(() => {
    getProjects();
    getProjectTypes();
    getIVTUsers();
  }, []);

  useEffect(() => {
    getCompanies();
  }, [search]);
  const getCompanies = () => {
    const payload = { q: search };
    dispatch(getCompanyDetails(payload));
  };
  const getProjects = () => {
    const payload = {};
    dispatch(getProjectsDetails(payload));
  };
  const getProjectTypes = () => {
    const payload = {};
    dispatch(getProjectTypeDetails(payload));
  };

  const getIVTUsers = () => {
    const payload = {};
    dispatch(getIVTUserDetails(payload));
  };
  // console.log('IVTUsersList', IVTUsersList);

  return (
    <div>
      <Layout sider={true}>
        <ScrollViewPDF
          setSearch={setSearch}
          companies={companies}
          projects={projects}
          IVTUsersList={IVTUsersList}
        />
      </Layout>
    </div>
  );
};

export default index;
