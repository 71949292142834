import React from 'react';
import styles from './CustomTooltip.module.css'; // Import the CSS file
import { MessageOutlined } from '@ant-design/icons';
// CloseOutlined
import { Tooltip, Typography, Tag } from 'antd';
const { Text, Paragraph } = Typography;
// import styles from '../pages/CustomTooltip1/CustomTooltip.module.css';
const color = ['rgb(255, 251, 242)'];
const CustomTooltip = ({ teamName, comment, id }) => {
  // console.log('teamName', teamName);
  // console.log('comment', id);

  // const [visible, setVisible] = useState(false);
  // const [showContent, setShowContent] = useState(false);
  // const handleButtonClick = () => {
  //   setVisible(!visible); // Toggle visibility on button click
  // };
  // const handleButtonClick = () => {
  //   setShowContent(!showContent); // Toggle content visibility on button click
  // };

  const content = teamName ? (
    <div className={styles.tooltipContent}>
      <div className={styles.tooltipHeader}>
        <Text strong>From - &nbsp; </Text>
        <Text className={styles.teamName}>
          {teamName === 'SCROLL TEAM'
            ? 'ST'
            : teamName === 'INVOICE VALIDATION TEAM'
            ? 'IVT'
            : 'FCA'}
        </Text>
        {/* <CloseOutlined className={styles.closeIcon} /> */}
      </div>
      <div className={styles.tooltipBody}>
        <div className={styles.tooltipcomments}>
          <Text strong>Comments </Text>
          <Tag color="green" className={styles.latestTag}>
            Latest
          </Tag>
        </div>
        <Paragraph className={styles.commentText}>
          {comment || 'No comment available.'}
        </Paragraph>
        <a href={`viewpdf/${id}`} className={styles.viewAllLink}>
          View all
        </a>
      </div>
    </div>
  ) : (
    'No Comments'
  );

  return (
    <Tooltip
      style={{ color: '#fff' }}
      overlayInnerStyle={{
        width: '230px',
        backgroundColor: '#FFFBF2',
        color: '#333'
      }}
      color={color}
      className={styles.removepadding}
      // title={content}
      title={content ? content : 'No comments'}
      placement="right"
      overlayClassName={styles.customTooltip}
    >
      <MessageOutlined className={styles.commentIcon} />
    </Tooltip>
    //
    // <Tooltip
    //   visible={visible} // Control the tooltip visibility
    //   onVisibleChange={(visible) => setVisible(visible)} // Handle tooltip visibility changes
    //   overlayInnerStyle={{
    //     width: '100%',
    //     backgroundColor: '#FFFBF2',
    //     color: '#333'
    //   }}
    //   className={styles.removepadding}
    //   title={content || 'No comments'}
    //   placement="right"
    //   overlayClassName={styles.customTooltip}
    // >
    //   <MessageOutlined
    //     className={styles.commentIcon}
    //     onClick={handleButtonClick} // Handle click to show/hide the tooltip
    //   />
    // </Tooltip>
    //  //
    // <div>
    //   <MessageOutlined
    //     className={styles.commentIcon}
    //     onClick={handleButtonClick} // Handle click to show/hide the content
    //     style={{ cursor: 'pointer' }}
    //   />
    //   {showContent && (
    //     <div className={styles.inlineContent}>{content || 'No comments'}</div>
    //   )}
    // </div>
    // // //
    // <Popover
    //   style={{ color: '#fff' }}
    //   overlayInnerStyle={{
    //     width: '100%',
    //     backgroundColor: '#FFFBF2',
    //     color: '#333'
    //   }}
    //   className={styles.removepadding}
    //   content={content}
    //   trigger="click" // Trigger the popover on click
    //   placement="right"
    //   overlayClassName={styles.customPopover}
    // >
    //   <MessageOutlined
    //     className={styles.commentIcon}
    //     style={{ cursor: 'pointer' }}
    //   />
    // </Popover>
  );
};

export default CustomTooltip;
