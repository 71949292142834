import React from 'react';
import LocalStorage from './localStorage';
// import { MessageOutlined, CloseOutlined } from '@ant-design/icons';
// import { Tooltip, Typography, Tag } from 'antd';
// const { Text, Paragraph } = Typography;
// import styles from '../pages/CustomTooltip1/CustomTooltip.module.css';
import CustomTooltip from '../pages/CustomTooltip/CustomTooltip.js';

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('RIL_user') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};
// const CustomTooltip = ({ teamName }) => {
//   console.log('teamName', teamName);

//   const content = (
//     <div className={styles.tooltipContent}>
//       <div className={styles.tooltipHeader}>
//         <Text strong>From - </Text>
//         <Text className={styles.teamName}>{teamName}</Text>
//         <CloseOutlined className={styles.closeIcon} />
//       </div>
//       <div className={styles.tooltipBody}>
//         <Text strong>Comments </Text>
//         <Tag color="green" className={styles.latestTag}>
//           Latest
//         </Tag>
//         <Paragraph className={styles.commentText}>
//           Lorem Ipsum has been the industry`s standard dummy text ever since the
//           1500s, when an unknown printer took a galley of type and scrambled it
//           to make a type specimen book.
//         </Paragraph>
//         <a href="#" className={styles.viewAllLink}>
//           View all
//         </a>
//       </div>
//     </div>
//   );
//   return (
//     <Tooltip
//       title={content}
//       placement="right"
//       overlayClassName={styles.customTooltip}
//     >
//       <MessageOutlined className={styles.commentIcon} />
//     </Tooltip>
//   );
// };

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const blobToFile = (blob, fileName) => {
  blob.lastModified = new Date().getTime();
  blob.name = fileName;

  return blob;
};

export const removeDuplicatesFromArray = (arr, key) => {
  const uniqueArr = [];

  const unique = arr.filter((item) => {
    const isDuplicate = uniqueArr.includes(item[`${key}`]);

    if (!isDuplicate) {
      uniqueArr.push(item[`${key}`]);

      return true;
    }

    return false;
  });

  return unique;
};

export const getColumnsByRole = (
  radioFilterName,
  Tooltip,
  user,
  Checkbox,
  handleChangeNum,
  styles,
  moment,
  EyeFilled
) => {
  switch (radioFilterName) {
    case 'All':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Work Area',
          dataIndex: 'work_area',
          key: 'work_area'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },

        // {
        //   title: 'Invoice Base Amount',
        //   dataIndex: 'inv_basic_value',
        //   key: 'inv_basic_value'
        // },

        // {
        //   title: 'IVT Assigned',
        //   dataIndex: 'assigned_to_ivt',
        //   key: 'assigned_to_ivt'
        // },
        // {
        //   title: 'IVT Validated By',
        //   dataIndex: 'ivt_validated_by',
        //   key: 'ivt_validated_by'
        // },
        // {
        //   title: 'Entity Checked By',
        //   dataIndex: 'entity_checked_by',
        //   key: 'entity_checked_by'
        // },
        // {
        //   title: 'Downloaded',
        //   dataIndex: 'is_downloaded',
        //   key: 'is_downloaded'
        // },
        // {
        //   title: 'Downloaded Date',
        //   dataIndex: 'downloaded_on',
        //   key: 'downloaded_on',
        //   sorter: (a, b) =>
        //     moment(a[`downloaded_on`]).unix() -
        //     moment(b[`downloaded_on`]).unix(),
        //   render: (downloaded_on) => (
        //     <>
        //       <Tooltip
        //         title={
        //           downloaded_on === null
        //             ? ''
        //             : moment(downloaded_on).format('DD/MM/YY')
        //         }
        //         color={'white'}
        //         overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
        //         arrowPointAtCenter="false"
        //       >
        //         {downloaded_on === null
        //           ? ''
        //           : moment(downloaded_on).format('DD/MM/YY')}
        //       </Tooltip>
        //     </>
        //   )
        // },
        // {
        //   title: 'SN Shared Date',
        //   dataIndex: 'sn_shared_date',
        //   key: 'sn_shared_date',
        //   sorter: (a, b) =>
        //     moment(a[`sn_shared_date`]).unix() -
        //     moment(b[`sn_shared_date`]).unix(),
        //   render: (sn_shared_date) => (
        //     <>
        //       <Tooltip
        //         title={
        //           sn_shared_date === null
        //             ? ''
        //             : moment(sn_shared_date).format('DD/MM/YY')
        //         }
        //         color={'white'}
        //         overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
        //         arrowPointAtCenter="false"
        //       >
        //         {sn_shared_date === null
        //           ? ''
        //           : moment(sn_shared_date).format('DD/MM/YY')}
        //       </Tooltip>
        //     </>
        //   )
        // },
        // {
        //   title: 'FC&A Approved Date',
        //   dataIndex: 'management_approval_dt',
        //   key: 'management_approval_dt',
        //   sorter: (a, b) =>
        //     moment(a[`management_approval_dt`]).unix() -
        //     moment(b[`management_approval_dt`]).unix(),
        //   render: (management_approval_dt) => (
        //     <>
        //       <Tooltip
        //         title={
        //           management_approval_dt === null
        //             ? ''
        //             : moment(management_approval_dt).format('DD/MM/YY')
        //         }
        //         color={'white'}
        //         overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
        //         arrowPointAtCenter="false"
        //       >
        //         {management_approval_dt === null
        //           ? ''
        //           : moment(management_approval_dt).format('DD/MM/YY')}
        //       </Tooltip>
        //     </>
        //   )
        // },
        // {
        //   title: 'Payment Date',
        //   dataIndex: 'payment_date',
        //   key: 'payment_date',
        //   sorter: (a, b) =>
        //     moment(a[`payment_date`]).unix() - moment(b[`payment_date`]).unix(),
        //   render: (payment_date) => (
        //     <>
        //       <Tooltip
        //         title={
        //           payment_date === null
        //             ? ''
        //             : moment(payment_date).format('DD/MM/YY')
        //         }
        //         color={'white'}
        //         overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
        //         arrowPointAtCenter="false"
        //       >
        //         {payment_date === null
        //           ? ''
        //           : moment(payment_date).format('DD/MM/YY')}
        //       </Tooltip>
        //     </>
        //   )
        // },

        // {
        //   title: 'Vendor Name & Code',
        //   dataIndex: 'vendor_name (vendor_code)',
        //   key: 'vendor_name (vendor_code)',
        //   render: (text, record) => (
        //     <span>
        //       {record.vendor_name} ({record.vendor_code})
        //     </span>
        //   )
        // },

        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            // console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            // console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null &&
                  record.invoice_sts_des !== 'Paid' ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : record?.request_no !== null &&
                    (record.invoice_sts_des === 'PAID' ||
                      record.invoice_sts_des === 'Paid') ? (
                    <a href={`invoice-view/${record.id}`}>
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'Draft':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        // {
        //   title: 'Scroll No.',
        //   dataIndex: 'scroll_no',
        //   header: 'Scroll No.',
        //   key: 'scroll_no',
        //   width: 150
        // },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Work Area',
          dataIndex: 'work_area',
          key: 'work_area'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'ST Submitted':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        // {
        //   title: 'Scroll No.',
        //   dataIndex: 'scroll_no',
        //   header: 'Scroll No.',
        //   key: 'scroll_no',
        //   width: 150
        // },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Work Area',
          dataIndex: 'work_area',
          key: 'work_area'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        // {
        //   title: 'Invoice Base Amount',
        //   dataIndex: 'inv_basic_value',
        //   key: 'inv_basic_value'
        // },
        // {
        //   title: 'IVT Assigned to',
        //   dataIndex: 'assigned_to_ivt',
        //   key: 'assigned_to_ivt'
        // },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'ST Rejected':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        // {
        //   title: 'Scroll No.',
        //   dataIndex: 'scroll_no',
        //   header: 'Scroll No.',
        //   key: 'scroll_no',
        //   width: 150
        // },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Work Area',
          dataIndex: 'work_area',
          key: 'work_area'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        // {
        //   title: 'Invoice Base Amount',
        //   dataIndex: 'inv_basic_value',
        //   key: 'inv_basic_value'
        // },
        // {
        //   title: 'IVT Assigned to',
        //   dataIndex: 'assigned_to_ivt',
        //   key: 'assigned_to_ivt'
        // },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'SN Generated':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Work Area',
          dataIndex: 'work_area',
          key: 'work_area'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        // {
        //   title: 'Invoice Base Amount',
        //   dataIndex: 'inv_basic_value',
        //   key: 'inv_basic_value'
        // },
        {
          title: 'IVT Assigned',
          dataIndex: 'assigned_to_ivt',
          key: 'assigned_to_ivt'
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'SN Validated':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Project Name',
          dataIndex: 'project_name',
          key: 'project_name'
        },
        {
          title: 'Project Type',
          dataIndex: 'project_type_des',
          key: 'project_type_des'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        // {
        //   title: 'Invoice Base Amount',
        //   dataIndex: 'inv_basic_value',
        //   key: 'inv_basic_value'
        // },
        {
          title: 'IVT Assigned to',
          dataIndex: 'assigned_to_ivt',
          key: 'assigned_to_ivt'
        },
        {
          title: 'IVT Validated By',
          dataIndex: 'ivt_validated_by',
          key: 'ivt_validated_by'
        },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'IVT Rejected':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Project Name',
          dataIndex: 'project_name',
          key: 'project_name'
        },
        {
          title: 'Project Type',
          dataIndex: 'project_type_des',
          key: 'project_type_des'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        // {
        //   title: 'Invoice Base Amount',
        //   dataIndex: 'inv_basic_value',
        //   key: 'inv_basic_value'
        // },
        {
          title: 'IVT Assigned to',
          dataIndex: 'assigned_to_ivt',
          key: 'assigned_to_ivt'
        },
        {
          title: 'IVT Validated By',
          dataIndex: 'ivt_validated_by',
          key: 'ivt_validated_by'
        },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'IVT Hold':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Project Name',
          dataIndex: 'project_name',
          key: 'project_name'
        },
        {
          title: 'Project Type',
          dataIndex: 'project_type_des',
          key: 'project_type_des'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        // {
        //   title: 'Invoice Base Amount',
        //   dataIndex: 'inv_basic_value',
        //   key: 'inv_basic_value'
        // },
        {
          title: 'IVT Assigned to',
          dataIndex: 'assigned_to_ivt',
          key: 'assigned_to_ivt'
        },
        {
          title: 'IVT Validated By',
          dataIndex: 'ivt_validated_by',
          key: 'ivt_validated_by'
        },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'SN Entity Checked':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Project Name',
          dataIndex: 'project_name',
          key: 'project_name'
        },
        {
          title: 'Project Type',
          dataIndex: 'project_type_des',
          key: 'project_type_des'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        // {
        //   title: 'IVT Assigned to',
        //   dataIndex: 'assigned_to_ivt',
        //   key: 'assigned_to_ivt'
        // },
        {
          title: 'IVT Validated By',
          dataIndex: 'ivt_validated_by',
          key: 'ivt_validated_by'
        },
        {
          title: 'SN Entity Checked By',
          dataIndex: 'entity_checked_by',
          key: 'entity_checked_by'
        },
        {
          title: 'Downloaded',
          dataIndex: 'is_downloaded',
          key: 'is_downloaded',
          render: (is_downloaded) => (
            <Tooltip title={is_downloaded ? 'Downloaded' : 'Not Downloaded'}>
              {is_downloaded ? 'Yes' : 'No'}
            </Tooltip>
          )
        },
        {
          title: 'Downloaded Date',
          dataIndex: 'downloaded_on',
          key: 'downloaded_on',
          sorter: (a, b) =>
            moment(a[`downloaded_on`]).unix() -
            moment(b[`downloaded_on`]).unix(),
          render: (downloaded_on) => (
            <>
              <Tooltip
                title={
                  downloaded_on === null
                    ? ''
                    : moment(downloaded_on).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {downloaded_on === null
                  ? ''
                  : moment(downloaded_on).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'SN Shared':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Project Name',
          dataIndex: 'project_name',
          key: 'project_name'
        },
        {
          title: 'Project Type',
          dataIndex: 'project_type_des',
          key: 'project_type_des'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        {
          title: 'SN Shared Date',
          dataIndex: 'sn_shared_date',
          key: 'sn_shared_date',
          sorter: (a, b) =>
            moment(a[`sn_shared_date`]).unix() -
            moment(b[`sn_shared_date`]).unix(),
          render: (sn_shared_date) => (
            <>
              <Tooltip
                title={
                  sn_shared_date === null
                    ? ''
                    : moment(sn_shared_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {sn_shared_date === null
                  ? ''
                  : moment(sn_shared_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'FC&A Approved Date',
          dataIndex: 'management_approval_dt',
          key: 'management_approval_dt',
          sorter: (a, b) =>
            moment(a[`management_approval_dt`]).unix() -
            moment(b[`management_approval_dt`]).unix(),
          render: (management_approval_dt) => (
            <>
              <Tooltip
                title={
                  management_approval_dt === null
                    ? ''
                    : moment(management_approval_dt).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {management_approval_dt === null
                  ? ''
                  : moment(management_approval_dt).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        // {
        //   title: 'Downloaded',
        //   dataIndex: 'is_downloaded',
        //   key: 'is_downloaded'

        // },
        {
          title: 'Downloaded',
          dataIndex: 'is_downloaded',
          key: 'is_downloaded',
          render: (is_downloaded) => (
            <Tooltip title={is_downloaded ? 'Downloaded' : 'Not Downloaded'}>
              {is_downloaded ? 'Yes' : 'No'}
            </Tooltip>
          )
        },
        {
          title: 'Downloaded Date',
          dataIndex: 'downloaded_on',
          key: 'downloaded_on',
          sorter: (a, b) =>
            moment(a[`downloaded_on`]).unix() -
            moment(b[`downloaded_on`]).unix(),
          render: (downloaded_on) => (
            <>
              <Tooltip
                title={
                  downloaded_on === null
                    ? ''
                    : moment(downloaded_on).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {downloaded_on === null
                  ? ''
                  : moment(downloaded_on).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'SN Entity Checked By',
          dataIndex: 'entity_checked_by',
          key: 'entity_checked_by'
        },

        //
        // {
        //   title: 'Vendor Name & Code',
        //   dataIndex: 'vendor_name (vendor_code)',
        //   key: 'vendor_name (vendor_code)',
        //   render: (text, record) => (
        //     <span>
        //       {record.vendor_name} ({record.vendor_code})
        //     </span>
        //   )
        // },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'Paid':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Project Name',
          dataIndex: 'project_name',
          key: 'project_name'
        },
        {
          title: 'Project Type',
          dataIndex: 'project_type_des',
          key: 'project_type_des'
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        {
          title: 'Payment Date',
          dataIndex: 'payment_date',
          key: 'payment_date',
          sorter: (a, b) =>
            moment(a[`payment_date`]).unix() - moment(b[`payment_date`]).unix(),
          render: (payment_date) => (
            <>
              <Tooltip
                title={
                  payment_date === null
                    ? ''
                    : moment(payment_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {payment_date === null
                  ? ''
                  : moment(payment_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'SN Shared Date',
          dataIndex: 'sn_shared_date',
          key: 'sn_shared_date',
          sorter: (a, b) =>
            moment(a[`sn_shared_date`]).unix() -
            moment(b[`sn_shared_date`]).unix(),
          render: (sn_shared_date) => (
            <>
              <Tooltip
                title={
                  sn_shared_date === null
                    ? ''
                    : moment(sn_shared_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {sn_shared_date === null
                  ? ''
                  : moment(sn_shared_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'FC&A Approved Date',
          dataIndex: 'management_approval_dt',
          key: 'management_approval_dt',
          sorter: (a, b) =>
            moment(a[`management_approval_dt`]).unix() -
            moment(b[`management_approval_dt`]).unix(),
          render: (management_approval_dt) => (
            <>
              <Tooltip
                title={
                  management_approval_dt === null
                    ? ''
                    : moment(management_approval_dt).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {management_approval_dt === null
                  ? ''
                  : moment(management_approval_dt).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        // {
        //   title: 'Downloaded',
        //   dataIndex: 'is_downloaded',
        //   key: 'is_downloaded'
        // },
        {
          title: 'Downloaded',
          dataIndex: 'is_downloaded',
          key: 'is_downloaded',
          render: (is_downloaded) => (
            <Tooltip title={is_downloaded ? 'Downloaded' : 'Not Downloaded'}>
              {is_downloaded ? 'Yes' : 'No'}
            </Tooltip>
          )
        },
        {
          title: 'Downloaded Date',
          dataIndex: 'downloaded_on',
          key: 'downloaded_on',
          sorter: (a, b) =>
            moment(a[`downloaded_on`]).unix() -
            moment(b[`downloaded_on`]).unix(),
          render: (downloaded_on) => (
            <>
              <Tooltip
                title={
                  downloaded_on === null
                    ? ''
                    : moment(downloaded_on).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {downloaded_on === null
                  ? ''
                  : moment(downloaded_on).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'SN Entity Checked By',
          dataIndex: 'entity_checked_by',
          key: 'entity_checked_by'
        },
        {
          title: 'SAP Scroll No.',
          dataIndex: 'sap_scroll_number',
          key: 'sap_scroll_number'
        },
        {
          title: 'SAP Scroll Date',
          dataIndex: 'sap_scroll_dt',
          key: 'sap_scroll_dt',
          sorter: (a, b) =>
            moment(a[`sap_scroll_dt`]).unix() -
            moment(b[`sap_scroll_dt`]).unix(),
          render: (sap_scroll_dt) => (
            <>
              <Tooltip
                title={
                  sap_scroll_dt === null
                    ? ''
                    : moment(sap_scroll_dt).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {sap_scroll_dt === null
                  ? ''
                  : moment(sap_scroll_dt).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Payment Advice Number',
          dataIndex: 'payment_advice_number',
          key: 'payment_advice_number'
        },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    <a href={`invoice-view/${record.id}`}>
                      {/* <a href={`viewpdf/${record.id}`}> */}
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
    case 'FC&A Approved':
      return [
        {
          title: 'Request No.',
          dataIndex: 'request_no',
          key: 'request_no',
          width:
            radioFilterName == 'SN ENTITY CHECKED' ||
            radioFilterName == 'SN Entity Checked' ||
            radioFilterName == 'All' ||
            radioFilterName == '' ||
            radioFilterName == 'Paid' ||
            radioFilterName == 'ST Rejected' ||
            radioFilterName == 'ST Submitted' ||
            radioFilterName == 'SN Generated' ||
            radioFilterName == 'SN Validated' ||
            radioFilterName == 'SN Shared' ||
            radioFilterName == 'ST Entity Checked' ||
            radioFilterName == 'IVT Hold' ||
            radioFilterName == 'IVT Rejected'
              ? 210
              : 150,
          // ellipsis: true,
          // sorter: (a, b) => a?.request_no?.localeCompare(b?.request_no),
          render: (request_no, record, index) => {
            return (
              <>
                <Tooltip
                  title={request_no}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* || user?.user_role_des == 'SURRGATE USER' */}
                  {user?.user_role_des == 'VENDOR' &&
                  (record?.invoice_sts_des == 'DRAFT' ||
                    record?.invoice_sts_des == 'ST REJECTED' ||
                    record?.invoice_sts_des == 'Draft' ||
                    record?.invoice_sts_des == 'ST Rejected' ||
                    record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SURRGATE USER' &&
                    (record?.invoice_sts_des == 'DRAFT' ||
                      record?.invoice_sts_des == 'ST REJECTED' ||
                      record?.invoice_sts_des == 'Draft' ||
                      record?.invoice_sts_des == 'ST Rejected' ||
                      record?.invoice_sts_des == 'IVT Rejected') ? (
                    //  <a href={`invoice-view/${record.id}`}></a>
                    <a href={`invoices/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'SCROLL TEAM' &&
                    (record?.invoice_sts_des == 'ST SUBMITTED' ||
                      record?.invoice_sts_des == 'ST Submitted') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'INVOICE VALIDATION TEAM' &&
                    (record?.invoice_sts_des == 'SN Generated' ||
                      record?.invoice_sts_des == 'IVT Hold') ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Validated' ? (
                    <a href={`scrollview/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'SN Shared' ? (
                    <a href={`approveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : user?.user_role_des == 'FINANCE AND ACCOUNTS TEAM' &&
                    record?.invoice_sts_des == 'FC&A Approved' ? (
                    <a href={`fcaapproveInvoice/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                    </a>
                  ) : request_no === null ? (
                    'Not generated'
                  ) : radioFilterName == 'SN ENTITY CHECKED' ||
                    (radioFilterName == 'SN Entity Checked' &&
                      user?.user_role_des == 'INVOICE VALIDATION TEAM') ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleChangeNum(e, record?.id)}
                      />{' '}
                      {request_no}
                    </>
                  ) : (
                    request_no
                  )}
                </Tooltip>
                {index === 0 && (
                  <span
                    className={
                      index === 0 ? styles.defaultStyle : styles.anotherStyle
                    }
                  ></span>
                )}
              </>
            );
          }
        },
        {
          title: 'Scroll No.',
          dataIndex: 'scroll_no',
          header: 'Scroll No.',
          key: 'scroll_no',
          width: 150
        },
        {
          title: 'Company Name',
          dataIndex: 'bill_to_party',
          key: 'bill_to_party'
          // width: 150
        },
        {
          title: 'Deal Slip No.',
          dataIndex: 'deal_slip',
          key: 'deal_slip'
        },
        {
          title: 'Project Name',
          dataIndex: 'project_name',
          key: 'project_name'
        },
        {
          title: 'Project Type',
          dataIndex: 'project_type_des',
          key: 'project_type_des'
        },
        {
          title: 'Vendor Name',
          dataIndex: 'vendor_name',
          key: 'vendor_name'
        },
        {
          title: 'Vendor Code',
          dataIndex: 'vendor_code',
          key: 'vendor_code'
        },
        {
          title: 'Currency',
          dataIndex: 'currency_code',
          key: 'currency_code'
        },
        {
          title: 'Invoice Type',
          dataIndex: 'invoice_type_des',
          key: 'invoice_type_des'
        },
        {
          title: 'Invoice No.',
          dataIndex: 'invoice_no',
          key: 'invoice_no'
        },
        {
          title: 'Invoice Date',
          dataIndex: 'inv_date',
          key: 'inv_date',
          sorter: (a, b) =>
            moment(a[`inv_date`]).unix() - moment(b[`inv_date`]).unix(),
          render: (inv_date) => (
            <>
              <Tooltip
                title={
                  inv_date === null
                    ? 'Not generated'
                    : moment(inv_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {inv_date === null
                  ? 'Not generated'
                  : moment(inv_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        // {
        //   title: 'Invoice Base Amount',
        //   dataIndex: 'inv_basic_value',
        //   key: 'inv_basic_value'
        // },
        {
          title: 'Invoice Amount (with GST)',
          dataIndex: 'invoice_amount',
          key: 'invoice_amount'
        },

        {
          title: 'IVT Assigned to',
          dataIndex: 'assigned_to_ivt',
          key: 'assigned_to_ivt'
        },
        {
          title: 'IVT Validated By',
          dataIndex: 'ivt_validated_by',
          key: 'ivt_validated_by'
        },
        {
          title: 'Entity Checked By',
          dataIndex: 'entity_checked_by',
          key: 'entity_checked_by'
        },
        // {
        //   title: 'Downloaded',
        //   dataIndex: 'is_downloaded',
        //   key: 'is_downloaded'
        // },
        {
          title: 'Downloaded',
          dataIndex: 'is_downloaded',
          key: 'is_downloaded',
          render: (is_downloaded) => (
            <Tooltip title={is_downloaded ? 'Downloaded' : 'Not Downloaded'}>
              {is_downloaded ? 'Yes' : 'No'}
            </Tooltip>
          )
        },
        {
          title: 'Downloaded Date',
          dataIndex: 'downloaded_on',
          key: 'downloaded_on',
          sorter: (a, b) =>
            moment(a[`downloaded_on`]).unix() -
            moment(b[`downloaded_on`]).unix(),
          render: (downloaded_on) => (
            <>
              <Tooltip
                title={
                  downloaded_on === null
                    ? ''
                    : moment(downloaded_on).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {downloaded_on === null
                  ? ''
                  : moment(downloaded_on).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'SN Shared Date',
          dataIndex: 'sn_shared_date',
          key: 'sn_shared_date',
          sorter: (a, b) =>
            moment(a[`sn_shared_date`]).unix() -
            moment(b[`sn_shared_date`]).unix(),
          render: (sn_shared_date) => (
            <>
              <Tooltip
                title={
                  sn_shared_date === null
                    ? ''
                    : moment(sn_shared_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {sn_shared_date === null
                  ? ''
                  : moment(sn_shared_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'FC&A Approved Date',
          dataIndex: 'management_approval_dt',
          key: 'management_approval_dt',
          sorter: (a, b) =>
            moment(a[`management_approval_dt`]).unix() -
            moment(b[`management_approval_dt`]).unix(),
          render: (management_approval_dt) => (
            <>
              <Tooltip
                title={
                  management_approval_dt === null
                    ? ''
                    : moment(management_approval_dt).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {management_approval_dt === null
                  ? ''
                  : moment(management_approval_dt).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Payment Date',
          dataIndex: 'payment_date',
          key: 'payment_date',
          sorter: (a, b) =>
            moment(a[`payment_date`]).unix() - moment(b[`payment_date`]).unix(),
          render: (payment_date) => (
            <>
              <Tooltip
                title={
                  payment_date === null
                    ? ''
                    : moment(payment_date).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {payment_date === null
                  ? ''
                  : moment(payment_date).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },

        // {
        //   title: 'Vendor Name & Code',
        //   dataIndex: 'vendor_name (vendor_code)',
        //   key: 'vendor_name (vendor_code)',
        //   render: (text, record) => (
        //     <span>
        //       {record.vendor_name} ({record.vendor_code})
        //     </span>
        //   )
        // },
        {
          title: 'Status',
          dataIndex: 'invoice_sts_des',
          key: 'invoice_sts_des',
          width: 150,
          // ellipsis: true,
          // textWrap: 'word-break',
          sorter: (a, b) =>
            (a?.invoice_sts_des || '').localeCompare(b?.invoice_sts_des || ''),
          render: (text, record) => {
            const status = record?.invoice_sts_des || 'NA';
            const color =
              status == 'ST GENERATED' || status == 'ST Generated'
                ? '#007bff'
                : status == 'ST SUBMITTED' || status == 'ST Submitted'
                ? '#9747FF'
                : status == 'ST REJECTED' || status == 'ST Rejected'
                ? '#E3486C'
                : status == 'SN VALIDATED' || status == 'SN Validated'
                ? '#344054'
                : status == 'IVT HOLD' || status == 'IVT Hold'
                ? '#FF5E00'
                : status == 'SN ENTITY CHECKED' || status == 'SN Entity Checked'
                ? '#15B0E0'
                : status == 'SN SHARED' || status == 'SN Shared'
                ? '#FF00FF'
                : status == 'PAID' || status == 'Paid'
                ? '#3C9C51'
                : status == 'DRAFT' || status == 'Draft'
                ? '#6c757d'
                : status == 'SN GENERATED' || status == 'SN Generated'
                ? '#2F80ED'
                : status == 'IVT REJECTED' || status == 'IVT Rejected'
                ? '#D92D20'
                : 'green';
            return (
              <span style={{ color, fontSize: '11px !important' }}>
                {status}
              </span>
            );
          }
        },
        {
          title: 'Last Updated Date',
          dataIndex: 'updated_at',
          key: 'updated_at',
          sorter: (a, b) =>
            moment(a[`updated_at`]).unix() - moment(b[`updated_at`]).unix(),
          render: (updated_at) => (
            <>
              <Tooltip
                title={
                  updated_at === null
                    ? ''
                    : moment(updated_at).format('DD/MM/YY')
                }
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {updated_at === null
                  ? ''
                  : moment(updated_at).format('DD/MM/YY')}
              </Tooltip>
            </>
          )
        },
        {
          title: 'Comments',
          dataIndex: 'comment',
          key: 'comment',
          render: (comments, record) => {
            // Check if there are any comments
            // console.log('comments', record.length);
            // const commentLength = Array.isArray(comments) ? comments.length : 0;
            console.log('Number of comments:', comments?.length);
            // if (comments?.length > 0) {
            console.log('comments', record);
            return (
              <div>
                {/* {record.map((comment, index) => ( */}
                <CustomTooltip
                  // key={index}
                  teamName={record.commented_user_role}
                  comment={record.comment}
                  id={record.id}
                />
                {/* ))} */}
              </div>
            );
            // } else {
            //   // If no comments, render a default message or return null
            //   return <span>No remarks available</span>;
            // }
          }
        },
        {
          title: 'View',
          dataIndex: 'View',
          key: 'View',
          className: styles.highlightedColumn,
          render: (request_no, record) => {
            return (
              <>
                <Tooltip
                  title={
                    record?.request_no === null
                      ? 'Not Generated'
                      : record?.request_no
                  }
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {/* (user?.user_role_des == 'VENDOR' && record.status == 'ST SUBMITTED') */}
                  {record?.request_no !== null ? (
                    // <a href={`invoice-view/${record.id}`}>
                    <a href={`viewpdf/${record.id}`}>
                      {request_no === null ? 'Not generated' : request_no}
                      <EyeFilled className={`${styles.edit_icon}`} />
                    </a>
                  ) : (
                    <EyeFilled />
                  )}
                </Tooltip>
              </>
            );
          }
        }
      ];
  }
};
