import React from 'react';
import Layout from '@/common/Layout';
import FCAApprove from '@/pages/FCAApprove/components';

const index = () => {
  return (
    <>
      <Layout sider={true}>
        <FCAApprove />
        {/* IVT Single Invoice Approve Page testing */}
      </Layout>
    </>
  );
};

export default index;
