import React, { useState } from 'react';
import { Row, Col, Upload, Button, message } from 'antd';
import styles from '../../index.module.less';
import FormItem from '@/common/FormItem';
import { PaperClipOutlined } from '@ant-design/icons';
import { removeDuplicatesFromArray } from 'utilities/helpers';
// import { TextArea } from 'semantic-ui-react';

const attachments = ({
  invoiceValues,
  setInvoiceValues,
  setFileList,
  fileList,
  attchment_files
  // handleUploadChange,
  // handleRemove
  // fileData
  // attachmentsData
  // removeAttachment
}) => {
  // const [form] = Form.useForm();
  // const [fileList, setFileList] = useState([]);

  const MAX_FILE_SIZE_MB = 3 * 1024 * 1024; // 3MB in bytes
  const [errorFiles, setErrorFiles] = useState(new Set());
  const handleOnChangePdfUpload = (
    fileName,
    { fileList: onChangeFileList }
  ) => {
    const newErrorFiles = new Set(errorFiles);

    const validFiles = onChangeFileList.filter((file) => {
      if (file.size > MAX_FILE_SIZE_MB) {
        if (!newErrorFiles.has(file.name)) {
          message.error(
            `${file.name} exceeds the maximum size of 3MB and was not added.`
          );
          newErrorFiles.add(file.name);
          // setErrorFiles(newErrorFiles); // Update error files state
        }
        return false;
      }
      return true;
    });
    // let files = fileList.concat(onChangeFileList.map((o) => o.originFileObj));
    // let files = fileList.concat(validFiles.map((o) => o.originFileObj));

    // If file list is empty and the file is required, show an error message
    if (validFiles.length === 0) {
      message.error(`${fileName} is required and must be uploaded.`);
      return;
    }

    // setFileList(removeDuplicatesFromArray(files, 'name'));
    setFileList((prevState) => ({
      ...prevState,
      [fileName]: removeDuplicatesFromArray(
        [
          ...(prevState[fileName] || []),
          ...validFiles.map((file) => file.originFileObj)
        ],
        'name'
      )
    }));
    // };
  };

  // const handleRemove = (file) => {
  //   // const uploadedFiles = [...fileList];
  //   const updatedFileList = fileList.some((f) => f.uid !== file.uid);

  //   console.log('handleRemove', file, fileList, updatedFileList);
  // };

  const handleRemove = (file) => {
    // Clone the current file list from state
    // const uploadedFiles = [...fileList[attachmentType]];
    // console.log('fileList76', fileList);

    // // Filter out the file that is being removed based on UID
    // const updatedFileList = uploadedFiles.filter((f) => f.uid !== file.uid);

    // // Update the state with the new file list for the specified attachment type
    // // setFileList((prevState) => ({
    // //   ...prevState,
    // //   [attachmentType]: updatedFileList
    // // }));

    // setFileList((prevState) => {
    //   // If the updated file list is empty, remove the key from the state
    //   if (updatedFileList.length === 0) {
    //     const { [attachmentType]: removed, ...rest } = prevState;
    //     return rest;
    //   }

    //   // Otherwise, just update the file list for that attachment type
    //   return {
    //     ...prevState,
    //     [attachmentType]: updatedFileList
    //   };
    // });

    // // Optional: You can log or handle other side-effects here
    // console.log('File removed:', file);
    // console.log('Updated file list:', updatedFileList);
    // console.log('removedKey', removed);

    // if (Array.isArray(fileList)) {
    // const objarr = Object.values(fileList);
    // console.log('objarr', objarr, file);

    // const updatedFileList = objarr.filter((f) => f[0].uid !== file.uid);
    // console.log('updatedFileList', updatedFileList);
    // setFileList(updatedFileList);

    // message.info(`${file.name} file removed.`);
    // }

    // const filerobj = Object.entries(fileList).reduce((acc, [key, value]) => {
    //   console.log('value', value, file, key);

    //   if (value[0].uid !== file.uid) {
    //     acc[key] = value;
    //   }
    //   if (value[0].uid === file.uid) {
    //     form.setFieldsValue({ key: '' });
    //   }
    //   return acc;
    // }, {});
    // setFileList(filerobj);
    // form.setFieldsValue({ files: filerobj });

    // console.log('filerobj', filerobj);

    // Remove the file from the fileList
    const updatedFileList = Object.entries(fileList).reduce(
      (acc, [key, value]) => {
        // Filter out the file with the matching uid
        const filteredFiles = value?.filter((f) => f.uid !== file.uid);

        // If there are files left for the key, add them to the accumulator
        if (filteredFiles?.length > 0) {
          acc[key] = filteredFiles;
        } else {
          // If no files left for this key, set the value to undefined in the accumulator
          acc[key] = undefined;
        }

        return acc;
      },
      {}
    );

    // Update the fileList state
    setFileList(updatedFileList);
    setInvoiceValues({
      ...invoiceValues,
      updatedFileList: ''
    });

    // Optional: Clear the form field associated with the removed file
  };

  console.log('invoiceValues', fileList);
  // console.log('attachmentsData', attachmentsData);
  console.log('attchment_files', attchment_files);

  const handleBeforeUpload = (file) => {
    if (file.size > MAX_FILE_SIZE_MB) {
      if (!errorFiles.has(file.name)) {
        message.error(`${file.name} exceeds the maximum size allowed is 3MB.`);
        setErrorFiles((prev) => new Set([...prev, file.name])); // Update error files state
      }
      return Upload.LIST_IGNORE; // Prevent file from being added to the upload list
    }
    return true;
  };

  return (
    // <Form form={form} layout="vertical">
    <Row gutter={24}>
      {/* Rohit Code */}

      {attchment_files?.map((item, index) => {
        const fileRules =
          // item?.file_required &&
          item?.status === 'st-approve' || item?.status === 'ivt-approve'
            ? [{ required: false, message: 'Please attach a file' }]
            : item?.file_required
            ? [{ required: true, message: 'Please attach a file' }]
            : [];
        // Function to get file rules based on the file status
        console.log('item?.file_required', item?.file_required);

        // const getFileRules = (item) => {
        //   if (item?.status === 'st-approve' || item?.status === 'ivt-approve') {
        //     return [{ required: false, message: 'Please attach a file' }];
        //   }
        //   if (item?.file_required) {
        //     return [{ required: true, message: 'Please attach a file' }];
        //   }
        //   return [];
        // };

        const isDisabled =
          item.status === 'st-approve' ||
          item.status === 'ivt-approve' ||
          false;
        // const isDisabled = status === 'approve' || item.disabled;
        const fileName =
          item.status === 'st-approve' || item.status === 'ivt-approve'
            ? item.name
            : '';
        // console.log('fileName', attchment_files);
        // console.log('item?.header_name', item?.header_name);
        return item?.header_name === 'yes' || item?.header_name === 'true' ? (
          <Col xs={24} xl={8} span={8} className={styles.upload} key={index}>
            <FormItem
              label={item?.label}
              name={item?.file_name}
              rules={fileRules}
              // rules={getFileRules(item)}
              // rules={[{ required: true, message: 'Please attach a file' }]}
              className={styles.customLable}
            >
              {fileName ? (
                // <p> {fileName} </p>
                <Button
                  icon={<PaperClipOutlined />}
                  className={styles.uploaded_button}
                  disabled={isDisabled}
                >
                  {fileName}
                </Button>
              ) : (
                <Upload
                  accept=".pdf"
                  // fileList={item?.fileList}
                  fileList={item?.file}
                  className={`avatar-uploader ${styles.upload}`}
                  // onChange={handleOnChangePdfUpload}
                  onChange={(info) =>
                    handleOnChangePdfUpload(item.file_name, info)
                  }
                  // onRemove={(info) => handleRemove(item.file_name, info)}
                  // onRemove={handleRemove}
                  onRemove={(file) => handleRemove(file, 'invoice_attachment')}
                  beforeUpload={handleBeforeUpload}
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                  // multiple={true}
                  // showUploadList={true}
                  showUploadList={{ showRemoveIcon: true }}
                  // showUploadList={{
                  //   showRemoveIcon: !item?.disabled, // Hide remove icon if disabled
                  //   showPreviewIcon: true,
                  //   showDownloadIcon: true // Allow downloading files
                  // }}
                >
                  <Button
                    icon={<PaperClipOutlined />}
                    className={styles.upload_button}
                    disabled={isDisabled}
                  >
                    {/* Attach File */}
                    {/* {isDisabled ? 'File Attached' : 'Attach File'} */}
                    {isDisabled === 'false' ? 'Re-upload File' : 'Attach File'}
                  </Button>
                </Upload>
              )}
              {/* {isDisabled && (
                <div className={styles.fileName}>{item?.file_name}</div>
              )} */}
            </FormItem>
          </Col>
        ) : (
          ''
        );
      })}
    </Row>
    // </Form>
  );
};
export default attachments;
