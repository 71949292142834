import React, { useState } from 'react';
import { Col, Row, Checkbox } from 'antd';
import FormItem from 'common/FormItem';
import CustomButton from '@/common/CustomButton';
import styles from '../ChecklistPage/index.module.less';
import { history } from '@/app/history';

export default function ChecklistPage() {
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = (event) => {
    console.log('Checkbox clicked:', event.target.checked);
    setIsChecked(event.target.checked);
    event.target.checked
      ? localStorage.setItem('declaration_signed', true)
      : localStorage.setItem('declaration_signed', false);
  };
  // console.log(isChecked);
  const pageChange = () => {
    history.push('/invoices');
  };

  const pageCancel = () => {
    history.push('/dashboard');
  };

  const data = [
    {
      id: 1,
      name: '1. Make sure that the only EIC certified Invoice is submitted.'
    },
    { id: 2, name: '2. Liquidity damage (LD) must be confirmed by EIC.' },
    {
      id: 3,
      name: '3. Deal Slip / Order number, Invoice number (Invoice number not exceeding sixteen characters)., Date of Invoice is mandatory to mention on invoices.'
    },
    {
      id: 4,
      name: '4. Invoice & Bill abstract etc. should be signed by authorized person of the Vendors.'
    },
    { id: 5, name: '5. Billing address should be as per the Deal Slip.' },
    {
      id: 6,
      name: '6. HSN/SAC code, GSTN No should be mentioned on the Invoice.'
    },
    {
      id: 7,
      name: '7. Closer documents (No Claim and FIM reconciliation) has to be submitted along with the Final Bill.'
    },
    { id: 8, name: '8. Ensure that EIC certified abstract copy is attached.' },
    {
      id: 9,
      name: '9. Ensure that no additional quantity is claimed over the deal slip quantity.'
    },
    {
      id: 10,
      name: '10. Ensure submission of PBG/SBLC/ as per Deal Slip / Order terms.'
    },
    {
      id: 11,
      name: '11. Ensure to deduct applicable Milestone for Retention Hold from Abstract as per deal slip terms.'
    },
    {
      id: 12,
      name: '12. Soft copy of the abstract Excel sheet as per standard format has been attached in portal.'
    },
    { id: 13, name: '13. Ensure to attached latest Deal Slip.' },
    {
      id: 14,
      name: '14. Ensure to attached Credit note in case Quantity is rejected, damaged, replacement, etc. certified by EIC.'
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.cardbody}>
        <h2>Checklist</h2>
        <ul>
          {data.map((eachdata) => (
            <li key={eachdata.id} style={{ listStyleType: 'none' }}>
              <p> {eachdata.name}</p>
            </li>
          ))}
        </ul>

        <Row>
          <Col
            span={24}
            className={styles.checkbox}
            checked={isChecked}
            onChange={handleOnChange}
          >
            <Checkbox
              className="sfprotext-medium font-12"
              // onChange={(e) => handleOnChange(e)}
            >
              I declare that the bill is complete as per all the above check
              points. Invoice will be rejected if above applicable points are
              not complied.
            </Checkbox>
          </Col>
        </Row>
      </div>
      <FormItem className={styles.sectionfooter}>
        <CustomButton
          // block
          // type="primary"
          htmlType="submit"
          className={styles.submit_button}
          disabled={!isChecked}
          onClick={pageChange}
        >
          Submit
        </CustomButton>
        <CustomButton
          // block
          // type="secondary"
          htmlType="submit"
          className={styles.cancel_button}
          onClick={pageCancel}
        >
          Cancel
        </CustomButton>
      </FormItem>
    </div>
  );
}
