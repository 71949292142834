import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from './components/LoginPage';
import { login } from './redux/slice';

const Login = () => {
  const { loading } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(login(payload));
  };

  return (
    <>
      <LoginPage loading={loading} onSubmit={onSubmit} />
    </>
  );
};

export default Login;
