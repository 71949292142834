import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Select,
  DatePicker,
  Input,
  Space,
  Popover,
  Tooltip,
  // Modal,
  // Button,
  // Form,
  // message,
  Alert,
  notification
} from 'antd';
import styles from '../../index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import { InfoCircleOutlined } from '@ant-design/icons';
// import { CloseOutlined } from '@ant-design/icons';
// CloseOutlined
import CustomModal from '@/common/CustomModal';
import { history } from '@/app/history';
import moment from 'moment';
// import '../../DropdownWithCustomNotification.module.css';

const header = ({ invoicetypes, invoiceValues, invoicesData }) => {
  console.log(invoiceValues, 'invoiceValues');
  console.log(invoicesData, 'invoicesData');
  const [eICData, setEICData] = useState(invoiceValues?.certified_by_eic);
  const [finalData, setFinalData] = useState(invoiceValues?.final_bill);
  // const [noClaim, setNoClaim] = useState(invoiceValues?.no_claim_certificate);
  const [msmeData, setMsmeData] = useState(invoiceValues?.msme);
  const [ismodal, setIsmodal] = useState(false);
  // const [isldeic, setIsldeic] = useState(false);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState(
    invoiceValues?.invoice_type
  );
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // const [selectedValue, setSelectedValue] = useState('');
  // const [showNotification, setShowNotification] = useState(true);

  const handleEIC = (e) => {
    setEICData(e);
    console.log(e);
    e === 'no' ? setIsmodal(true) : setIsmodal(false);
  };

  const handleLDCerteic = (e) => {
    // setIsldeic(e);
    // setIsldeic(e === 'yes');
    // if (e === 'yes') {
    //   setIsModalVisible(true);
    // }
    if (e === 'yes') {
      notification.info({
        // message: 'Certification Required',
        description: 'Certification must be attached.',
        duration: 5,
        // placement: 'bottomRight',
        style: {
          position: 'fixed',
          right: '25px',
          top: '80%',
          transform: 'translateY(-50%)'
        }
      });
    } else {
      // setShowAlert(false);
      // message.info('No certification needed.');
      notification.warning({
        // message: 'Information',
        description: 'Applicable LD will be charged.',
        duration: 3,
        // placement: 'bottomRight',
        style: {
          position: 'fixed',
          right: '25px',
          top: '80%',
          transform: 'translateY(-50%)'
        }
      });
    }
    console.log(e);
  };

  const handleFinalBill = (e) => {
    setFinalData(e);
  };

  // const handleNoClaim = (e) => {
  // setNoClaim(e);
  // };

  const handleMsme = (e) => {
    setMsmeData(e);
  };

  const handleInvoiceTypeChange = (e) => {
    setSelectedInvoiceType(e);
  };

  const handleClose = () => {
    setIsmodal(false);
    history.push('/dashboard');
  };
  useEffect(() => {
    setEICData(
      invoicesData?.certified_by_eic ||
        invoiceValues?.certified_by_eic === 'yes'
        ? 'yes'
        : 'no'
    );
    setFinalData(
      invoicesData?.final_bill || invoiceValues?.final_bill === 'yes'
        ? 'yes'
        : 'no'
    );
    // setNoClaim(invoicesData?.no_claim_certificate ? 'yes' : '');
    setMsmeData(
      invoicesData?.msme || invoiceValues?.msme === 'yes' ? 'yes' : 'no'
    );

    const invoiceTypeId1 = 'B4EE433E-F36B-1410-8194-00685A84348C';
    const invoiceTypeId2 = 'B5EE433E-F36B-1410-8194-00685A84348C';
    setSelectedInvoiceType(
      // invoicesData?.invoice_type_id ||
      //   invoiceValues?.invoice_type ===
      //     'B4EE433E-F36B-1410-8194-00685A84348C' ||
      //   invoicesData?.invoice_type_id ||
      //   invoiceValues?.invoice_type === 'B5EE433E-F36B-1410-8194-00685A84348C'
      //   ? 'yes'
      //   : 'no'

      // invoiceValues?.invoice_type ===
      //   'B4EE433E-F36B-1410-8194-00685A84348C'
      //    ||
      // 'B5EE433E-F36B-1410-8194-00685A84348C'
      // ? 'yes'
      // : 'no'
      //   || invoicesData?.invoice_type_id) ||
      // invoiceValues?.invoice_type === 'B5EE433E-F36B-1410-8194-00685A84348C'
      // ? 'yes'
      // : 'no'

      invoicesData?.invoice_type_id === invoiceTypeId1 ||
        invoicesData?.invoice_type_id === invoiceTypeId2 ||
        invoiceValues?.invoice_type === invoiceTypeId1 ||
        invoiceValues?.invoice_type === invoiceTypeId2
        ? 'yes'
        : 'no'
    );
  }, [invoicesData]);

  // console.log(invoicesData, invoiceValues, 'eicData');
  console.log('setSelectedInvoiceType', selectedInvoiceType);

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  // const content = (
  //   <div>
  //     <p>Certification must be attached.</p>
  //     <p>Additional instructions or information can go here.</p>
  //   </div>
  // );

  // const handleChange = (value) => {
  //   setSelectedValue(value);
  //   setShowNotification(true); // Show notification on selection
  // };

  // const handleCloseNotification = () => {
  //   setShowNotification(false); // Hide notification on close
  // };

  return (
    // <Form form={form} layout="vertical ">
    <>
      <Row gutter={24}>
        <Col xs={24} xl={eICData === 'yes' ? 6 : 8} span={8}>
          <FormItem
            // label="Certified by EIC"
            label={
              <Space>
                <span>Certified by EIC</span>
                <Tooltip
                  title="Enter the name of the EIC as indicated on the Deal Slip. Specify the date from when the EIC confirmed receipt of material and job completion. Vendors can submit invoices only within 60 days (two months) of this date."
                  placement="topLeft"
                  // overlayClassName={styles.customTooltip}
                  className={styles.customTooltip}
                >
                  <InfoCircleOutlined style={{ color: 'red' }} />
                </Tooltip>
              </Space>
            }
            name="certified_by_eic"
            rules={[
              {
                required: true,
                message: 'Select Yes/No'
                // Select Certified by EIC
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select Yes/No"
              style={{ width: '100%' }}
              onChange={(e) => handleEIC(e)}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
                  ? true
                  : false
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormItem>
        </Col>
        {eICData === 'yes' ? (
          <Col xs={24} xl={6} span={8}>
            <FormItem
              label="Name of EIC"
              name="name_of_eic"
              rules={[
                {
                  required: true,
                  message: 'Enter name of EIC'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                placeholder="Enter name of EIC"
                disabled={
                  invoicesData?.invoice_sts_code === 'STR' ||
                  invoicesData?.invoice_sts_code === 'IVTR'
                    ? true
                    : false
                }
              />
            </FormItem>
          </Col>
        ) : (
          ''
        )}
        <Col xs={24} xl={eICData === 'yes' ? 6 : 8} span={8}>
          <FormItem
            label="Date of certification"
            name="date_of_certification"
            rules={[
              {
                required: true,
                message: 'Select date of certification'
              }
            ]}
            className={styles.customLable}
          >
            <DatePicker
              placeholder="Select date of certification"
              style={{ width: '100%' }}
              disabledDate={(current) => {
                return (
                  // moment().add(-30, 'days') >= current ||
                  moment().add(0, 'month') <= current ||
                  (current && current <= moment('2024-04-01'))
                );
              }}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
              }
            />
          </FormItem>
        </Col>
        <Col xs={24} xl={eICData === 'yes' ? 6 : 8} span={8}>
          <FormItem
            label="Invoice type"
            name="invoice_type"
            rules={[
              {
                required: true,
                message: 'Select invoice type'
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select invoice type"
              style={{ width: '100%' }}
              onChange={(e) => handleInvoiceTypeChange(e)}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
                  ? true
                  : false
              }
            >
              {invoicetypes.length
                ? invoicetypes?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.invoice_type_des}
                    </option>
                  ))
                : ''}
            </Select>
          </FormItem>
        </Col>
        {/* Ref Invoice Number and Date columns */}
        {/* {['Debit Note', 'Credit Note'].includes(selectedInvoiceType) ? ( */}
        {selectedInvoiceType === 'B4EE433E-F36B-1410-8194-00685A84348C' ||
        selectedInvoiceType === 'B5EE433E-F36B-1410-8194-00685A84348C' ||
        selectedInvoiceType === 'yes' ? (
          // selectedInvoiceType === 'B5EE433E-F36B-1410-8194-00685A84348C'
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Reference Invoice Number"
              name="reference_invoice_number"
              rules={[
                {
                  required: true,
                  message: 'Enter reference invoice number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter reference invoice number" />
            </FormItem>
          </Col>
        ) : (
          ''
        )}
        {selectedInvoiceType === 'B4EE433E-F36B-1410-8194-00685A84348C' ||
        selectedInvoiceType === 'B5EE433E-F36B-1410-8194-00685A84348C' ||
        selectedInvoiceType === 'yes' ? (
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Reference Invoice Date"
              name="reference_invoice_date"
              rules={[
                {
                  required: true,
                  message: 'Select reference invoice date'
                }
              ]}
              className={styles.customLable}
            >
              <DatePicker
                placeholder="Select reference invoice date"
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  return (
                    // moment().add(-30, 'days') >= current ||
                    moment().add(0, 'month') <= current ||
                    (current && current <= moment('2024-04-01'))
                  );
                }}
              />
            </FormItem>
          </Col>
        ) : (
          ''
        )}

        <Col xs={24} xl={finalData === 'yes' ? 8 : 8} span={8}>
          <FormItem
            label="Certified abstract in standard format"
            name="certified_abstract"
            rules={[
              {
                required: true,
                message: 'Select Yes/No'
                // Select Certified abstract in standard format
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select Yes/No"
              style={{ width: '100%' }}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
                  ? true
                  : false
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormItem>
        </Col>
        <Col xs={24} xl={finalData === 'yes' ? 8 : 8} span={8}>
          <FormItem
            label="Final bill"
            name="final_bill"
            rules={[
              {
                required: true,
                message: 'Select Yes/No'
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select Yes/No"
              style={{ width: '100%' }}
              onChange={(e) => handleFinalBill(e)}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
                  ? true
                  : false
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormItem>
        </Col>
        {/* Rohit Code */}
        {finalData === 'yes' ? (
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label={
                <Space>
                  <span>FIM reconciliation statement</span>
                  <Popover
                    placement="topLeft"
                    content="FIM Must be signed by EIC"
                    overlayClassName={styles.customPopover}
                  >
                    <sup>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                    </sup>
                  </Popover>
                </Space>
              }
              name="fim_Reconciliation_statement"
              rules={[
                {
                  required: true,
                  message: 'Select Yes/No'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select Yes/No"
                style={{ width: '100%' }}
                disabled={
                  invoicesData?.invoice_sts_code === 'STR' ||
                  invoicesData?.invoice_sts_code === 'IVTR'
                    ? true
                    : false
                }
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Select>
            </FormItem>
          </Col>
        ) : (
          ''
        )}
        {finalData === 'yes' ? (
          <Col xs={24} xl={8} span={8}>
            {/* <Col xs={24} xl={finalData === 'yes' ? 6 : 8} span={8}> */}
            <FormItem
              label={
                <Space>
                  <span>No claim certificate</span>
                  <Popover
                    placement="topLeft"
                    content="No Claim Certificate must be signed by Vendor"
                  >
                    <sup>
                      <InfoCircleOutlined style={{ color: 'red' }} />
                    </sup>
                  </Popover>
                </Space>
              }
              name="no_claim_certificate"
              rules={[
                {
                  required: true,
                  message: 'Select Yes/No'
                }
              ]}
              className={styles.customLable}
            >
              <Select
                placeholder="Select Yes/No"
                style={{ width: '100%' }}
                // onChange={(e) => handleNoClaim(e)}
                disabled={
                  invoicesData?.invoice_sts_code === 'STR' ||
                  invoicesData?.invoice_sts_code === 'IVTR'
                    ? true
                    : false
                }
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Select>
            </FormItem>
          </Col>
        ) : (
          ''
        )}
        {/* {noClaim === 'no' ? ( */}
        <Col xs={24} xl={8} span={8}>
          <FormItem
            label="E-Way bill"
            name="eway_bill"
            rules={[
              {
                required: true,
                message: 'Select Yes/No'
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select Yes/No "
              style={{ width: '100%' }}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
                  ? true
                  : false
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormItem>
        </Col>
        {/* ) : (
          ''
        )} */}

        <Col xs={24} xl={8} span={8}>
          <FormItem
            label="Delivery challan"
            name="delivery_challan"
            rules={[
              {
                required: true,
                message: 'Select Yes/No'
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select Yes/No"
              style={{ width: '100%' }}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
                  ? true
                  : false
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormItem>
        </Col>

        <Col xs={24} xl={8} span={8}>
          <FormItem
            label="LR copy"
            name="lr_copy"
            rules={[
              {
                required: true,
                message: 'Select Yes/No'
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select Yes/No"
              style={{ width: '100%' }}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
                  ? true
                  : false
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormItem>
        </Col>

        <Col xs={24} xl={8} span={8}>
          <FormItem
            label="LD certification by EIC"
            name="ld_certification_by_EIC"
            rules={[
              {
                required: true,
                message: 'Select Yes/No'
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select Yes/No"
              style={{ width: '100%' }}
              onChange={(e) => handleLDCerteic(e)}
              disabled={
                invoicesData?.invoice_sts_code === 'STR' ||
                invoicesData?.invoice_sts_code === 'IVTR'
                  ? true
                  : false
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormItem>

          {/* Modal */}
          {/* <Modal
          title="Certification Required"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          overlayClassName={styles.customModal}
            >
              <p>Certification must be attached. Please ensure all required documents are included.</p>
            </Modal> */}

          {/* Popover */}
          {/* {isldeic && (
              <Popover
                content={content}
                title="Notice"
                trigger="click"
                overlayClassName={styles.customPopover}
              >
                <Button type="link" icon={<InfoCircleOutlined style={{ color: '#D4AF37' }} />}>
                  More Info
                </Button>
              </Popover>
            )} */}

          {showAlert && (
            <Alert
              message="Certification Required"
              description="Certification must be attached. Please ensure all required documents are included."
              type="warning"
              closable
              onClose={() => setShowAlert(false)}
              className={styles.customAlert}
            />
          )}
        </Col>

        {/* <div style={{ position: 'relative', padding: '20px' }}>
          {showNotification && (
            <div className="custom-notification">
              <span className="notification-message">
                {selectedValue === 'yes'
                  ? 'Certification must be attached.'
                  : 'Applicable LD will be charged.'}
              </span>
              <Button
                className="notification-close"
                type="text"
                icon={<CloseOutlined />}
                onClick={handleCloseNotification}
              />
            </div>
          )}
          <Col xs={24} xl={8} span={8}>
            <Form.Item
              label="LD certification by EIC"
              name="ld_certification_by_EIC"
              rules={[
                {
                  required: true,
                  message: 'Select Yes/No'
                }
              ]}
              className="customLabel"
            >
              <Select
                placeholder="Select Yes/No"
                style={{ width: '100%' }}
                onChange={handleChange}
                disabled={
                  invoicesData?.invoice_sts_code === 'STR' ||
                  invoicesData?.invoice_sts_code === 'IVTR'
                }
              >
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </div> */}

        <Col xs={24} xl={8} span={8}>
          <FormItem
            label="MSME"
            name="msme"
            rules={[
              {
                required: true,
                message: 'Select Yes/No'
              }
            ]}
            className={styles.customLable}
          >
            <Select
              placeholder="Select Yes/No"
              style={{ width: '100%' }}
              onChange={(e) => handleMsme(e)}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Select>
          </FormItem>
        </Col>
        {msmeData === 'yes' ? (
          <Col xs={24} xl={8} span={8}>
            <FormItem
              label="Udayam number"
              name="udayam_number"
              rules={[
                {
                  required: true,
                  message: 'Enter udayam number'
                }
              ]}
              className={styles.customLable}
            >
              <Input placeholder="Enter udayam number" />
            </FormItem>
          </Col>
        ) : (
          ''
        )}
      </Row>
      {ismodal ? (
        <CustomModal
          title="Certification by EIC"
          visible={ismodal}
          width="30%"
          footer={null}
          className={styles.customModal}
          closable={false}
        >
          <div>
            Please get your invoice certified by EIC, before proceeding further.
          </div>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => handleClose()}
              type="primary"
              // size="small"
            >
              Close
            </CustomButton>
          </FormItem>
        </CustomModal>
      ) : (
        ''
      )}
    </>
    // </Form>
  );
};
export default header;
