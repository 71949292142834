import React from 'react';
import { Form } from 'antd';
import styles from './index.module.less';

const FormItem = ({ children, className, ...rest }) => {
  return (
    <Form.Item
      {...rest}
      className={`${styles.custom_form_item}${
        className ? ` ${className}` : ''
      }`}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
